<template>
  <b-sidebar
    id="add-document-sidebar"
    :visible="isAddDocumentSidebarActive"
    backdrop
    bg-variant="white"
    no-header
    right
    shadow
    sidebar-class="sidebar-lg"
    @change="(val) => $emit('update:is-add-document-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h4 class="mb-0">Add Document</h4>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="clearForm(); hide(); "
        />
      </div>
      <b-overlay :show="loadingModel" rounded="sm">
        <!-- BODY -->
        <validation-observer ref="refFormObserver">
          <b-overlay :show="formLoading" rounded="sm">
            <!-- Form -->
            <div class="p-2">
              <div class="alert d-flex font-weight-bold">
                <label class="font-small-4"> Select Document</label>
              </div>

              <b-row>
                <b-col class="mb-1" cols="12">
                  <validation-provider
                    #default="{ errors }"
                    name="Document"
                    rules="required"
                  >
                    <b-form-file
                      ref="image_ref"
                      v-model="uploadedFile"
                      drop-placeholder="Drop file here..."
                      placeholder="Choose a file or drop it here..."
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col class="mb-1" cols="12">
                  <label>Document Type</label>
                  <validation-provider
                    #default="{ errors }"
                    name="Reason"
                    rules="required"
                  >
                    <b-form-group>
                      <v-select
                        v-model="selected_file_type_id"
                        :options="fileTypeOption"
                        :reduce="(fileTypeOption) => fileTypeOption.id"
                        label="name"
                        placeholder="Select"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12">
                  <b-form-group
                    label="Document Description (optional)"
                    label-for="document"
                  >
                    <b-form-input
                      v-model="description"
                      id="document"
                      name="document"
                      type="text"
                      placeholder="e.g. This is a renewed ID"
                    />
                  </b-form-group>
                </b-col>
              </b-row>

              <!-- Form Actions -->
              <b-row>
                <span class="mr-1 mt-2 ml-2">
                  <div class="d-flex mt-1">
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      type="submit"
                      variant="primary"
                      :disabled="isActive"
                      @click="uploadFile()"
                    >
                      Save
                    </b-button>
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      class="text-primary ml-1 shadow"
                      type="button"
                      variant="outline-white"
                      @click="clearForm(); hide();"
                    >
                      Cancel
                    </b-button>
                  </div>
                </span>
              </b-row>
            </div>
          </b-overlay>
        </validation-observer>
      </b-overlay>
    </template>
  </b-sidebar>
</template>

<script>
import {
  VBTooltip,
  BRow,
  BAlert,
  BButton,
  BForm,
  BFormCheckbox,
  BFormDatepicker,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BInputGroup,
  BFormTextarea,
  BSidebar,
  BInputGroupPrepend,
  BCol,
  BBadge,
  BOverlay,
  BTable,
  BFormFile,
  BFormRadio,
  BFormRadioGroup,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@validations";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import Filter from "@/mixins/FilterMixin";
import MomentMixin from "@/mixins/MomentMixin";
import settings from "@/apis/modules/settings";
import fileUploader from "@/views/my-documents/fileUploader";
import { baseApiUrl } from "@/constants/config";
import staffBank from "@/apis/modules/staffBank";

export default {
  mixins: [MomentMixin, Filter],

  components: {
    flatPickr,
    vSelect,
    BAlert,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroup,
    BFormTextarea,
    BButton,
    BFormDatepicker,
    BFormCheckbox,
    BRow,
    BCol,
    BInputGroupPrepend,
    BTable,
    BOverlay,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    BFormRadio,
    VBTooltip,
    BFormFile,
    BFormRadioGroup,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },

  model: {
    prop: "isAddDocumentSidebarActive",
    event: "update:is-add-document-sidebar-active",
  },
  props: {
    isAddDocumentSidebarActive: {
      type: Boolean,
    },
  },
  data() {
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const minDate = new Date(today);
    minDate.setMonth(minDate.getMonth());
    minDate.setDate(minDate.getDate());
    return {
      fileTypeOption: [],
      buttonLoading: false,
      required,
      documentType: "",
      documentTypeOptions: "",
      formLoading: false,
      uploadedFile: null,
      loadingModel: false,
      selected_file_type_id: "",
      description: "",
      current_user_id: this.$route.params.id,
      isActive: true,
    };
  },

  watch: {
    selected_file_type_id() {
      this.validateFiled();
    },
    uploadedFile() {
      this.validateFiled();
    },
  },
  methods: {
    openSideBar() {
      this.selected_file_type_id = "";
      this.fileTypeOption = [];
      this.uploadedFile = null;
      this.isAddDocumentSidebarActive = !this.isAddDocumentSidebarActive;
      this.getFileType();
    },
    validateFiled() {
      this.selected_file_type_id && this.uploadedFile
        ? (this.isActive = false)
        : (this.isActive = true);
    },
    async getFileType() {
      try {
        this.loadingModel = true;

        const Response = await settings.getFilteredFileType(
          this.current_user_id
        );
        this.fileTypeOption = Response.data.data.map((x) => ({
          id: x.id,
          name: x.name,
        }));
      } catch (error) {
        this.convertAndNotifyError(error);
        this.tableLoading = false;
      }
      this.loadingModel = false;
    },
    async uploadFile() {
      try {
        this.loadingModel = true;
        fileUploader
          .store(this.uploadedFile, {
            progress: (progress) => {
              this.uploadProgress = Math.round(progress * 100);
            },
            visibility: "public-read",
            // baseURL: 'http://hyre-be-v2.test/api/mp',
            baseURL: `${baseApiUrl}`,

            params: {
              auth_practice_id: `${sessionStorage.getItem("practice_id")}`,
            },
          })
          .then(async (response) => {
            const key = response.key;
            await this.setImageKeyToApi(key);
            // await this.resetData()
            this.isActive = true;
          });
      } catch (error) {
        this.convertAndNotifyError(error);
        this.loadingModel = false;
      }
      // this.toggle();
    },
    async setImageKeyToApi(key) {
      try {
        await staffBank.getAdminImage({
          user_id: this.current_user_id,
          key: key,
          name: this.uploadedFile.name,
          file_type_id: this.selected_file_type_id,
          description: this.description,
        });
        this.showSuccessMessage("Document Uploaded Successfully");
        this.loadingModel = false;
        this.clearForm();
      } catch (e) {
        this.loadingModel = false;
      }
    },
    // resetForm() {
    //   this.$emit("getDocumentList");
    //   this.isAddDocumentSidebarActive = false;
    // },

    // toggle() {
    //   (this.uploadedFile = null),
    //     (this.description = ""),
    //     (this.isAddDocumentSidebarActive = false);
    //   this.$refs.refFormObserver.reset();
    // },

    
    clearForm() {
        this.uploadedFile = null;
        this.description = "";
        this.$emit("getDocumentList");
        this.isAddMandatorySidebarActive = false;
        this.$refs.refFormObserver.reset();
    },

  },
  mounted() {},
};
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-todo.scss";
</style>
