var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("b-card-header", { staticClass: "border-bottom pb-1" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-row" },
              [
                _c(
                  "b-avatar",
                  { attrs: { rounded: "", variant: "light-primary" } },
                  [
                    _c("feather-icon", {
                      staticClass: "text-dark font-weight-bolder",
                      attrs: { icon: "CalendarIcon", size: "18" }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "ml-1",
                    staticStyle: { "padding-top": "8px" }
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          "font-family": "Mulish",
                          "font-style": "normal",
                          "font-weight": "bold",
                          "font-size": "20px",
                          "line-height": "25px",
                          color: "#171822"
                        }
                      },
                      [_vm._v(" Shifts ")]
                    )
                  ]
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "ml-auto" },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "btn-icon bg-white shadow-sm font-weight-bold",
                    attrs: { variant: "white" }
                  },
                  [
                    _c("feather-icon", {
                      directives: [
                        {
                          name: "b-tooltip",
                          rawName: "v-b-tooltip.hover.top",
                          value: "Download",
                          expression: "'Download'",
                          modifiers: { hover: true, top: true }
                        }
                      ],
                      staticClass: "text-primary",
                      attrs: { icon: "DownloadIcon", size: "20" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]),
          _c(
            "b-card-body",
            { staticClass: " pl-1 px-0 mx-0" },
            [
              _vm.noDataTable !== 0
                ? _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "b-overlay",
                            {
                              attrs: { show: _vm.tableLoading, rounded: "sm" }
                            },
                            [
                              _c("b-table", {
                                ref: "table",
                                staticClass: "mobile_table_css table_shifts",
                                attrs: {
                                  "current-page": _vm.currentPage,
                                  "per-page": _vm.pagination.perPage,
                                  fields: _vm.fields,
                                  filter: _vm.filter,
                                  "filter-included-fields": _vm.filterOn,
                                  items: _vm.getShifts,
                                  "sort-by": _vm.sortBy,
                                  "sort-desc": _vm.sortDesc,
                                  "sort-direction": _vm.sortDirection,
                                  hover: "",
                                  responsive: ""
                                },
                                on: {
                                  "update:sortBy": function($event) {
                                    _vm.sortBy = $event
                                  },
                                  "update:sort-by": function($event) {
                                    _vm.sortBy = $event
                                  },
                                  "update:sortDesc": function($event) {
                                    _vm.sortDesc = $event
                                  },
                                  "update:sort-desc": function($event) {
                                    _vm.sortDesc = $event
                                  }
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "cell(subject)",
                                      fn: function(data) {
                                        return [
                                          _c("div", { staticClass: "pl-1 " }, [
                                            _c(
                                              "h5",
                                              {
                                                staticStyle: {
                                                  "font-family": "Mulish",
                                                  "font-style": "normal",
                                                  "font-weight": "bold",
                                                  "font-size": "16px",
                                                  "line-height": "20px",
                                                  color: "#171822"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(data.item.subject) +
                                                    " "
                                                ),
                                                data.item.is_covid
                                                  ? _c(
                                                      "b-badge",
                                                      {
                                                        staticClass:
                                                          "text-capitalize",
                                                        attrs: {
                                                          variant:
                                                            "light-danger"
                                                        }
                                                      },
                                                      [_vm._v(" COVID-19 ")]
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "mr-1",
                                                staticStyle: {
                                                  "font-family": "Mulish",
                                                  "font-style": "normal",
                                                  "font-weight": "normal",
                                                  "font-size": "14px",
                                                  "line-height": "21px",
                                                  color: "#171822"
                                                }
                                              },
                                              [
                                                _c("feather-icon", {
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    icon: "CalendarIcon"
                                                  }
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(data.item.date) +
                                                    " "
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "mr-1",
                                                staticStyle: {
                                                  "font-family": "Mulish",
                                                  "font-style": "normal",
                                                  "font-weight": "normal",
                                                  "font-size": "14px",
                                                  "line-height": "21px",
                                                  color: "#171822"
                                                }
                                              },
                                              [
                                                _c("feather-icon", {
                                                  staticClass: "mr-1",
                                                  attrs: { icon: "ClockIcon" }
                                                }),
                                                _vm._v(
                                                  " " +
                                                    _vm._s(data.item.time) +
                                                    " "
                                                )
                                              ],
                                              1
                                            )
                                          ])
                                        ]
                                      }
                                    },
                                    {
                                      key: "cell(action)",
                                      fn: function(data) {
                                        return [
                                          data.item.status === "Active"
                                            ? _c(
                                                "b-badge",
                                                {
                                                  staticClass:
                                                    "text-capitalize  float-right",
                                                  attrs: {
                                                    variant: "light-primary"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.item.status) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          data.item.status === "Upcoming"
                                            ? _c(
                                                "b-badge",
                                                {
                                                  staticClass:
                                                    "text-capitalize  float-right",
                                                  staticStyle: {
                                                    "background-color":
                                                      "rgba(239, 161, 44, 0.1)",
                                                    color: "#EFA12C"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.item.status) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _vm._e(),
                                          data.item.status === "Past"
                                            ? _c(
                                                "b-badge",
                                                {
                                                  staticClass:
                                                    "text-capitalize  float-right",
                                                  staticStyle: {
                                                    "background-color":
                                                      "rgba(178, 178, 178, 0.1)",
                                                    color:
                                                      "rgba(23, 24, 34, 0.6)"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.item.status) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      }
                                    }
                                  ],
                                  null,
                                  false,
                                  2698806903
                                )
                              })
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm.noDataTable === 0
                        ? _c(
                            "b-col",
                            {
                              staticClass: "text-center",
                              attrs: { cols: "12" }
                            },
                            [_c("span", [_vm._v("No data for preview")])]
                          )
                        : _vm._e(),
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center pl-1 justify-content-center justify-content-sm-start",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c("span", { staticClass: "text-muted" }, [
                            _vm._v(
                              "Showing " +
                                _vm._s(_vm.pagination.from) +
                                " to " +
                                _vm._s(_vm.pagination.to) +
                                " of " +
                                _vm._s(_vm.pagination.totalRows) +
                                " entries"
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-col",
                        {
                          staticClass:
                            "d-flex align-items-center pr-4 justify-content-center justify-content-sm-end",
                          attrs: { cols: "12", sm: "6" }
                        },
                        [
                          _c("b-pagination", {
                            staticClass: "mb-0 mt-1 mt-sm-0 ",
                            attrs: {
                              "per-page": _vm.pagination.perPage,
                              "total-rows": _vm.pagination.totalRows,
                              "first-number": "",
                              "last-number": "",
                              "next-class": "next-item",
                              "prev-class": "prev-item"
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "prev-text",
                                  fn: function() {
                                    return [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "ChevronLeftIcon",
                                          size: "18"
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                },
                                {
                                  key: "next-text",
                                  fn: function() {
                                    return [
                                      _c("feather-icon", {
                                        attrs: {
                                          icon: "ChevronRightIcon",
                                          size: "18"
                                        }
                                      })
                                    ]
                                  },
                                  proxy: true
                                }
                              ],
                              null,
                              false,
                              1308952388
                            ),
                            model: {
                              value: _vm.currentPage,
                              callback: function($$v) {
                                _vm.currentPage = $$v
                              },
                              expression: "currentPage"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column align-items-center justify-content-center h-100"
                    },
                    [
                      _c("div", { staticClass: "empty-state py-1" }, [
                        _c("img", {
                          staticClass: "img-fluid",
                          attrs: {
                            src: require("@/assets/images/pages/shift-icon.png"),
                            alt: "",
                            width: "100"
                          }
                        }),
                        _c("p", { staticClass: "txt-grey mt-1" }, [
                          _vm._v("No Shifts")
                        ])
                      ])
                    ]
                  )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }