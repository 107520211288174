import api from '../axios';
const resource = '/api/mp/';

export default {
  getUserNotes: (id, page, paginate) =>
    api.get(`${resource}salaried-staff/notes/${id}`),
  deleteNote: (id) => api.delete(`${resource}salaried-staff/notes/${id}`),
  addNote: (payoad) => api.post(`${resource}salaried-staff/notes`, payoad),
  editNote: (id, payload) =>
    api.put(`${resource}salaried-staff/notes/${id}`, payload),
};
