<template>
  <div>
    <b-card no-body>

      <b-card-header class="border-bottom pb-1">
        <div class="d-flex flex-row">

          <b-avatar
              rounded
              variant="light-primary"
          >
            <feather-icon
                class="text-dark font-weight-bolder"
                icon="KeyIcon"
                size="18"
            />
          </b-avatar>
          <div class="ml-1" style="padding-top:8px">
            <h4 class=" font-weight-700">
              Reset Password
            </h4>
          </div>
        </div>
      </b-card-header>
      <b-card-body>
        <b-row class="mx-1">

          <b-col class="mt-2" cols="12" md="12">
            <b-form-group>
              Your new password must be different from previously used <br>
              passwords.
            </b-form-group>
          </b-col>
          <!-- Current Password -->


          <b-col cols="12" md="6">
            <validation-observer ref="resetPasswordForm">
              <b-form @submit.prevent>
                <b-form-group class="">
                  <label for="basic-password1">Current Password</label>
                  <validation-provider
                      #default="{ errors }"
                      name="Old-Password"
                      rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                          id="basic-password1"
                          v-model="form.old_password"
                          :type="password1FieldType"
                          placeholder="Enter Current Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                            :icon="password1ToggleIcon"
                            class="cursor-pointer"
                            @click="togglePassword1Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <!-- New Password -->


                <!--              <b-col cols="12" md="12">-->
                <b-form-group>
                  <label for="basic-password2">New Password</label>
                  <validation-provider
                      #default="{ errors }"
                      name="Password"
                      rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                          id="basic-password2"
                          v-model="form.password"
                          :type="password2FieldType"
                          placeholder="Enter New Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                            :icon="password2ToggleIcon"
                            class="cursor-pointer"
                            @click="togglePassword2Visibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group>
                  <label for="basic-password3">Confirm Password</label>
                  <validation-provider
                      #default="{ errors }"
                      name="Confirm-Password"
                      rules="required"
                  >
                    <b-input-group class="input-group-merge">
                      <b-form-input
                          id="basic-password3"
                          v-model="form.password_confirmation
"
                          :type="password3FieldType"
                          placeholder="Enter New Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                            :icon="password3ToggleIcon"
                            class="cursor-pointer"
                            @click=""
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    class="mr-1"
                    type="submit"
                    variant="primary"
                    @click=""
                >
                  Save
                </b-button>
                <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="outline-secondary"
                >
                   <span class="text-secondary">
                        Reset
                          </span>
                </b-button>
              </b-form>
            </validation-observer>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import EditProfileSidebar from '@/views/staff-bank/sidebar/EditProfile'
import AddNoteSidebar from '@/views/staff-bank/staff-profile/sidebars/addNoteSidebar'
import EditNoteSidebar from '@/views/staff-bank/staff-profile/sidebars/editNoteSidebar'
import RequestLeave from '@/views/staff-bank/staff-profile/sidebars/requestLeave'
import ProfileDownloadDropDownMenu
  from '@/views/staff-bank/staff-profile/includes/dropdowns/ProfileDownloadDropDownMenu'
import DropDownMenu from '@/views/profile/includes/dropdowns/ProfileLeavesDropDownMenu'
import {
  BForm,
  BAvatar, BBadge,
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCol, BDropdown, BDropdownItem, BFormFile, BFormGroup, BFormInput,
  BImg, BInputGroup, BInputGroupAppend, BInputGroupPrepend,
  BLink, BListGroup, BListGroupItem,
  BMedia, BModal, BOverlay,
  BRow, BTab,
  BTable, BTabs,
  VBTooltip
} from 'bootstrap-vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import vSelect from 'vue-select'
import AddLeaveSidebar from '@/views/admin-leave/sidebars/addLeaveSidebar'
import Ripple from 'vue-ripple-directive'
import MomentMixin from '@/mixins/MomentMixin'

export default {
  name: 'settings',
  components: {
    BForm,
    EditProfileSidebar,
    AddNoteSidebar,
    EditNoteSidebar,
    RequestLeave,
    ProfileDownloadDropDownMenu,
    DropDownMenu,
    VBTooltip,
    BTable,
    BMedia,
    BImg,
    BCard,
    BCardHeader,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BCardBody,
    BBadge,
    VuePerfectScrollbar,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    vSelect,
    BModal,
    BFormFile,
    BOverlay,
    BTabs,
    BTab,
    AddLeaveSidebar,
    BInputGroup,
    BFormGroup,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  mixins: [MomentMixin],

  data() {
    return{
      form: {
        old_password: '',
        password: '',
        password_confirmation: '',
      },
      password1FieldType: 'password',
      password2FieldType: 'password',
      password3FieldType: 'password',
    }
  },

  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password3ToggleIcon() {
      return this.password3FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },

  methods:{
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword3Visibility() {
      this.password3FieldType = this.password3FieldType === 'password' ? 'text' : 'password'
    },
  }
}
</script>

<style scoped>

</style>