var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12", xl: "1" } },
                [
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        {
                          staticClass: "d-flex justify-content-start",
                          attrs: { cols: "12" }
                        },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass:
                                "shadow-sm font-weight-bold  align-self-baseline float-left",
                              attrs: { variant: "white" },
                              on: {
                                click: function($event) {
                                  return _vm.$router.back()
                                }
                              }
                            },
                            [
                              _c("feather-icon", {
                                attrs: { icon: "ChevronLeftIcon", size: "20" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticStyle: { width: "250px" } },
                            [
                              _c("b-avatar", {
                                staticClass: "mt-1 mr-1 badge-minimal",
                                staticStyle: { padding: "7px" },
                                attrs: {
                                  src: _vm.getUserImage(_vm.user.id, true),
                                  "badge-variant": "success",
                                  variant: "light-primary",
                                  size: "110px"
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                { attrs: { cols: "12", xl: "11" } },
                [
                  _c(
                    "b-row",
                    { staticClass: "ml-5" },
                    [
                      _c("b-col", { attrs: { cols: "12", xl: "5" } }, [
                        _vm.user
                          ? _c(
                              "div",
                              { staticClass: "d-flex align-items-center " },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-md-flex flex-row text-center mr-1 ml-1"
                                  },
                                  [
                                    _c(
                                      "h3",
                                      {
                                        staticClass:
                                          "mb-0 font-weight-700 mt-2",
                                        attrs: { id: "user_name" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.user.first_name +
                                              " " +
                                              _vm.user.last_name
                                          ) + " "
                                        )
                                      ]
                                    )
                                  ]
                                ),
                                _c("div", { staticClass: "mt-1" }),
                                _c("div", {
                                  staticClass: "mt-2",
                                  staticStyle: { "padding-left": "6px" }
                                })
                              ]
                            )
                          : _vm._e()
                      ]),
                      _c(
                        "b-col",
                        {
                          staticClass: "pt-0 pb-2 pt-xl-2 pb-xl-0 ",
                          attrs: { cols: "12", xl: "7" }
                        },
                        [
                          _c(
                            "b-row",
                            [
                              _c("b-col", {
                                staticClass:
                                  "d-flex justify-content-center justify-content-xl-end",
                                attrs: { cols: "12", md: "4" }
                              }),
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    " pr-3 pl-3 pl-md-0  my-2 my-md-0",
                                  attrs: { cols: "12", md: "8" }
                                },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c("b-col", {
                                        staticClass:
                                          " pr-3 pl-3 pl-md-0  my-md-0 ",
                                        attrs: { cols: "12", md: "7" }
                                      }),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: " pl-md-0  my-md-0",
                                          attrs: { cols: "12", md: "5" }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex justify-content-end "
                                            },
                                            [
                                              _c(
                                                "b-avatar",
                                                {
                                                  attrs: {
                                                    variant: "light-primary",
                                                    rounded: ""
                                                  }
                                                },
                                                [
                                                  _c("feather-icon", {
                                                    attrs: {
                                                      icon: "ClockIcon",
                                                      size: "18"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                { staticClass: "ml-1" },
                                                [
                                                  _c(
                                                    "h4",
                                                    {
                                                      staticClass:
                                                        "mb-0 font-weight-bolder"
                                                    },
                                                    [
                                                      _vm._v(
                                                        " " +
                                                          _vm._s(
                                                            _vm
                                                              .calculateHoursWorked(
                                                                _vm.user.shifts
                                                              )
                                                              .toFixed(1)
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  ),
                                                  _c("small", [
                                                    _vm._v("Hours Worked")
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("b-col", { staticClass: "d-flex", attrs: { cols: "3" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "ml-5",
                        staticStyle: { "padding-left": "16px" }
                      },
                      [
                        _c(
                          "b-alert",
                          { attrs: { variant: "primary", show: "" } },
                          [
                            _c(
                              "small",
                              {
                                staticClass: "text-dark font-weight-700 mx-1",
                                attrs: { id: "job_role1" }
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm.user.job_roles || _vm.user.job_roles[0]
                                      ? _vm.user.job_roles[0].name
                                      : ""
                                  ) + " "
                                )
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-row",
            {},
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-tabs",
                    {
                      staticClass: "ml-5",
                      attrs: { lazy: "" },
                      model: {
                        value: _vm.tabIndex,
                        callback: function($$v) {
                          _vm.tabIndex = $$v
                        },
                        expression: "tabIndex"
                      }
                    },
                    [
                      _c("b-tab", { attrs: { title: "Personal Info" } }, [
                        _c(
                          "div",
                          { staticClass: "alert-body" },
                          [
                            _c(
                              "b-card",
                              {
                                staticClass: "mb-0 mr-5",
                                staticStyle: { "background-color": "#EBF5FB" }
                              },
                              [
                                _c("personalInfo", {
                                  on: { updateDetails: _vm.updatedDetails }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c("b-tab", { attrs: { title: "Employment Info" } }, [
                        _c(
                          "div",
                          { staticClass: "alert-body" },
                          [
                            _c(
                              "b-card",
                              {
                                staticClass: "mb-0",
                                staticStyle: { "background-color": "#EBF5FB" }
                              },
                              [_c("employmentInfo")],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c("b-tab", { attrs: { title: "Leave" } }, [
                        _c(
                          "div",
                          { staticClass: "alert-body" },
                          [
                            _c(
                              "b-card",
                              {
                                staticClass: "mb-0",
                                staticStyle: { "background-color": "#EBF5FB" }
                              },
                              [_c("leave")],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c("b-tab", { attrs: { title: "Working Hours" } }, [
                        _c(
                          "div",
                          { staticClass: "alert-body" },
                          [
                            _c(
                              "b-card",
                              {
                                staticClass: "mb-0",
                                staticStyle: { "background-color": "#EBF5FB" }
                              },
                              [_c("workingHours")],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c(
                        "b-tab",
                        { attrs: { title: "Documents & Training" } },
                        [
                          _c(
                            "div",
                            { staticClass: "alert-body" },
                            [
                              _c(
                                "b-card",
                                {
                                  staticClass: "mb-0",
                                  staticStyle: { "background-color": "#EBF5FB" }
                                },
                                [_c("documentsAndTraining")],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c("b-tab", { attrs: { title: "Shifts" } }, [
                        _c(
                          "div",
                          { staticClass: "alert-body" },
                          [
                            _c(
                              "b-card",
                              {
                                staticClass: "mb-0",
                                staticStyle: { "background-color": "#EBF5FB" }
                              },
                              [_c("shifts")],
                              1
                            )
                          ],
                          1
                        )
                      ]),
                      _c("b-tab", { attrs: { title: "Settings" } }, [
                        _c(
                          "div",
                          { staticClass: "alert-body" },
                          [
                            _c(
                              "b-card",
                              {
                                staticClass: "mb-0",
                                staticStyle: { "background-color": "#EBF5FB" }
                              },
                              [_c("settings")],
                              1
                            )
                          ],
                          1
                        )
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }