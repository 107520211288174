<template>
  <div>
    <b-modal
        id="modal-center"
        v-model="showFileUploadModal"
        centered
        hide-footer
        no-close-on-backdrop
        size="lg"
        title="Upload Employment Contact"
        @change="goBack"
    >
      <b-overlay :show="modalLoading" rounded="sm">
        <b-row class="mx-1">
          <b-col class="mt-2" cols="12">
            <div class="m-auto" style="width: 100px; height: 100px">
              <svg
                  id="Layer_1"
                  style="enable-background: new 0 0 512 512"
                  version="1.1"
                  viewBox="0 0 512 512"
                  x="0px"
                  xml:space="preserve"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  y="0px"
              >
                <rect height="512" style="fill: #32bea6" width="512" y="0"/>
                <g>
                  <polygon
                      points="182.76,229.728 232.704,178.808 232.704,301.688 279.296,301.688 279.296,178.808
		329.24,229.728 362.488,197.288 256.008,88.72 149.512,197.288 	"
                      style="fill: #ffffff"
                  />
                  <polygon
                      points="395.528,421.864 116.472,421.864 116.472,301.088 132.472,301.088 132.472,405.864
		379.528,405.864 379.528,301.088 395.528,301.088 	"
                      style="fill: #ffffff"
                  />
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </div>
          </b-col>
          <b-col class="mt-3" cols="12">
            <b-overlay :show="buttonLoading" rounded="sm">
              <b-form-file
                  ref="image_ref"
                  v-model="uploadedFile"
                  drop-placeholder="Drop file here..."
                  placeholder="Choose a file or drop it here..."
              />
            </b-overlay>
          </b-col>
          <b-col class="mt-5" cols="12">
            <div class="float-right">
              <b-button
                  :disabled="isActive"
                  class="mr-2"
                  variant="primary"
                  @click="uploadFile(uploadType)"
              >
                Upload
              </b-button>

              <b-button variant="warning" @click="goBack">Cancel</b-button>
            </div>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BLink,
  BRow,
  BFormCheckbox,
  BCardBody,
  BCardHeader,
  BImg,
  BMedia,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BModal,
  BFormFile,
  BOverlay,
  VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import staffBankAPI from '@/apis/modules/staffBank'
import {baseApiUrl, apiUrl} from '@/constants/config'
import fileUploader from '@/views/my-documents/fileUploader'
import profile from '@/apis/modules/profile'
import staffBank from '@/apis/modules/staffBank'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import SuccessMessage from '@/mixins/SuccessMessage'
import axios from 'axios'
import Ripple from 'vue-ripple-directive'
import Filter from '@/mixins/FilterMixin'
import settings from '@/apis/modules/settings'

export default {
  name: 'index',
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
    BCardBody,
    BCardHeader,
    BFormCheckbox,
    BImg,
    BMedia,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    vSelect,
    BModal,
    BFormFile,
    BOverlay,

    VBTooltip
  },
  mixins: [MomentMixin, Filter],
  directives: {
    Ripple,
    'b-tooltip': VBTooltip
  },
  data () {
    return {
      fileTypeOption: [],
      selected_file_type_id: '',
      isActive: true,
      buttonLoading: false,
      tableLoading: false,
      noDataTable: '',
      uploadedFile: null,
      showFileUploadModal: false,
      modalLoading: false,
      currentPage: 1,
      filters: {},
      filter: null,
      filterOn: [],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: ''
      },
      items: [],
      document: [],
      uploadType: 'new_upload'
    }
  },

  watch: {
    uploadedFile () {
      this.isActive = false
    }
  },

  methods: {
    open () {
      this.showFileUploadModal = true
    },

    replaceDocument (item) {
      this.document = item
      this.uploadType = 'update'
      this.showFileUploadModal = true
    },

    async downloadFile (item) {
      try {
        this.tableLoading = true
        // const response =

        const response = await staffBank.downloadImage(item.id)
        axios
            .get(
                `${apiUrl}/salaried-staff/files/${
                    item.id
                }/download?auth_practice_id=${sessionStorage.getItem(
                    'practice_id'
                )}`,
                {
                  // headers{
                  // }
                  responseType: 'arraybuffer',
                  withCredentials: true
                }
            )
            .then((response) => {
              let blob = new Blob([response.data], {type: 'application/pdf'}),
                  // url = window.URL.createObjectURL(blob)
                  // let blob = new Blob([response.data], { type: 'application/pdf' })
                  link = document.createElement('a')
              link.href = window.URL.createObjectURL(blob)
              link.download = item.name
              link.click()

            })

        this.showSuccessMessage('File Downloaded Successfully')
        this.$refs.table.refresh()
      } catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },

    async uploadFile (type) {
      try {
        if (type === 'update') {
          await staffBankAPI.deleteCertificate(this.document.id)
        }
        this.buttonLoading = true
        fileUploader
            .store(this.uploadedFile, {
              progress: (progress) => {
                this.uploadProgress = Math.round(progress * 100)
              },
              visibility: 'public-read',
              // baseURL: 'http://hyre-be-v2.test/api/mp',
              baseURL: `${baseApiUrl}`,

              params: {
                auth_practice_id: `${sessionStorage.getItem('practice_id')}`
              }
            })
            .then(async (response) => {
              const key = response.key
              await this.setImageKeyToApi(key)
              // await this.resetData()
              // this.isActive = true
            })
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },

    async setImageKeyToApi (key) {
      try {
        let respond = await staffBank.getDocuments({
          key: key,
          name: this.uploadedFile.name
        })
        this.document = respond.data.data
        this.showSuccessMessage('Document Uploaded Successfully')
        this.buttonLoading = false
        this.goBack()
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },

    openFileUploadModel () {
      try {
        this.showFileUploadModal = true
      } catch (error) {
        this.convertAndNotifyError(error)
      }
    },

    goBack () {
      this.showFileUploadModal = false
      this.$emit('getContactDocument', this.document)
    }
  },
  mounted () {
    this.getFileType()
  }
}
</script>

<style scoped></style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
