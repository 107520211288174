var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        [
          _c(
            "b-row",
            { staticClass: "my-n2  ml-0 mr-0" },
            [
              _c("b-col", { staticClass: "pb-2 pt-2", attrs: { cols: "10" } }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center pr-bx mt-n1" },
                    [
                      _c(
                        "b-avatar",
                        { attrs: { variant: "light-primary", rounded: "" } },
                        [
                          _c("feather-icon", {
                            staticStyle: { color: "#4361EE" },
                            attrs: { icon: "CalendarIcon", size: "18" }
                          })
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "ml-1" },
                        [
                          _vm.editLeaveDaysShow
                            ? _c(
                                "b-row",
                                [
                                  _c("b-col", [
                                    _vm.trackLeaveOnDays
                                      ? _c(
                                          "h4",
                                          {
                                            staticClass:
                                              "mb-0 font-weight-bolder ml-n0",
                                            staticStyle: {
                                              "margin-top": "10px",
                                              width: "100px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.allocatedLeaveDays.toFixed(
                                                    1
                                                  )
                                                ) +
                                                " days "
                                            )
                                          ]
                                        )
                                      : _c(
                                          "h4",
                                          {
                                            staticClass:
                                              "mb-0 font-weight-bolder ml-n0",
                                            staticStyle: {
                                              "margin-top": "10px",
                                              width: "100px"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  parseFloat(
                                                    _vm.allocatedLeaveHours
                                                  ).toFixed(1)
                                                ) +
                                                " hrs "
                                            )
                                          ]
                                        ),
                                    _c("div", [
                                      _c("small", { staticClass: "ml-0" }, [
                                        _vm._v("Allocated leave")
                                      ])
                                    ])
                                  ]),
                                  _c(
                                    "b-col",
                                    {},
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass:
                                            "btn-icon bg-white text-primary",
                                          staticStyle: { "margin-top": "3px" },
                                          attrs: {
                                            size: "20",
                                            variant: "outline-white"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.editLeaveDays()
                                            }
                                          }
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: {
                                              icon: "EditIcon",
                                              size: "15"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "mb-0 mr-n4",
                                      attrs: { sm: "10" }
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        { staticClass: "input-group-merge" },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              size: "sm",
                                              placeholder: "",
                                              type: "number"
                                            },
                                            model: {
                                              value: _vm.editNoOfLeaveDays,
                                              callback: function($$v) {
                                                _vm.editNoOfLeaveDays = $$v
                                              },
                                              expression: "editNoOfLeaveDays"
                                            }
                                          }),
                                          _c(
                                            "b-input-group-append",
                                            { attrs: { "is-text": "" } },
                                            [
                                              _c("feather-icon", {
                                                staticClass: "text-primary",
                                                staticStyle: {
                                                  cursor: "pointer"
                                                },
                                                attrs: {
                                                  icon: "CheckIcon",
                                                  size: "15"
                                                },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.editLeaveDays()
                                                  }
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c("div", [
                                        _c("small", { staticClass: "ml-0" }, [
                                          _vm._v("Allocated leave")
                                        ])
                                      ])
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center pr-bx" },
                    [
                      _c("div", { staticClass: "ml-1" }, [
                        _vm.trackLeaveOnDays
                          ? _c(
                              "h4",
                              { staticClass: "mb-0 font-weight-bolder" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      parseFloat(_vm.usedLeaveDays).toFixed(1)
                                    ) +
                                    " days "
                                )
                              ]
                            )
                          : _c(
                              "h4",
                              { staticClass: "mb-0 font-weight-bolder" },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.usedLeaveHours >= 0
                                        ? parseFloat(
                                            _vm.usedLeaveHours
                                          ).toFixed(1)
                                        : "0.0"
                                    ) +
                                    " hrs "
                                )
                              ]
                            ),
                        _c("small", [_vm._v("Used")])
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center pr-bx" },
                    [
                      _c("div", { staticClass: "ml-1" }, [
                        _c("ul", { staticClass: "list-inline mb-0" }, [
                          _c("li", { staticClass: "list-inline-item" }, [
                            _vm.trackLeaveOnDays
                              ? _c(
                                  "h4",
                                  { staticClass: "mb-0 font-weight-bolder" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          parseFloat(
                                            _vm.allocatedLeaveDays -
                                              _vm.usedLeaveDays
                                          ).toFixed(1)
                                        ) +
                                        " days "
                                    )
                                  ]
                                )
                              : _c(
                                  "h4",
                                  { staticClass: "mb-0 font-weight-bolder" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          parseFloat(
                                            _vm.remainingLeaveHours
                                          ).toFixed(1)
                                        ) +
                                        " hrs "
                                    )
                                  ]
                                )
                          ])
                        ]),
                        _c("small", [_vm._v("Remaining")])
                      ])
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "d-flex align-items-center ml-1" },
                    [
                      _c(
                        "b-avatar",
                        { attrs: { variant: "light-warning", rounded: "" } },
                        [
                          _c("feather-icon", {
                            attrs: { icon: "LoaderIcon", size: "18" }
                          })
                        ],
                        1
                      ),
                      _c("div", { staticClass: "ml-1" }, [
                        _c("h4", { staticClass: "mb-0 font-weight-bolder" }, [
                          _vm._v(" " + _vm._s(_vm.leaveRequestCount) + " ")
                        ]),
                        _c("small", [_vm._v("Leave requests")])
                      ])
                    ],
                    1
                  )
                ])
              ]),
              _c(
                "b-col",
                {
                  staticClass: "pt-0 pb-2 pt-xl-2 pb-xl-0 ml-n1 ",
                  attrs: { cols: "2" }
                },
                [
                  _c("b-row", [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-end  mt-2 mt-md-0 pl-0 pl-md-0 ml-auto"
                      },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "float-right",
                            attrs: { size: "md", variant: "primary" },
                            on: {
                              click: function($event) {
                                return _vm.toggleAddLeaveSideBar()
                              }
                            }
                          },
                          [_vm._v("+ Add Leave ")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c(
            "b-card-body",
            { staticClass: " pl-1 " },
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c(
                      "div",
                      {
                        staticClass: "ml-1",
                        staticStyle: { "padding-top": "8px" }
                      },
                      [
                        _c("h4", { staticClass: " font-weight-700 mt-1" }, [
                          _vm._v(" Leave Requests "),
                          _vm.totalLeaveRequests > 0
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "translate-middle badge rounded-circle bg-danger",
                                  staticStyle: { "font-size": "0.8em" }
                                },
                                [_vm._v(_vm._s(_vm.totalLeaveRequests) + " ")]
                              )
                            : _c("span", [
                                _vm._v(
                                  "(" + _vm._s(_vm.totalLeaveRequests) + ")"
                                )
                              ])
                        ])
                      ]
                    )
                  ]),
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-overlay",
                        { attrs: { show: _vm.tableLoading, rounded: "sm" } },
                        [
                          _c("b-table", {
                            ref: "table_leave_requests",
                            staticClass: "mobile_table_css",
                            attrs: {
                              "current-page": _vm.currentPage,
                              "per-page": _vm.pagination.perPage,
                              fields: _vm.fields,
                              items: _vm.leaveRequests,
                              "sort-by": _vm.sortBy,
                              "sort-desc": _vm.sortDesc,
                              "sort-direction": _vm.sortDirection,
                              hover: "",
                              responsive: ""
                            },
                            on: {
                              "update:sortBy": function($event) {
                                _vm.sortBy = $event
                              },
                              "update:sort-by": function($event) {
                                _vm.sortBy = $event
                              },
                              "update:sortDesc": function($event) {
                                _vm.sortDesc = $event
                              },
                              "update:sort-desc": function($event) {
                                _vm.sortDesc = $event
                              }
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "cell(date)",
                                fn: function(data) {
                                  return [
                                    _c(
                                      "div",
                                      { staticStyle: { width: "150px" } },
                                      [
                                        _c(
                                          "b-row",
                                          {
                                            staticClass: "center text-primary"
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "ml-1 font-weight-bolder pt-1 text-dark"
                                              },
                                              [_vm._v(_vm._s(data.item.date))]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              },
                              {
                                key: "cell(time)",
                                fn: function(data) {
                                  return [
                                    _c(
                                      "div",
                                      { staticStyle: { width: "80px" } },
                                      [
                                        _c(
                                          "b-row",
                                          {
                                            staticClass: "center text-primary"
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass:
                                                  "ml-1 font-weight-bolder pt-1 text-dark"
                                              },
                                              [_vm._v(_vm._s(data.item.time))]
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              },
                              {
                                key: "cell(duration)",
                                fn: function(data) {
                                  return [
                                    data.item.is_day
                                      ? _c("div", [
                                          data.item.duration > 1
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(data.item.duration) +
                                                    " days"
                                                )
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(data.item.duration) +
                                                    " day"
                                                )
                                              ])
                                        ])
                                      : _c("div", [
                                          data.item.duration > 1
                                            ? _c("span", [
                                                _vm._v(
                                                  _vm._s(data.item.duration) +
                                                    " hours"
                                                )
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(data.item.duration) +
                                                    " hour"
                                                )
                                              ])
                                        ])
                                  ]
                                }
                              },
                              {
                                key: "cell(action)",
                                fn: function(data) {
                                  return [
                                    _c(
                                      "div",
                                      { staticStyle: { width: "180px" } },
                                      [
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.top",
                                                value: "Edit",
                                                expression: "'Edit'",
                                                modifiers: {
                                                  hover: true,
                                                  top: true
                                                }
                                              }
                                            ],
                                            staticClass:
                                              "btn-icon shadow-sm bg-white text-primary mr-1 ",
                                            attrs: {
                                              size: "23",
                                              variant: "outline-white"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.toggleSideBars(
                                                  data.item
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: {
                                                icon: "EditIcon",
                                                size: "18"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.top",
                                                value: "Accept",
                                                expression: "'Accept'",
                                                modifiers: {
                                                  hover: true,
                                                  top: true
                                                }
                                              }
                                            ],
                                            staticClass:
                                              "btn-icon shadow-sm bg-white text-primary mr-1",
                                            attrs: {
                                              size: "23",
                                              variant: "outline-white"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.approveLeave(
                                                  data.item.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: {
                                                icon: "CheckSquareIcon",
                                                size: "18"
                                              }
                                            })
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-button",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.hover.top",
                                                value: "Reject",
                                                expression: "'Reject'",
                                                modifiers: {
                                                  hover: true,
                                                  top: true
                                                }
                                              }
                                            ],
                                            staticClass:
                                              "btn-icon shadow-sm bg-white text-danger",
                                            attrs: {
                                              size: "23",
                                              variant: "outline-white"
                                            },
                                            on: {
                                              click: function($event) {
                                                return _vm.rejectLeave(
                                                  data.item.id
                                                )
                                              }
                                            }
                                          },
                                          [
                                            _c("feather-icon", {
                                              attrs: {
                                                icon: "XSquareIcon",
                                                size: "18",
                                                variant: "outline-danger"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center justify-content-sm-start",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _vm.pagination1.totalRows !== 0
                        ? _c("span", { staticClass: "text-muted" }, [
                            _vm._v(
                              "Showing " +
                                _vm._s(_vm.pagination1.from) +
                                " to " +
                                _vm._s(_vm.pagination1.to) +
                                " of " +
                                _vm._s(_vm.pagination1.totalRows) +
                                " entries"
                            )
                          ])
                        : _vm._e()
                    ]
                  ),
                  _c(
                    "b-col",
                    {
                      staticClass:
                        "d-flex align-items-center justify-content-center justify-content-sm-end",
                      attrs: { cols: "12", sm: "6" }
                    },
                    [
                      _c("b-pagination", {
                        staticClass: "mb-0 mt-1 mt-sm-0 ",
                        attrs: {
                          "per-page": _vm.pagination1.perPage,
                          "total-rows": _vm.pagination1.totalRows,
                          "first-number": "",
                          "last-number": "",
                          "next-class": "next-item",
                          "prev-class": "prev-item"
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "prev-text",
                            fn: function() {
                              return [
                                _c("feather-icon", {
                                  attrs: { icon: "ChevronLeftIcon", size: "18" }
                                })
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "next-text",
                            fn: function() {
                              return [
                                _c("feather-icon", {
                                  attrs: {
                                    icon: "ChevronRightIcon",
                                    size: "18"
                                  }
                                })
                              ]
                            },
                            proxy: true
                          }
                        ]),
                        model: {
                          value: _vm.currentPage,
                          callback: function($$v) {
                            _vm.currentPage = $$v
                          },
                          expression: "currentPage"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("b-card", { staticClass: "mb-0", attrs: { "no-body": "" } }, [
        _c(
          "div",
          [
            _c("b-row", [
              _c("h4", { staticClass: "ml-3 mb-1 mt-1 font-weight-700 " }, [
                _vm._v(" Scheduled Leaves "),
                _c("span", [
                  _vm._v("(" + _vm._s(_vm.pagination2.totalRows) + ")")
                ])
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "b-overlay",
              { attrs: { show: _vm.tableLoading, rounded: "sm" } },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "ml-2 mr-2" },
                      [
                        _c("b-table", {
                          ref: "table_sheduled_leaves",
                          staticClass: "mobile_table_css",
                          attrs: {
                            "current-page": _vm.currentPage1,
                            "per-page": _vm.pagination.perPage,
                            fields: _vm.fields,
                            items: _vm.scheduledLeaves,
                            "sort-by": _vm.sortBy,
                            "sort-desc": _vm.sortDesc,
                            "sort-direction": _vm.sortDirection,
                            hover: "",
                            responsive: ""
                          },
                          on: {
                            "update:sortBy": function($event) {
                              _vm.sortBy = $event
                            },
                            "update:sort-by": function($event) {
                              _vm.sortBy = $event
                            },
                            "update:sortDesc": function($event) {
                              _vm.sortDesc = $event
                            },
                            "update:sort-desc": function($event) {
                              _vm.sortDesc = $event
                            }
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "cell(date)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { width: "150px" } },
                                    [
                                      _c(
                                        "b-row",
                                        { staticClass: "center text-primary" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "ml-1 font-weight-bolder pt-1 text-dark"
                                            },
                                            [_vm._v(_vm._s(data.item.date))]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(time)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { width: "80px" } },
                                    [
                                      _c(
                                        "b-row",
                                        { staticClass: "center text-primary" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "ml-1 font-weight-bolder pt-1 text-dark"
                                            },
                                            [_vm._v(_vm._s(data.item.time))]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(action)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { width: "200px" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover.top",
                                              value: "Edit",
                                              expression: "'Edit'",
                                              modifiers: {
                                                hover: true,
                                                top: true
                                              }
                                            }
                                          ],
                                          staticClass:
                                            "btn-icon shadow-sm bg-white text-primary mr-1",
                                          attrs: {
                                            size: "23",
                                            variant: "outline-white"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.toggleSideBars(
                                                data.item
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: {
                                              icon: "EditIcon",
                                              size: "18"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover.top",
                                              value: "Cancel",
                                              expression: "'Cancel'",
                                              modifiers: {
                                                hover: true,
                                                top: true
                                              }
                                            }
                                          ],
                                          staticClass:
                                            "btn-icon shadow-sm bg-white text-danger",
                                          attrs: {
                                            size: "23",
                                            variant: "outline-white"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.cancelLeave(
                                                data.item.id
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Cancel ")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mx-1 mb-2" },
              [
                _c(
                  "b-row",
                  [
                    _vm.noDataTable === 0
                      ? _c(
                          "b-col",
                          { staticClass: "text-center", attrs: { cols: "12" } },
                          [_c("span", [_vm._v("No data for preview")])]
                        )
                      : _vm._e(),
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-center justify-content-sm-start",
                        attrs: { cols: "12", sm: "6" }
                      },
                      [
                        _vm.pagination2.totalRows !== 0
                          ? _c("span", { staticClass: "text-muted" }, [
                              _vm._v(
                                "Showing " +
                                  _vm._s(_vm.pagination2.from) +
                                  " to " +
                                  _vm._s(_vm.pagination2.to) +
                                  " of " +
                                  _vm._s(_vm.pagination2.totalRows) +
                                  " entries"
                              )
                            ])
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-center justify-content-sm-end",
                        attrs: { cols: "12", sm: "6" }
                      },
                      [
                        _c("b-pagination", {
                          staticClass: "mb-0 mt-1 mt-sm-0 ",
                          attrs: {
                            "per-page": _vm.pagination2.perPage,
                            "total-rows": _vm.pagination2.totalRows,
                            "first-number": "",
                            "last-number": "",
                            "next-class": "next-item",
                            "prev-class": "prev-item"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "prev-text",
                              fn: function() {
                                return [
                                  _c("feather-icon", {
                                    attrs: {
                                      icon: "ChevronLeftIcon",
                                      size: "18"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "next-text",
                              fn: function() {
                                return [
                                  _c("feather-icon", {
                                    attrs: {
                                      icon: "ChevronRightIcon",
                                      size: "18"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.currentPage1,
                            callback: function($$v) {
                              _vm.currentPage1 = $$v
                            },
                            expression: "currentPage1"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("b-card", { staticClass: "mb-2 mt-2", attrs: { "no-body": "" } }, [
        _c(
          "div",
          [
            _c("b-row", [
              _c(
                "h4",
                { staticClass: "ml-3 mb-1 mt-1  font-weight-700 mt-1" },
                [
                  _vm._v(" Bank Holidays "),
                  _c("span", [
                    _vm._v("(" + _vm._s(_vm.pagination4.totalRows) + ")")
                  ])
                ]
              )
            ])
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "b-overlay",
              { attrs: { show: _vm.tableLoading, rounded: "sm" } },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "ml-2 mr-2" },
                      [
                        _c("b-table", {
                          ref: "table_bank_leaves",
                          staticClass: "mobile_table_css",
                          attrs: {
                            "current-page": _vm.currentPage2,
                            "per-page": _vm.pagination4.perPage,
                            fields: _vm.fields4,
                            items: _vm.bankHolidayLeaves,
                            "sort-by": _vm.sortBy,
                            "sort-desc": _vm.sortDesc,
                            "sort-direction": _vm.sortDirection,
                            hover: "",
                            responsive: ""
                          },
                          on: {
                            "update:sortBy": function($event) {
                              _vm.sortBy = $event
                            },
                            "update:sort-by": function($event) {
                              _vm.sortBy = $event
                            },
                            "update:sortDesc": function($event) {
                              _vm.sortDesc = $event
                            },
                            "update:sort-desc": function($event) {
                              _vm.sortDesc = $event
                            }
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "cell(date)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { width: "150px" } },
                                    [
                                      _c(
                                        "b-row",
                                        { staticClass: "center text-primary" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "ml-1 font-weight-bolder pt-1 text-dark"
                                            },
                                            [_vm._v(_vm._s(data.item.date))]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(time)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { width: "80px" } },
                                    [
                                      _c(
                                        "b-row",
                                        { staticClass: "center text-primary" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "ml-1 font-weight-bolder pt-1 text-dark"
                                            },
                                            [_vm._v(_vm._s(data.item.time))]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(action)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { width: "100px" } },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName: "v-b-tooltip.hover.top",
                                              value: "Cancel",
                                              expression: "'Cancel'",
                                              modifiers: {
                                                hover: true,
                                                top: true
                                              }
                                            }
                                          ],
                                          staticClass:
                                            "btn-icon shadow-sm bg-white text-danger",
                                          attrs: {
                                            size: "23",
                                            variant: "outline-white"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.cancelLeave(
                                                data.item.id
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(" Cancel ")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mx-1 mb-2" },
              [
                _c(
                  "b-row",
                  [
                    _vm.noDataTable === 0
                      ? _c(
                          "b-col",
                          { staticClass: "text-center", attrs: { cols: "12" } },
                          [_c("span", [_vm._v("No data for preview")])]
                        )
                      : _vm._e(),
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-center justify-content-sm-start",
                        attrs: { cols: "12", sm: "6" }
                      },
                      [
                        _vm.pagination4.totalRows !== 0
                          ? _c("span", { staticClass: "text-muted" }, [
                              _vm._v(
                                "Showing " +
                                  _vm._s(_vm.pagination4.from) +
                                  " to " +
                                  _vm._s(_vm.pagination4.to) +
                                  " of " +
                                  _vm._s(_vm.pagination4.totalRows) +
                                  " entries"
                              )
                            ])
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-center justify-content-sm-end",
                        attrs: { cols: "12", sm: "6" }
                      },
                      [
                        _c("b-pagination", {
                          staticClass: "mb-0 mt-1 mt-sm-0 ",
                          attrs: {
                            "per-page": _vm.pagination4.perPage,
                            "total-rows": _vm.pagination4.totalRows,
                            "first-number": "",
                            "last-number": "",
                            "next-class": "next-item",
                            "prev-class": "prev-item"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "prev-text",
                              fn: function() {
                                return [
                                  _c("feather-icon", {
                                    attrs: {
                                      icon: "ChevronLeftIcon",
                                      size: "18"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "next-text",
                              fn: function() {
                                return [
                                  _c("feather-icon", {
                                    attrs: {
                                      icon: "ChevronRightIcon",
                                      size: "18"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.currentPage2,
                            callback: function($$v) {
                              _vm.currentPage2 = $$v
                            },
                            expression: "currentPage2"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("b-card", { staticClass: "mb-2 mt-2", attrs: { "no-body": "" } }, [
        _c(
          "div",
          [
            _c("b-row", [
              _c("h4", { staticClass: "ml-3 mt-2 mb-1  font-weight-700" }, [
                _vm._v(" Past Leaves "),
                _c("span", [
                  _vm._v("(" + _vm._s(_vm.pagination.totalRows) + ")")
                ])
              ])
            ])
          ],
          1
        ),
        _c(
          "div",
          [
            _c(
              "b-overlay",
              { attrs: { show: _vm.tableLoading, rounded: "sm" } },
              [
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      { staticClass: "ml-2 mr-2" },
                      [
                        _c("b-table", {
                          ref: "table",
                          staticClass: "position-relative mobile_table_css",
                          attrs: {
                            "current-page": _vm.currentPage3,
                            "per-page": _vm.pagination.perPage,
                            fields: _vm.fields1,
                            items: _vm.getPastLeaves,
                            "sort-by": _vm.sortBy,
                            "sort-desc": _vm.sortDesc,
                            "sort-direction": _vm.sortDirection,
                            "empty-text": "",
                            hover: "",
                            "primary-key": "id",
                            responsive: "",
                            "show-empty": ""
                          },
                          on: {
                            "update:sortBy": function($event) {
                              _vm.sortBy = $event
                            },
                            "update:sort-by": function($event) {
                              _vm.sortBy = $event
                            },
                            "update:sortDesc": function($event) {
                              _vm.sortDesc = $event
                            },
                            "update:sort-desc": function($event) {
                              _vm.sortDesc = $event
                            }
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "cell(date)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { width: "150px" } },
                                    [
                                      _c(
                                        "b-row",
                                        { staticClass: "center text-primary" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "ml-1 font-weight-bolder pt-1 text-dark"
                                            },
                                            [_vm._v(_vm._s(data.item.date))]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(time)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "div",
                                    { staticStyle: { width: "80px" } },
                                    [
                                      _c(
                                        "b-row",
                                        { staticClass: "center text-primary" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "ml-1 font-weight-bolder pt-1 text-dark"
                                            },
                                            [_vm._v(_vm._s(data.item.time))]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            },
                            {
                              key: "cell(status)",
                              fn: function(data) {
                                return [
                                  data.item.status == "Requested"
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {},
                                            [
                                              _c(
                                                "b-badge",
                                                {
                                                  attrs: {
                                                    variant: "light-warning"
                                                  }
                                                },
                                                [_vm._v(" Pending review ")]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : data.item.status == "Approved"
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {},
                                            [
                                              _c(
                                                "b-badge",
                                                {
                                                  attrs: {
                                                    variant: "light-success"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.item.status) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : data.item.status == "Cancelled"
                                    ? _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {},
                                            [
                                              _c(
                                                "b-badge",
                                                {
                                                  attrs: {
                                                    variant: "light-secondary"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.item.status) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-col",
                                            {},
                                            [
                                              _c(
                                                "b-badge",
                                                {
                                                  attrs: {
                                                    variant: "light-danger"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(data.item.status) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                ]
                              }
                            }
                          ])
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mx-1 mb-2" },
              [
                _c(
                  "b-row",
                  [
                    _vm.noDataTable === 0
                      ? _c(
                          "b-col",
                          { staticClass: "text-center", attrs: { cols: "12" } },
                          [_c("span", [_vm._v("No data for preview")])]
                        )
                      : _vm._e(),
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-center justify-content-sm-start",
                        attrs: { cols: "12", sm: "6" }
                      },
                      [
                        _vm.pagination.totalRows !== 0
                          ? _c("span", { staticClass: "text-muted" }, [
                              _vm._v(
                                "Showing " +
                                  _vm._s(_vm.pagination.from) +
                                  " to " +
                                  _vm._s(_vm.pagination.to) +
                                  " of " +
                                  _vm._s(_vm.pagination.totalRows) +
                                  " entries"
                              )
                            ])
                          : _vm._e()
                      ]
                    ),
                    _c(
                      "b-col",
                      {
                        staticClass:
                          "d-flex align-items-center justify-content-center justify-content-sm-end",
                        attrs: { cols: "12", sm: "6" }
                      },
                      [
                        _c("b-pagination", {
                          staticClass: "mb-0 mt-1 mt-sm-0 ",
                          attrs: {
                            "per-page": _vm.pagination.perPage,
                            "total-rows": _vm.pagination.totalRows,
                            "first-number": "",
                            "last-number": "",
                            "next-class": "next-item",
                            "prev-class": "prev-item"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "prev-text",
                              fn: function() {
                                return [
                                  _c("feather-icon", {
                                    attrs: {
                                      icon: "ChevronLeftIcon",
                                      size: "18"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "next-text",
                              fn: function() {
                                return [
                                  _c("feather-icon", {
                                    attrs: {
                                      icon: "ChevronRightIcon",
                                      size: "18"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.currentPage3,
                            callback: function($$v) {
                              _vm.currentPage3 = $$v
                            },
                            expression: "currentPage3"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      ]),
      _c("EditLeaveSidebar", {
        ref: "EditLeaveSidebar",
        attrs: {
          "is-edit-leave-sidebar-active": _vm.isEditLeaveSidebarActive,
          "staff-details": _vm.staffMember
        },
        on: {
          toggleSideBars: function($event) {
            return _vm.toggle()
          }
        }
      }),
      _c("AddLeaveSidebar", {
        ref: "AddLeaveSidebar",
        attrs: { "is-add-leave-sidebar-active": _vm.isAddLeaveSidebarActive },
        on: {
          toggleSideBars: function($event) {
            return _vm.toggleAddLeaveSideBar()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }