import { render, staticRenderFns } from "./documentsAndTraining.vue?vue&type=template&id=7ce54b84&scoped=true&"
import script from "./documentsAndTraining.vue?vue&type=script&lang=js&"
export * from "./documentsAndTraining.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7ce54b84",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("E:\\10QBIT\\Hyre\\hyre_mp_fe\\node_modules\\vue-hot-reload-api\\dist\\index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7ce54b84')) {
      api.createRecord('7ce54b84', component.options)
    } else {
      api.reload('7ce54b84', component.options)
    }
    module.hot.accept("./documentsAndTraining.vue?vue&type=template&id=7ce54b84&scoped=true&", function () {
      api.rerender('7ce54b84', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/staff-bank/staff-profile/includes/documentsAndTraining.vue"
export default component.exports