var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-sidebar", {
    attrs: {
      id: "add-document-sidebar",
      visible: _vm.isAddDocumentSidebarActive,
      backdrop: "",
      "bg-variant": "white",
      "no-header": "",
      right: "",
      shadow: "",
      "sidebar-class": "sidebar-lg"
    },
    on: {
      change: function(val) {
        return _vm.$emit("update:is-add-document-sidebar-active", val)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hide = ref.hide
          return [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
              },
              [
                _c("h4", { staticClass: "mb-0" }, [_vm._v("Add Document")]),
                _c("feather-icon", {
                  staticClass: "ml-1 cursor-pointer",
                  attrs: { icon: "XIcon", size: "16" },
                  on: {
                    click: function($event) {
                      _vm.clearForm()
                      hide()
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "b-overlay",
              { attrs: { show: _vm.loadingModel, rounded: "sm" } },
              [
                _c(
                  "validation-observer",
                  { ref: "refFormObserver" },
                  [
                    _c(
                      "b-overlay",
                      { attrs: { show: _vm.formLoading, rounded: "sm" } },
                      [
                        _c(
                          "div",
                          { staticClass: "p-2" },
                          [
                            _c(
                              "div",
                              { staticClass: "alert d-flex font-weight-bold" },
                              [
                                _c("label", { staticClass: "font-small-4" }, [
                                  _vm._v(" Select Document")
                                ])
                              ]
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Document",
                                        rules: "required"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("b-form-file", {
                                                  ref: "image_ref",
                                                  attrs: {
                                                    "drop-placeholder":
                                                      "Drop file here...",
                                                    placeholder:
                                                      "Choose a file or drop it here..."
                                                  },
                                                  model: {
                                                    value: _vm.uploadedFile,
                                                    callback: function($$v) {
                                                      _vm.uploadedFile = $$v
                                                    },
                                                    expression: "uploadedFile"
                                                  }
                                                }),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger"
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  {
                                    staticClass: "mb-1",
                                    attrs: { cols: "12" }
                                  },
                                  [
                                    _c("label", [_vm._v("Document Type")]),
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Reason",
                                        rules: "required"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c(
                                                  "b-form-group",
                                                  [
                                                    _c("v-select", {
                                                      attrs: {
                                                        options:
                                                          _vm.fileTypeOption,
                                                        reduce: function(
                                                          fileTypeOption
                                                        ) {
                                                          return fileTypeOption.id
                                                        },
                                                        label: "name",
                                                        placeholder: "Select"
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.selected_file_type_id,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.selected_file_type_id = $$v
                                                        },
                                                        expression:
                                                          "selected_file_type_id"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger"
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          label:
                                            "Document Description (optional)",
                                          "label-for": "document"
                                        }
                                      },
                                      [
                                        _c("b-form-input", {
                                          attrs: {
                                            id: "document",
                                            name: "document",
                                            type: "text",
                                            placeholder:
                                              "e.g. This is a renewed ID"
                                          },
                                          model: {
                                            value: _vm.description,
                                            callback: function($$v) {
                                              _vm.description = $$v
                                            },
                                            expression: "description"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("b-row", [
                              _c("span", { staticClass: "mr-1 mt-2 ml-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex mt-1" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple.400",
                                            value: "rgba(255, 255, 255, 0.15)",
                                            expression:
                                              "'rgba(255, 255, 255, 0.15)'",
                                            modifiers: { "400": true }
                                          }
                                        ],
                                        attrs: {
                                          type: "submit",
                                          variant: "primary",
                                          disabled: _vm.isActive
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.uploadFile()
                                          }
                                        }
                                      },
                                      [_vm._v(" Save ")]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple.400",
                                            value: "rgba(186, 191, 199, 0.15)",
                                            expression:
                                              "'rgba(186, 191, 199, 0.15)'",
                                            modifiers: { "400": true }
                                          }
                                        ],
                                        staticClass: "text-primary ml-1 shadow",
                                        attrs: {
                                          type: "button",
                                          variant: "outline-white"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.clearForm()
                                            hide()
                                          }
                                        }
                                      },
                                      [_vm._v(" Cancel ")]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ])
                          ],
                          1
                        )
                      ]
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }