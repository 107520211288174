var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "add_mandatory_training_model",
            centered: "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            size: "lg",
            title: "Add Mandatory Training"
          },
          on: { change: _vm.clearData },
          model: {
            value: _vm.show,
            callback: function($$v) {
              _vm.show = $$v
            },
            expression: "show"
          }
        },
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.loadingModel, rounded: "sm" } },
            [
              _c(
                "b-card-body",
                [
                  _c(
                    "validation-observer",
                    { ref: "trainingForm" },
                    [
                      _c("span", [
                        _vm._v(
                          "Choose the trainings that are applicable for the staff’s role. "
                        )
                      ]),
                      _c("hr"),
                      _c(
                        "div",
                        { staticClass: "mt-1 ml-n1 mr-n1" },
                        [
                          _c("b-row", [
                            _c(
                              "span",
                              {
                                staticClass: "ml-2 mt-1 mb-1 ml-auto mr-auto",
                                staticStyle: {
                                  "font-family": "Mulish",
                                  "font-style": "normal",
                                  "font-weight": "bold",
                                  "font-size": "14px",
                                  "line-height": "21px",
                                  color: "#171822"
                                }
                              },
                              [
                                _c(
                                  "b-form-group",
                                  { staticStyle: { "margin-top": "6px" } },
                                  [
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Training",
                                        rules: "required"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function(ref) {
                                            var errors = ref.errors
                                            return [
                                              _c("v-select", {
                                                staticStyle: { width: "400px" },
                                                attrs: {
                                                  label: "name",
                                                  options: _vm.fileTypeOption,
                                                  reduce: function(
                                                    fileTypeOption
                                                  ) {
                                                    return fileTypeOption.id
                                                  },
                                                  placeholder: "Select training"
                                                },
                                                model: {
                                                  value: _vm.form.training_id,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "training_id",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.training_id"
                                                }
                                              }),
                                              _c(
                                                "small",
                                                { staticClass: "text-danger" },
                                                [_vm._v(_vm._s(errors[0]))]
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "margin-left": "6px",
                                        "font-size": "12px"
                                      }
                                    },
                                    [_vm._v("Date of Training")]
                                  ),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticStyle: {
                                        "margin-left": "6px",
                                        "margin-top": "6px"
                                      }
                                    },
                                    [
                                      _c("validation-provider", {
                                        attrs: {
                                          name: "Start date",
                                          rules: "required"
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c(
                                                  "b-input-group",
                                                  {
                                                    staticClass:
                                                      "input-group-merge"
                                                  },
                                                  [
                                                    _c("b-form-datepicker", {
                                                      staticClass:
                                                        "form-control",
                                                      attrs: {
                                                        id: "start_date",
                                                        "date-format-options": {
                                                          year: "numeric",
                                                          month: "2-digit",
                                                          day: "2-digit"
                                                        },
                                                        placeholder: "Select",
                                                        "start-weekday": "1",
                                                        locale: "en-UK",
                                                        trim: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form.start_date,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            "start_date",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "form.start_date"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger"
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              ]
                                            }
                                          }
                                        ])
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "margin-left": "0px",
                                        "font-size": "12px"
                                      }
                                    },
                                    [_vm._v("Renewal frequency")]
                                  ),
                                  _c(
                                    "b-form-group",
                                    {
                                      staticStyle: {
                                        "margin-left": "-5px",
                                        "margin-right": "-5px",
                                        "margin-top": "6px"
                                      }
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          disabled: _vm.renewalDesabled,
                                          label: "name",
                                          placeholder: "Select",
                                          options: _vm.renewal_options,
                                          reduce: function(renewal_options) {
                                            return renewal_options.key
                                          }
                                        },
                                        model: {
                                          value: _vm.seleceted_renewal,
                                          callback: function($$v) {
                                            _vm.seleceted_renewal = $$v
                                          },
                                          expression: "seleceted_renewal"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "3" } },
                                [
                                  _c(
                                    "b-form-checkbox",
                                    {
                                      staticStyle: { "margin-top": "32px" },
                                      model: {
                                        value: _vm.form.is_non_renewal,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "is_non_renewal",
                                            $$v
                                          )
                                        },
                                        expression: "form.is_non_renewal"
                                      }
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "todo-title" },
                                        [_vm._v("Non-renewal")]
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticStyle: { "margin-top": "30px" },
                                  attrs: { cols: "3" }
                                },
                                [
                                  _vm.uploadedCertificate.name
                                    ? _c("div", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.uploadedCertificate.name
                                            ) +
                                            " "
                                        )
                                      ])
                                    : _c(
                                        "a",
                                        {
                                          attrs: { href: "#" },
                                          on: {
                                            click: function($event) {
                                              return _vm.openUploadDocumentPopup()
                                            }
                                          }
                                        },
                                        [_vm._v("Upload document")]
                                      )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("b-row", [
                        _c("span", { staticClass: "ml-2 mt-1 ml-auto" }, [
                          _c(
                            "div",
                            { staticClass: "d-flex mt-1 ml-auto" },
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(255, 255, 255, 0.15)",
                                      expression: "'rgba(255, 255, 255, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  attrs: { type: "submit", variant: "primary" },
                                  on: { click: _vm.submitForm }
                                },
                                [_vm._v(" Save ")]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(186, 191, 199, 0.15)",
                                      expression: "'rgba(186, 191, 199, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  staticClass: "text-primary ml-1 shadow",
                                  attrs: {
                                    type: "button",
                                    variant: "outline-white"
                                  },
                                  on: { click: _vm.clearData }
                                },
                                [_vm._v(" Cancel ")]
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("UploadCertificate", {
        ref: "uploadCertificate",
        on: { getDocument: _vm.getDocument }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }