var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "match-height" },
        [
          _vm.openForm === true
            ? _c(
                "b-col",
                { attrs: { lg: "4", md: "6" } },
                [
                  _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        { staticClass: "border-bottom pb-1" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row" },
                            [
                              _c(
                                "b-avatar",
                                {
                                  attrs: {
                                    rounded: "",
                                    variant: "light-primary"
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "text-dark font-weight-700",
                                    attrs: { icon: "UserIcon", size: "18" }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: { "padding-top": "8px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "bold",
                                        "font-size": "20px",
                                        "line-height": "25px",
                                        color: "#171822"
                                      }
                                    },
                                    [_vm._v(" Personal Details ")]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ml-auto" }, [
                            _c("span", { staticStyle: { color: "#EFA12C" } }, [
                              _vm._v("•")
                            ]),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "5px" } },
                              [_vm._v("Editing")]
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-card-body",
                        [
                          _c(
                            "validation-observer",
                            { ref: "userEditValidateForm" },
                            [
                              _c(
                                "b-form",
                                {
                                  staticClass: "auth-login-form mt-2",
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: " ",
                                      attrs: { cols: "12", xl: "12" }
                                    },
                                    [
                                      _c(
                                        "b-row",
                                        { staticClass: "mt-2" },
                                        [
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12", md: "12" } },
                                            [
                                              _c("label", [
                                                _vm._v(" First Name")
                                              ]),
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "First Name",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            staticClass: "mb-1",
                                                            attrs: {
                                                              id:
                                                                "h-first-name",
                                                              placeholder:
                                                                "First Name"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form
                                                                  .first_name,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.form,
                                                                  "first_name",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "form.first_name"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  3959212540
                                                )
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12", md: "12" } },
                                            [
                                              _c("label", [
                                                _vm._v(" Second Name")
                                              ]),
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "Last Name",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("b-form-input", {
                                                            staticClass: "mb-1",
                                                            attrs: {
                                                              id: "h-last-name",
                                                              placeholder:
                                                                "Last Name"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form
                                                                  .last_name,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.form,
                                                                  "last_name",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "form.last_name"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  2183989452
                                                )
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12", md: "12" } },
                                            [
                                              _c("label", [_vm._v("DOB")]),
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "Date",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-form-group",
                                                            [
                                                              _c(
                                                                "b-form-datepicker",
                                                                {
                                                                  staticClass:
                                                                    "form-control",
                                                                  attrs: {
                                                                    id: "date",
                                                                    "date-format-options": {
                                                                      month:
                                                                        "numeric",
                                                                      day:
                                                                        "numeric",
                                                                      year:
                                                                        "numeric"
                                                                    },
                                                                    min:
                                                                      _vm.min,
                                                                    locale:
                                                                      "en-UK",
                                                                    placeholder:
                                                                      "Selects",
                                                                    "start-weekday":
                                                                      "1",
                                                                    trim: ""
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.form
                                                                        .dob,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.form,
                                                                        "dob",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "form.dob"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  3854417489
                                                )
                                              })
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "6", md: "12" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "NI Number",
                                                    "label-for": "h-Ni_number"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "NI Number",
                                                      rules: "numeric"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-input-group",
                                                                {
                                                                  staticClass:
                                                                    "input-group-merge"
                                                                },
                                                                [
                                                                  _c(
                                                                    "b-form-input",
                                                                    {
                                                                      attrs: {
                                                                        id:
                                                                          "icons-Ni_number",
                                                                        placeholder:
                                                                          "Enter NI Number"
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .form
                                                                            .ni_number,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.form,
                                                                            "ni_number",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "form.ni_number"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      593212042
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "6", md: "12" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Gender",
                                                    "label-for": "h-Gender"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "Gender",
                                                      rules: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-input-group",
                                                                {
                                                                  staticClass:
                                                                    "input-group-merge"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-select",
                                                                    {
                                                                      staticClass:
                                                                        "w-100",
                                                                      attrs: {
                                                                        reduce: function(
                                                                          title
                                                                        ) {
                                                                          return title.value
                                                                        },
                                                                        options: [
                                                                          {
                                                                            title:
                                                                              "Male",
                                                                            value:
                                                                              "Male"
                                                                          },
                                                                          {
                                                                            title:
                                                                              "Female",
                                                                            value:
                                                                              "Female"
                                                                          },
                                                                          {
                                                                            title:
                                                                              "Non-binary",
                                                                            value:
                                                                              "Non-binary"
                                                                          }
                                                                        ],
                                                                        label:
                                                                          "title",
                                                                        placeholder:
                                                                          "Select Gender"
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .form
                                                                            .gender,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.form,
                                                                            "gender",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "form.gender"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1579453
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "6", md: "12" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Preferred Pronouns",
                                                    "label-for":
                                                      "h-Preferred_pronouns"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name:
                                                        "Preferred Pronouns",
                                                      rules: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-input-group",
                                                                {
                                                                  staticClass:
                                                                    "input-group-merge"
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-select",
                                                                    {
                                                                      staticClass:
                                                                        "w-100",
                                                                      attrs: {
                                                                        reduce: function(
                                                                          title
                                                                        ) {
                                                                          return title.value
                                                                        },
                                                                        options: [
                                                                          {
                                                                            title:
                                                                              "They/Them",
                                                                            value:
                                                                              "They/Them"
                                                                          },
                                                                          {
                                                                            title:
                                                                              "He/Him",
                                                                            value:
                                                                              "He/Him"
                                                                          },
                                                                          {
                                                                            title:
                                                                              "She/Her",
                                                                            value:
                                                                              "She/Her"
                                                                          },
                                                                          {
                                                                            title:
                                                                              "Prefer not to say",
                                                                            value:
                                                                              "Prefer not to say"
                                                                          }
                                                                        ],
                                                                        label:
                                                                          "title",
                                                                        placeholder:
                                                                          "Select Preferred Pronouns"
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .form
                                                                            .preferred_pronouns,
                                                                        callback: function(
                                                                          $$v
                                                                        ) {
                                                                          _vm.$set(
                                                                            _vm.form,
                                                                            "preferred_pronouns",
                                                                            $$v
                                                                          )
                                                                        },
                                                                        expression:
                                                                          "form.preferred_pronouns"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      958229801
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12", md: "12" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Alias",
                                                    "label-for": "h-Alias"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "b-input-group",
                                                    {
                                                      staticClass:
                                                        "input-group-merge"
                                                    },
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          id: "icons-Alias",
                                                          placeholder:
                                                            "Enter Alias"
                                                        },
                                                        model: {
                                                          value: _vm.form.alias,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "alias",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.alias"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _vm.form.locum_job_role
                                            ? _c(
                                                "b-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    md: "12"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: "Locum Job Role",
                                                        "label-for":
                                                          "locum_job_role"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: { readonly: "" },
                                                        model: {
                                                          value:
                                                            _vm.form
                                                              .locum_job_role,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.form,
                                                              "locum_job_role",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "form.locum_job_role"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12" } },
                                            [
                                              _c("b-row", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "mt-2",
                                                    staticStyle: {
                                                      "font-family": "Mulish",
                                                      "font-style": "normal",
                                                      "font-weight": "bold",
                                                      "font-size": "14px",
                                                      "line-height": "18px",
                                                      color: "#171822"
                                                    }
                                                  },
                                                  [_vm._v(" Bank Details ")]
                                                )
                                              ])
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            { attrs: { cols: "12", md: "12" } },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "mt-1" },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: "Bank Name",
                                                        "label-for":
                                                          "v-bank-name"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "validation-provider",
                                                        {
                                                          attrs: {
                                                            name: "Bank Name",
                                                            rules:
                                                              _vm.bank_details
                                                                .sort_code ||
                                                              _vm.bank_details
                                                                .account_number
                                                                ? "required"
                                                                : ""
                                                          },
                                                          scopedSlots: _vm._u(
                                                            [
                                                              {
                                                                key: "default",
                                                                fn: function(
                                                                  ref
                                                                ) {
                                                                  var errors =
                                                                    ref.errors
                                                                  return [
                                                                    _c(
                                                                      "b-form-input",
                                                                      {
                                                                        attrs: {
                                                                          id:
                                                                            "v-bank-name",
                                                                          placeholder:
                                                                            "e.g.Bank of England"
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            _vm
                                                                              .bank_details
                                                                              .name,
                                                                          callback: function(
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.bank_details,
                                                                              "name",
                                                                              $$v
                                                                            )
                                                                          },
                                                                          expression:
                                                                            "bank_details.name"
                                                                        }
                                                                      }
                                                                    ),
                                                                    _c(
                                                                      "small",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger"
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            errors[0]
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                }
                                                              }
                                                            ],
                                                            null,
                                                            false,
                                                            4182307870
                                                          )
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c("b-col", { attrs: { md: "12" } }, [
                                            _c(
                                              "div",
                                              {},
                                              [
                                                _c(
                                                  "b-form-group",
                                                  {
                                                    attrs: {
                                                      label: "Account Number",
                                                      "label-for":
                                                        "account-number"
                                                    }
                                                  },
                                                  [
                                                    _c("validation-provider", {
                                                      attrs: {
                                                        name: "Account Number",
                                                        rules:
                                                          _vm.bank_details
                                                            .name ||
                                                          _vm.bank_details
                                                            .sort_code
                                                            ? "required|numeric|min_bank_account|max_bank_account"
                                                            : "numeric|min_bank_account|max_bank_account"
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "default",
                                                            fn: function(ref) {
                                                              var errors =
                                                                ref.errors
                                                              return [
                                                                _c(
                                                                  "b-form-input",
                                                                  {
                                                                    attrs: {
                                                                      id:
                                                                        "account-number",
                                                                      placeholder:
                                                                        "xxxxxxxx"
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm
                                                                          .bank_details
                                                                          .account_number,
                                                                      callback: function(
                                                                        $$v
                                                                      ) {
                                                                        _vm.$set(
                                                                          _vm.bank_details,
                                                                          "account_number",
                                                                          $$v
                                                                        )
                                                                      },
                                                                      expression:
                                                                        "bank_details.account_number"
                                                                    }
                                                                  }
                                                                ),
                                                                _c(
                                                                  "small",
                                                                  {
                                                                    staticClass:
                                                                      "text-danger"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        errors[0]
                                                                      )
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            }
                                                          }
                                                        ],
                                                        null,
                                                        false,
                                                        2575129658
                                                      )
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ]),
                                          _c(
                                            "b-col",
                                            { attrs: { md: "12" } },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Sort Code",
                                                    "label-for": "sort-code"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "Sort Code",
                                                      rules:
                                                        _vm.bank_details.name ||
                                                        _vm.bank_details
                                                          .account_number
                                                          ? "required|numeric|max_sort_code|min_sort_code"
                                                          : "numeric|max_sort_code|min_sort_code"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "sort-code",
                                                                    placeholder:
                                                                      "xx-xx-xx"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .bank_details
                                                                        .sort_code,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.bank_details,
                                                                        "sort_code",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "bank_details.sort_code"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1260961626
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-col",
                                            {
                                              staticClass: "mt-2",
                                              attrs: { md: "12" }
                                            },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "ripple",
                                                      rawName: "v-ripple.400",
                                                      value:
                                                        "rgba(255, 255, 255, 0.15)",
                                                      expression:
                                                        "'rgba(255, 255, 255, 0.15)'",
                                                      modifiers: { "400": true }
                                                    }
                                                  ],
                                                  staticClass: "mr-1",
                                                  attrs: {
                                                    type: "submit",
                                                    variant: "primary"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.updateUserInfo()
                                                    }
                                                  }
                                                },
                                                [_vm._v(" Save ")]
                                              ),
                                              _c(
                                                "b-button",
                                                {
                                                  directives: [
                                                    {
                                                      name: "ripple",
                                                      rawName: "v-ripple.400",
                                                      value:
                                                        "rgba(186, 191, 199, 0.15)",
                                                      expression:
                                                        "'rgba(186, 191, 199, 0.15)'",
                                                      modifiers: { "400": true }
                                                    }
                                                  ],
                                                  staticClass:
                                                    "text-primary ml-1 shadow",
                                                  attrs: {
                                                    type: "reset",
                                                    variant: "outline-white"
                                                  },
                                                  on: {
                                                    click: function($event) {
                                                      _vm.openForm = false
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("span", [
                                                    _vm._v(" Cancel ")
                                                  ])
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.openForm === false
            ? _c(
                "b-col",
                { attrs: { lg: "4", md: "6" } },
                [
                  _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        { staticClass: "border-bottom pb-1" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row" },
                            [
                              _c(
                                "b-avatar",
                                {
                                  attrs: {
                                    rounded: "",
                                    variant: "light-primary"
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "text-dark font-weight-700",
                                    attrs: { icon: "UserIcon", size: "18" }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: { "padding-top": "8px" }
                                },
                                [
                                  _c(
                                    "h4",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "bold",
                                        "font-size": "20px",
                                        "line-height": "25px",
                                        color: "#171822"
                                      }
                                    },
                                    [_vm._v(" Personal Details ")]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "ml-auto" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass:
                                    "btn-icon bg-white shadow-sm font-weight-bold",
                                  attrs: { variant: "white" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openEditForm(
                                        _vm.isEditProfileSidebarActive
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        value: "Edit",
                                        expression: "'Edit'",
                                        modifiers: { hover: true, top: true }
                                      }
                                    ],
                                    staticClass: "text-primary",
                                    attrs: { icon: "EditIcon", size: "20" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "b-card-body",
                        [
                          _c(
                            "b-row",
                            { staticClass: "match-height mt-2" },
                            [
                              _c("b-col", { attrs: { lg: "6", md: "6" } }, [
                                _c("span", { staticClass: "header" }, [
                                  _vm._v("First Name")
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _c(
                                  "h5",
                                  {
                                    staticClass: "details",
                                    staticStyle: { "margin-top": "10px" }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.user.first_name) + " "
                                    )
                                  ]
                                )
                              ]),
                              _c("b-col", { attrs: { lg: "6", md: "6" } }, [
                                _c("span", { staticClass: "ml-5 header" }, [
                                  _vm._v("Second Name")
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _c(
                                  "h5",
                                  {
                                    staticClass: "details ml-5",
                                    staticStyle: { "margin-top": "10px" }
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.user.last_name) + " "
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "b-col",
                                {
                                  staticClass: "mt-2",
                                  attrs: { lg: "6", md: "6" }
                                },
                                [
                                  _c("span", { staticClass: "header" }, [
                                    _vm._v("DOB")
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "h5",
                                    {
                                      staticClass: "details",
                                      staticStyle: { "margin-top": "10px" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.user.dob
                                              ? _vm.ChangeFormateDate(
                                                  _vm.user.dob
                                                )
                                              : "-"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "mt-2",
                                  attrs: { lg: "6", md: "6" }
                                },
                                [
                                  _c("span", { staticClass: "ml-5 header" }, [
                                    _vm._v("NI number")
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "h5",
                                    {
                                      staticClass: "details ml-5",
                                      staticStyle: { "margin-top": "10px" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.user.ni_number
                                              ? _vm.user.ni_number
                                              : "-"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "mt-2",
                                  attrs: { lg: "6", md: "6" }
                                },
                                [
                                  _c("span", { staticClass: "header" }, [
                                    _vm._v("Gender")
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "h5",
                                    {
                                      staticClass: "details",
                                      staticStyle: { "margin-top": "10px" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.user.gender
                                              ? _vm.user.gender
                                              : "-"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "mt-2",
                                  attrs: { lg: "6", md: "6" }
                                },
                                [
                                  _c("span", { staticClass: "ml-5 header" }, [
                                    _vm._v("Preferred Pronouns")
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "h5",
                                    {
                                      staticClass: "details ml-5",
                                      staticStyle: { "margin-top": "10px" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.user.preferred_pronouns
                                              ? _vm.user.preferred_pronouns
                                              : "-"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "mt-2",
                                  attrs: { lg: "6", md: "6" }
                                },
                                [
                                  _c("span", { staticClass: "header" }, [
                                    _vm._v("Alias ")
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "h5",
                                    {
                                      staticClass: "details",
                                      staticStyle: { "margin-top": "10px" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.user.alias
                                              ? _vm.user.alias
                                              : "-"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "mt-2",
                              staticStyle: {
                                "font-family": "Mulish",
                                "font-style": "normal",
                                "font-weight": "bold",
                                "font-size": "14px",
                                "line-height": "18px",
                                color: "#171822"
                              }
                            },
                            [_vm._v(" Bank Details")]
                          ),
                          _c(
                            "b-row",
                            { staticClass: "match-height mt-2" },
                            [
                              _c("b-col", { attrs: { lg: "6", md: "6" } }, [
                                _c("span", { staticClass: "header" }, [
                                  _vm._v("Bank Name")
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _c(
                                  "h5",
                                  {
                                    staticClass: "details",
                                    staticStyle: { "margin-top": "10px" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.user.bank_details
                                            ? _vm.user.bank_details.name
                                            : "-"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]),
                              _c("b-col", { attrs: { lg: "6", md: "6" } }, [
                                _c("span", { staticClass: "ml-5 header" }, [
                                  _vm._v("Account Number")
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _c(
                                  "h5",
                                  {
                                    staticClass: "details ml-5",
                                    staticStyle: { "margin-top": "10px" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.user.bank_details
                                            ? _vm.user.bank_details
                                                .account_number
                                            : "-"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "b-col",
                                {
                                  staticClass: "mt-2",
                                  attrs: { lg: "6", md: "6" }
                                },
                                [
                                  _c("span", { staticClass: "header" }, [
                                    _vm._v("Sort Code")
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "h5",
                                    {
                                      staticClass: "details",
                                      staticStyle: { "margin-top": "10px" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.user.bank_details
                                              ? _vm.user.bank_details.sort_code
                                              : "-"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.openEditContactForm === true
            ? _c(
                "b-col",
                { attrs: { lg: "4", md: "6" } },
                [
                  _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        { staticClass: "border-bottom pb-1" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row" },
                            [
                              _c(
                                "b-avatar",
                                {
                                  attrs: {
                                    rounded: "",
                                    variant: "light-primary"
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "16",
                                        height: "16",
                                        viewBox: "0 0 16 16",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M2.66634 2.66602H13.333C14.0663 2.66602 14.6663 3.26602 14.6663 3.99935V11.9993C14.6663 12.7327 14.0663 13.3327 13.333 13.3327H2.66634C1.93301 13.3327 1.33301 12.7327 1.33301 11.9993V3.99935C1.33301 3.26602 1.93301 2.66602 2.66634 2.66602Z",
                                          stroke: "#171822",
                                          "stroke-width": "1.5",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M14.6663 4L7.99967 8.66667L1.33301 4",
                                          stroke: "#171822",
                                          "stroke-width": "1.5",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: { "padding-top": "8px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "bold",
                                        "font-size": "20px",
                                        "line-height": "25px",
                                        color: "#171822"
                                      }
                                    },
                                    [_vm._v(" Contact Details ")]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ml-auto" }, [
                            _c("span", { staticStyle: { color: "#EFA12C" } }, [
                              _vm._v("•")
                            ]),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "5px" } },
                              [_vm._v("Editing")]
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-card-body",
                        [
                          _c(
                            "validation-observer",
                            { ref: "EditPersonalInfoForm" },
                            [
                              _c(
                                "b-form",
                                {
                                  on: {
                                    submit: function($event) {
                                      $event.preventDefault()
                                    }
                                  }
                                },
                                [
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              attrs: {
                                                label: "Mobile",
                                                "label-for": "h-telephone"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "Telephone",
                                                  rules: "tel|min_tel|max_tel"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c(
                                                            "b-input-group",
                                                            {
                                                              staticClass:
                                                                "input-group-merge"
                                                            },
                                                            [
                                                              _c(
                                                                "b-input-group-prepend",
                                                                {
                                                                  attrs: {
                                                                    "is-text":
                                                                      ""
                                                                  }
                                                                },
                                                                [
                                                                  _c(
                                                                    "feather-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon:
                                                                          "PhoneIcon"
                                                                      }
                                                                    }
                                                                  )
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "icons-phone",
                                                                    placeholder:
                                                                      "(+44)"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm.form
                                                                        .phone_number,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.form,
                                                                        "phone_number",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "form.phone_number"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  false,
                                                  3347857612
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "mt-2",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("b-row", [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "font-family": "Mulish",
                                                  "font-style": "normal",
                                                  "font-weight": "bold",
                                                  "font-size": "14px",
                                                  "line-height": "18px",
                                                  color: "#171822"
                                                }
                                              },
                                              [_vm._v(" Home Address ")]
                                            ),
                                            _c("div", {
                                              staticClass: "optional-class"
                                            })
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "mt-1" },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Address 1",
                                                    "label-for": "home-address"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "Address",
                                                      rules: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "home-address"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .home_address
                                                                        .address,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.home_address,
                                                                        "address",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "home_address.address"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      2497576802
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _c(
                                            "div",
                                            {},
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label: "Address 2",
                                                    "label-for": "home-address"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "Address",
                                                      rules: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "home-address1"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .home_address
                                                                        .address_2,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.home_address,
                                                                        "address_2",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "home_address.address_2"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      4215154206
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c("b-col", { attrs: { md: "12" } }, [
                                        _c(
                                          "div",
                                          {},
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: "City / Town",
                                                  "label-for": "city"
                                                }
                                              },
                                              [
                                                _c("validation-provider", {
                                                  attrs: {
                                                    name: "City",
                                                    rules: "required",
                                                    rules: _vm.home_address
                                                      .address
                                                      ? "required"
                                                      : ""
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var errors =
                                                            ref.errors
                                                          return [
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                id: "city"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .home_address
                                                                    .city,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.home_address,
                                                                    "city",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "home_address.city"
                                                              }
                                                            }),
                                                            _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "text-danger"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0]
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    1412427584
                                                  )
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]),
                                      _c("b-col", { attrs: { md: "12" } }, [
                                        _c(
                                          "div",
                                          {},
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  label: "Post Code",
                                                  "label-for": "postal-code"
                                                }
                                              },
                                              [
                                                _c("validation-provider", {
                                                  attrs: {
                                                    name: "Post Code",
                                                    rules: _vm.home_address
                                                      .address
                                                      ? "required"
                                                      : ""
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "default",
                                                        fn: function(ref) {
                                                          var errors =
                                                            ref.errors
                                                          return [
                                                            _c("b-form-input", {
                                                              attrs: {
                                                                id:
                                                                  "postal-code",
                                                                placeholder:
                                                                  "e.g.PR8 8UW"
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .home_address
                                                                    .postal_code,
                                                                callback: function(
                                                                  $$v
                                                                ) {
                                                                  _vm.$set(
                                                                    _vm.home_address,
                                                                    "postal_code",
                                                                    $$v
                                                                  )
                                                                },
                                                                expression:
                                                                  "home_address.postal_code"
                                                              }
                                                            }),
                                                            _c(
                                                              "small",
                                                              {
                                                                staticClass:
                                                                  "text-danger"
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    errors[0]
                                                                  )
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        }
                                                      }
                                                    ],
                                                    null,
                                                    false,
                                                    774625509
                                                  )
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ]),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "mt-2",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _c("b-row", [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "font-family": "Mulish",
                                                  "font-style": "normal",
                                                  "font-weight": "bold",
                                                  "font-size": "14px",
                                                  "line-height": "18px",
                                                  color: "#171822"
                                                }
                                              },
                                              [_vm._v(" Emergency Contact ")]
                                            )
                                          ])
                                        ],
                                        1
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "mt-1" },
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Emergency contact name",
                                                    "label-for": "v-bank-name"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "Bank Name",
                                                      rules: ""
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "v-bank-name1",
                                                                    placeholder:
                                                                      "Enter Name "
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .emergency_contact
                                                                        .contact_name,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.emergency_contact,
                                                                        "contact_name",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "emergency_contact.contact_name"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      2351983444
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { cols: "12", md: "12" } },
                                        [
                                          _c(
                                            "div",
                                            {},
                                            [
                                              _c(
                                                "b-form-group",
                                                {
                                                  attrs: {
                                                    label:
                                                      "Emergency contact number",
                                                    "label-for":
                                                      "account-number"
                                                  }
                                                },
                                                [
                                                  _c("validation-provider", {
                                                    attrs: {
                                                      name: "Telephone",
                                                      rules:
                                                        "tel|min_tel|max_tel"
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "default",
                                                          fn: function(ref) {
                                                            var errors =
                                                              ref.errors
                                                            return [
                                                              _c(
                                                                "b-form-input",
                                                                {
                                                                  attrs: {
                                                                    id:
                                                                      "account-number1",
                                                                    placeholder:
                                                                      "Enter contact number"
                                                                  },
                                                                  model: {
                                                                    value:
                                                                      _vm
                                                                        .emergency_contact
                                                                        .contact_number,
                                                                    callback: function(
                                                                      $$v
                                                                    ) {
                                                                      _vm.$set(
                                                                        _vm.emergency_contact,
                                                                        "contact_number",
                                                                        $$v
                                                                      )
                                                                    },
                                                                    expression:
                                                                      "emergency_contact.contact_number"
                                                                  }
                                                                }
                                                              ),
                                                              _c(
                                                                "small",
                                                                {
                                                                  staticClass:
                                                                    "text-danger"
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      errors[0]
                                                                    )
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          }
                                                        }
                                                      ],
                                                      null,
                                                      false,
                                                      1691179788
                                                    )
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "mt-2",
                                          attrs: { md: "12" }
                                        },
                                        [
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "ripple",
                                                  rawName: "v-ripple.400",
                                                  value:
                                                    "rgba(255, 255, 255, 0.15)",
                                                  expression:
                                                    "'rgba(255, 255, 255, 0.15)'",
                                                  modifiers: { "400": true }
                                                }
                                              ],
                                              staticClass: "mr-1",
                                              attrs: {
                                                type: "submit",
                                                variant: "primary"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.profileUpdate()
                                                }
                                              }
                                            },
                                            [_vm._v(" Save ")]
                                          ),
                                          _c(
                                            "b-button",
                                            {
                                              directives: [
                                                {
                                                  name: "ripple",
                                                  rawName: "v-ripple.400",
                                                  value:
                                                    "rgba(186, 191, 199, 0.15)",
                                                  expression:
                                                    "'rgba(186, 191, 199, 0.15)'",
                                                  modifiers: { "400": true }
                                                }
                                              ],
                                              staticClass:
                                                "text-primary ml-1 shadow",
                                              attrs: {
                                                type: "button",
                                                variant: "outline-white"
                                              },
                                              on: {
                                                click: function($event) {
                                                  _vm.openEditContactForm = false
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "text-primary" },
                                                [_vm._v(" Cancel ")]
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.openEditContactForm === false
            ? _c(
                "b-col",
                { attrs: { lg: "4", md: "6" } },
                [
                  _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        { staticClass: "border-bottom pb-1" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row" },
                            [
                              _c(
                                "b-avatar",
                                {
                                  attrs: {
                                    rounded: "",
                                    variant: "light-primary"
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "16",
                                        height: "16",
                                        viewBox: "0 0 16 16",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M2.66634 2.66602H13.333C14.0663 2.66602 14.6663 3.26602 14.6663 3.99935V11.9993C14.6663 12.7327 14.0663 13.3327 13.333 13.3327H2.66634C1.93301 13.3327 1.33301 12.7327 1.33301 11.9993V3.99935C1.33301 3.26602 1.93301 2.66602 2.66634 2.66602Z",
                                          stroke: "#171822",
                                          "stroke-width": "1.5",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M14.6663 4L7.99967 8.66667L1.33301 4",
                                          stroke: "#171822",
                                          "stroke-width": "1.5",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: { "padding-top": "8px" }
                                },
                                [
                                  _c(
                                    "h4",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "bold",
                                        "font-size": "20px",
                                        "line-height": "25px",
                                        color: "#171822"
                                      }
                                    },
                                    [_vm._v(" Contact Details ")]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "ml-auto" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass:
                                    "btn-icon bg-white shadow-sm font-weight-bold",
                                  attrs: { variant: "white" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openContactDetailsEditForm(
                                        _vm.isEditContactInfoFormActive
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        value: "Edit",
                                        expression: "'Edit'",
                                        modifiers: { hover: true, top: true }
                                      }
                                    ],
                                    staticClass: "text-primary cursor-pointer",
                                    attrs: { icon: "EditIcon", size: "20" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c("b-card-body", [
                        _c("div", { staticClass: "mt-2" }, [
                          _c(
                            "span",
                            { staticClass: "mr-1 details" },
                            [
                              _c("feather-icon", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.top",
                                    value: "Contact Number",
                                    expression: "'Contact Number'",
                                    modifiers: { hover: true, top: true }
                                  }
                                ],
                                staticClass: "mr-1",
                                attrs: { icon: "PhoneIcon" }
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.user.phone_number
                                      ? _vm.user.phone_number
                                      : "-"
                                  ) +
                                  " "
                              )
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "mt-2" }, [
                          _c(
                            "span",
                            { staticClass: "mr-1 details" },
                            [
                              _c("feather-icon", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.top",
                                    value: "Contact Number",
                                    expression: "'Contact Number'",
                                    modifiers: { hover: true, top: true }
                                  }
                                ],
                                staticClass: "mr-1",
                                attrs: { icon: "AtSignIcon" }
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.user.email ? _vm.user.email : "-"
                                  ) +
                                  " "
                              )
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "mt-2" }, [
                          _c(
                            "span",
                            { staticClass: "mr-1 details" },
                            [
                              _c("feather-icon", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.top",
                                    value: "Address",
                                    expression: "'Address'",
                                    modifiers: { hover: true, top: true }
                                  }
                                ],
                                staticClass: "mr-1",
                                attrs: { icon: "MapPinIcon" }
                              }),
                              _vm.user.home_addresses
                                ? _c("span", [
                                    _c(
                                      "span",
                                      { staticStyle: { "margin-left": "1px" } },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.user.home_addresses.address
                                              ? _vm.user.home_addresses
                                                  .address + ", "
                                              : ""
                                          ) + " "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("br"),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-left": "28px" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.user.home_addresses.address_2
                                              ? _vm.user.home_addresses
                                                  .address_2 + ", "
                                              : ""
                                          ) + " "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("br"),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-left": "28px" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.user.home_addresses.city
                                              ? _vm.user.home_addresses.city +
                                                  ", "
                                              : ""
                                          ) + " "
                                        )
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("br"),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "margin-left": "28px" }
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.user.home_addresses.postal_code
                                              ? _vm.user.home_addresses
                                                  .postal_code
                                              : ""
                                          ) + " "
                                        )
                                      ]
                                    )
                                  ])
                                : _c("span", [_vm._v(" - ")])
                            ],
                            1
                          )
                        ]),
                        _c("div", { staticClass: "mt-2" }, [
                          _c(
                            "span",
                            { staticClass: "mr-1" },
                            [
                              _c("feather-icon", {
                                directives: [
                                  {
                                    name: "b-tooltip",
                                    rawName: "v-b-tooltip.hover.top",
                                    value: "Emergency Contact Number",
                                    expression: "'Emergency Contact Number'",
                                    modifiers: { hover: true, top: true }
                                  }
                                ],
                                attrs: { icon: "AlertCircleIcon" }
                              }),
                              _c(
                                "span",
                                {
                                  staticClass: "details",
                                  staticStyle: { "margin-left": "10px" }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.user.emergency_contacts
                                          ? _vm.user.emergency_contacts
                                              .contact_name +
                                              " - " +
                                              _vm.user.emergency_contacts
                                                .contact_number
                                          : "-"
                                      ) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.openProfessionalInfoForm === true
            ? _c(
                "b-col",
                { attrs: { lg: "4", md: "6" } },
                [
                  _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        { staticClass: "border-bottom pb-1" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row" },
                            [
                              _c(
                                "b-avatar",
                                {
                                  attrs: {
                                    rounded: "",
                                    variant: "light-primary"
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "16",
                                        height: "16",
                                        viewBox: "0 0 16 16",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M13.333 4.66602H2.66634C1.92996 4.66602 1.33301 5.26297 1.33301 5.99935V12.666C1.33301 13.4024 1.92996 13.9993 2.66634 13.9993H13.333C14.0694 13.9993 14.6663 13.4024 14.6663 12.666V5.99935C14.6663 5.26297 14.0694 4.66602 13.333 4.66602Z",
                                          stroke: "#171822",
                                          "stroke-width": "1.5",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M10.6663 14V3.33333C10.6663 2.97971 10.5259 2.64057 10.2758 2.39052C10.0258 2.14048 9.68663 2 9.33301 2H6.66634C6.31272 2 5.97358 2.14048 5.72353 2.39052C5.47348 2.64057 5.33301 2.97971 5.33301 3.33333V14",
                                          stroke: "#171822",
                                          "stroke-width": "1.5",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: { "padding-top": "8px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "bold",
                                        "font-size": "20px",
                                        "line-height": "25px",
                                        color: "#171822"
                                      }
                                    },
                                    [_vm._v(" Professional Details ")]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ml-auto" }, [
                            _c("span", { staticStyle: { color: "#EFA12C" } }, [
                              _vm._v("•")
                            ]),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "5px" } },
                              [_vm._v("Editing")]
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-card-body",
                        [
                          _c("validation-observer", {
                            ref: "professionalInfoForm",
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var handleSubmit = ref.handleSubmit
                                    return [
                                      _c(
                                        "b-form",
                                        { staticClass: "auth-login-form mt-2" },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "mr-2 ml-2" },
                                            [
                                              _c(
                                                "b-row",
                                                {},
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "12"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-group", {
                                                        attrs: {
                                                          label: "Job Role",
                                                          "label-for":
                                                            "h-job-role"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mb-1",
                                                      attrs: {
                                                        cols: "12",
                                                        md: "12"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-group",
                                                        [
                                                          _c(
                                                            "validation-provider",
                                                            {
                                                              attrs: {
                                                                name:
                                                                  "Job Role",
                                                                rules:
                                                                  "required"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var errors =
                                                                        ref.errors
                                                                      return [
                                                                        _c(
                                                                          "v-select",
                                                                          {
                                                                            attrs: {
                                                                              options:
                                                                                _vm
                                                                                  .dropdowns
                                                                                  .job_roles,
                                                                              placeholder:
                                                                                "Select Job Role"
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm.job_role,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.job_role = $$v
                                                                              },
                                                                              expression:
                                                                                "job_role"
                                                                            }
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "small",
                                                                          {
                                                                            staticClass:
                                                                              "text-danger"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                errors[0]
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "12"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-group", {
                                                        attrs: {
                                                          label:
                                                            "Qualification",
                                                          "label-for":
                                                            "h-qualification-name"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mb-1",
                                                      attrs: {
                                                        cols: "12",
                                                        md: "12"
                                                      }
                                                    },
                                                    [
                                                      _c("v-select", {
                                                        attrs: {
                                                          options:
                                                            _vm.dropdowns
                                                              .qualifications,
                                                          placeholder:
                                                            "Select Qualification"
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.professionalInfo
                                                              .qualification_id,
                                                          callback: function(
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.professionalInfo,
                                                              "qualification_id",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "professionalInfo.qualification_id"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "12"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-group", {
                                                        attrs: {
                                                          label: "Speciality",
                                                          "label-for":
                                                            "h-speciality-name"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mb-1",
                                                      attrs: {
                                                        cols: "12",
                                                        md: "12"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-group",
                                                        [
                                                          _c("v-select", {
                                                            attrs: {
                                                              options:
                                                                _vm.dropdowns
                                                                  .specialities,
                                                              placeholder:
                                                                "Select Speciality"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .professionalInfo
                                                                  .speciality_id,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.professionalInfo,
                                                                  "speciality_id",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "professionalInfo.speciality_id"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "12"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-group", {
                                                        attrs: {
                                                          label: "IT System",
                                                          "label-for":
                                                            "IT system"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "12"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-group",
                                                        { staticClass: "mb-1" },
                                                        [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              options:
                                                                _vm.dropdowns
                                                                  .it_systems,
                                                              multiple: "",
                                                              placeholder:
                                                                "Select IT System"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.professionalInfoItSystems,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.professionalInfoItSystems = $$v
                                                              },
                                                              expression:
                                                                "professionalInfoItSystems"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "12"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-group", {
                                                        attrs: {
                                                          label:
                                                            "Smart Card Number",
                                                          "label-for":
                                                            "SmartCardNumber"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "12"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-group",
                                                        [
                                                          _c(
                                                            "validation-provider",
                                                            {
                                                              attrs: {
                                                                name:
                                                                  "Smart card number",
                                                                rules:
                                                                  "max_input|numeric",
                                                                vid:
                                                                  "SmartCardNumber"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var errors =
                                                                        ref.errors
                                                                      return [
                                                                        _c(
                                                                          "b-form-input",
                                                                          {
                                                                            staticClass:
                                                                              "mb-1",
                                                                            attrs: {
                                                                              id:
                                                                                "SmartCardNumber",
                                                                              placeholder:
                                                                                "Enter smart card number"
                                                                            },
                                                                            model: {
                                                                              value:
                                                                                _vm
                                                                                  .professionalInfo
                                                                                  .smart_card_number,
                                                                              callback: function(
                                                                                $$v
                                                                              ) {
                                                                                _vm.$set(
                                                                                  _vm.professionalInfo,
                                                                                  "smart_card_number",
                                                                                  $$v
                                                                                )
                                                                              },
                                                                              expression:
                                                                                "professionalInfo.smart_card_number"
                                                                            }
                                                                          }
                                                                        ),
                                                                        _c(
                                                                          "small",
                                                                          {
                                                                            staticClass:
                                                                              "text-danger"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                errors[0]
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                true
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "12"
                                                      }
                                                    },
                                                    [
                                                      _c("b-form-group", {
                                                        attrs: {
                                                          label: "Skills",
                                                          "label-for":
                                                            "h-skills"
                                                        }
                                                      })
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mb-3",
                                                      attrs: {
                                                        cols: "12",
                                                        md: "12"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-group",
                                                        [
                                                          _c("v-select", {
                                                            attrs: {
                                                              dir: _vm.$store
                                                                .state.appConfig
                                                                .isRTL
                                                                ? "rtl"
                                                                : "ltr",
                                                              label: "title",
                                                              multiple: "",
                                                              placeholder:
                                                                "Add skills",
                                                              "push-tags": "",
                                                              taggable: ""
                                                            },
                                                            model: {
                                                              value:
                                                                _vm
                                                                  .professionalInfo
                                                                  .skills,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.$set(
                                                                  _vm.professionalInfo,
                                                                  "skills",
                                                                  $$v
                                                                )
                                                              },
                                                              expression:
                                                                "professionalInfo.skills"
                                                            }
                                                          })
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    { attrs: { md: "12" } },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "ripple",
                                                              rawName:
                                                                "v-ripple.400",
                                                              value:
                                                                "rgba(255, 255, 255, 0.15)",
                                                              expression:
                                                                "'rgba(255, 255, 255, 0.15)'",
                                                              modifiers: {
                                                                "400": true
                                                              }
                                                            }
                                                          ],
                                                          staticClass: "mr-1",
                                                          attrs: {
                                                            type: "submit",
                                                            variant: "primary"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              return _vm.submitForm(
                                                                $event
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [_vm._v(" Save ")]
                                                      ),
                                                      _c(
                                                        "b-button",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "ripple",
                                                              rawName:
                                                                "v-ripple.400",
                                                              value:
                                                                "rgba(186, 191, 199, 0.15)",
                                                              expression:
                                                                "'rgba(186, 191, 199, 0.15)'",
                                                              modifiers: {
                                                                "400": true
                                                              }
                                                            }
                                                          ],
                                                          staticClass:
                                                            "text-primary ml-1 shadow",
                                                          attrs: {
                                                            type: "button",
                                                            variant:
                                                              "outline-white"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.openProfessionalInfoForm = false
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-primary"
                                                            },
                                                            [_vm._v(" Cancel ")]
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ]
                                  }
                                }
                              ],
                              null,
                              false,
                              2477129989
                            )
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.openProfessionalInfoForm === false
            ? _c(
                "b-col",
                { attrs: { lg: "4", md: "6" } },
                [
                  _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        { staticClass: "border-bottom pb-1" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row" },
                            [
                              _c(
                                "b-avatar",
                                {
                                  attrs: {
                                    rounded: "",
                                    variant: "light-primary"
                                  }
                                },
                                [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "16",
                                        height: "16",
                                        viewBox: "0 0 16 16",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M13.333 4.66602H2.66634C1.92996 4.66602 1.33301 5.26297 1.33301 5.99935V12.666C1.33301 13.4024 1.92996 13.9993 2.66634 13.9993H13.333C14.0694 13.9993 14.6663 13.4024 14.6663 12.666V5.99935C14.6663 5.26297 14.0694 4.66602 13.333 4.66602Z",
                                          stroke: "#171822",
                                          "stroke-width": "1.5",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        }
                                      }),
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M10.6663 14V3.33333C10.6663 2.97971 10.5259 2.64057 10.2758 2.39052C10.0258 2.14048 9.68663 2 9.33301 2H6.66634C6.31272 2 5.97358 2.14048 5.72353 2.39052C5.47348 2.64057 5.33301 2.97971 5.33301 3.33333V14",
                                          stroke: "#171822",
                                          "stroke-width": "1.5",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: { "padding-top": "8px" }
                                },
                                [
                                  _c(
                                    "h4",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "bold",
                                        "font-size": "20px",
                                        "line-height": "25px",
                                        color: "#171822"
                                      }
                                    },
                                    [_vm._v(" Professional Details ")]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "ml-auto" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass:
                                    "btn-icon bg-white shadow-sm font-weight-bold",
                                  attrs: { variant: "white" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openEditProfessionalInfoForm(
                                        _vm.isEditProfessionalInfoFormActive
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        value: "Edit",
                                        expression: "'Edit'",
                                        modifiers: { hover: true, top: true }
                                      }
                                    ],
                                    staticClass: "text-primary cursor-pointer",
                                    attrs: { icon: "EditIcon", size: "20" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "b-card-body",
                        [
                          _c(
                            "b-row",
                            { staticClass: "match-height mt-2" },
                            [
                              _c("b-col", { attrs: { lg: "6", md: "6" } }, [
                                _c("span", { staticClass: "header" }, [
                                  _vm._v("Job Role")
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _c(
                                  "span",
                                  {
                                    staticClass: "details",
                                    staticStyle: { "margin-top": "10px" }
                                  },
                                  [_vm._v(_vm._s(this.job_role))]
                                )
                              ]),
                              _c("b-col", { attrs: { lg: "6", md: "6" } }, [
                                _c("span", { staticClass: "ml-5 header" }, [
                                  _vm._v("Qualification")
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _c(
                                  "h5",
                                  {
                                    staticClass: "details ml-5",
                                    staticStyle: { "margin-top": "10px" }
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.user.qalifications &&
                                            _vm.user.qalifications.qalification
                                            ? _vm.user.qalifications
                                                .qalification.name
                                            : "-"
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]),
                              _c(
                                "b-col",
                                {
                                  staticClass: "mt-2",
                                  attrs: { lg: "6", md: "6" }
                                },
                                [
                                  _c("span", { staticClass: "header" }, [
                                    _vm._v("Speciality")
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "h5",
                                    {
                                      staticClass: "details",
                                      staticStyle: { "margin-top": "10px" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.user.qalifications &&
                                              _vm.user.qalifications.speciality
                                              ? _vm.user.qalifications
                                                  .speciality.name
                                              : "-"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "mt-2",
                                  attrs: { lg: "6", md: "6" }
                                },
                                [
                                  _c("span", { staticClass: "ml-5 header" }, [
                                    _vm._v("IT System")
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "b-row",
                                    { staticClass: "pl-0 " },
                                    [
                                      _c(
                                        "b-col",
                                        {
                                          staticClass: "mt-1 font-weight-light",
                                          attrs: { cols: "12" }
                                        },
                                        [
                                          _vm._l(
                                            _vm.professionalInfoItSystems,
                                            function(professionalInfoItSystem) {
                                              return _c(
                                                "b-badge",
                                                {
                                                  staticClass: "mr-1 ml-4",
                                                  staticStyle: {
                                                    "margin-top": "5px"
                                                  },
                                                  attrs: {
                                                    variant: "light-info"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "text-dark details"
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          professionalInfoItSystem.label
                                                        )
                                                      )
                                                    ]
                                                  )
                                                ]
                                              )
                                            }
                                          ),
                                          Object.keys(
                                            _vm.professionalInfoItSystems
                                          ).length === 0
                                            ? _c(
                                                "h5",
                                                { staticClass: "mb-0 ml-5" },
                                                [_vm._v("- ")]
                                              )
                                            : _vm._e()
                                        ],
                                        2
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass: "mt-2",
                                  attrs: { lg: "6", md: "6" }
                                },
                                [
                                  _c("span", { staticClass: "header" }, [
                                    _vm._v("Smart Card Number")
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "h5",
                                    {
                                      staticClass: "details",
                                      staticStyle: { "margin-top": "10px" }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.user.qalifications &&
                                              _vm.user.qalifications
                                                .smart_card_number
                                              ? _vm.user.qalifications
                                                  .smart_card_number
                                              : "-"
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mt-2", attrs: { lg: "12" } },
                                [
                                  _c("span", { staticClass: "header" }, [
                                    _vm._v("Skills")
                                  ]),
                                  _vm._v(" "),
                                  _c("br"),
                                  _c(
                                    "div",
                                    [
                                      _vm.user.qalifications &&
                                      _vm.user.qalifications.skills
                                        ? _c(
                                            "b-row",
                                            { staticClass: "pl-0 " },
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  staticClass:
                                                    "mt-1 font-weight-light",
                                                  attrs: { cols: "12" }
                                                },
                                                [
                                                  _vm._l(
                                                    _vm.user.qalifications
                                                      .skills,
                                                    function(skill) {
                                                      return _c(
                                                        "b-badge",
                                                        {
                                                          staticClass: "mr-1",
                                                          attrs: {
                                                            variant:
                                                              "light-info"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-dark details"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(skill)
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  Object.keys(
                                                    _vm.user.qalifications
                                                      .skills
                                                  ).length === 0
                                                    ? _c(
                                                        "h5",
                                                        {
                                                          staticClass: "mb-0 "
                                                        },
                                                        [_vm._v("- ")]
                                                      )
                                                    : _vm._e()
                                                ],
                                                2
                                              )
                                            ],
                                            1
                                          )
                                        : _c("b-row", [
                                            _c(
                                              "span",
                                              { staticClass: "ml-2" },
                                              [_vm._v("-")]
                                            )
                                          ])
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "b-col",
            { attrs: { lg: "4", md: "6" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": "" } },
                [
                  _c("b-card-header", { staticClass: "border-bottom pb-1" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row" },
                      [
                        _c(
                          "b-avatar",
                          { attrs: { rounded: "", variant: "light-primary" } },
                          [
                            _c(
                              "svg",
                              {
                                attrs: {
                                  width: "16",
                                  height: "16",
                                  viewBox: "0 0 16 16",
                                  fill: "none",
                                  xmlns: "http://www.w3.org/2000/svg"
                                }
                              },
                              [
                                _c("path", {
                                  attrs: {
                                    d: "M8 13.334H14",
                                    stroke: "#171822",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }
                                }),
                                _c("path", {
                                  attrs: {
                                    d:
                                      "M11 2.33414C11.2652 2.06892 11.6249 1.91992 12 1.91992C12.1857 1.91992 12.3696 1.9565 12.5412 2.02757C12.7128 2.09864 12.8687 2.20281 13 2.33414C13.1313 2.46546 13.2355 2.62136 13.3066 2.79294C13.3776 2.96452 13.4142 3.14842 13.4142 3.33414C13.4142 3.51985 13.3776 3.70375 13.3066 3.87533C13.2355 4.04691 13.1313 4.20281 13 4.33414L4.66667 12.6675L2 13.3341L2.66667 10.6675L11 2.33414Z",
                                    stroke: "#171822",
                                    "stroke-width": "1.5",
                                    "stroke-linecap": "round",
                                    "stroke-linejoin": "round"
                                  }
                                })
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ml-1",
                            staticStyle: { "padding-top": "8px" }
                          },
                          [
                            _c(
                              "h4",
                              {
                                staticStyle: {
                                  "font-family": "Mulish",
                                  "font-style": "normal",
                                  "font-weight": "bold",
                                  "font-size": "20px",
                                  "line-height": "25px",
                                  color: "#171822"
                                }
                              },
                              [_vm._v(" Notes ")]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "ml-auto" },
                      [
                        _c("feather-icon", {
                          directives: [
                            {
                              name: "b-tooltip",
                              rawName: "v-b-tooltip.hover.top",
                              value: "Add Notes",
                              expression: "'Add Notes'",
                              modifiers: { hover: true, top: true }
                            }
                          ],
                          staticClass: "text-primary cursor-pointer",
                          attrs: { icon: "PlusIcon", size: "20" },
                          on: {
                            click: function($event) {
                              return _vm.toggleSidebar()
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]),
                  _c(
                    "b-card-body",
                    { staticClass: " pl-1 px-0 mx-0" },
                    [
                      _vm.noUserNotes !== 0
                        ? _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-overlay",
                                    {
                                      attrs: {
                                        show: _vm.tableLoading,
                                        rounded: "sm"
                                      }
                                    },
                                    [
                                      _c("b-table", {
                                        ref: "table",
                                        staticClass:
                                          "mobile_table_css table_shifts",
                                        staticStyle: { "max-height": "400px" },
                                        attrs: {
                                          "current-page": _vm.currentPage,
                                          "per-page": _vm.pagination.perPage,
                                          fields: _vm.fields,
                                          filter: _vm.filter,
                                          items: _vm.getUserNotes,
                                          "filter-included-fields":
                                            _vm.filterOn,
                                          "sort-by": _vm.sortBy,
                                          "sort-desc": _vm.sortDesc,
                                          "sort-direction": _vm.sortDirection,
                                          hover: "",
                                          responsive: ""
                                        },
                                        on: {
                                          "update:sortBy": function($event) {
                                            _vm.sortBy = $event
                                          },
                                          "update:sort-by": function($event) {
                                            _vm.sortBy = $event
                                          },
                                          "update:sortDesc": function($event) {
                                            _vm.sortDesc = $event
                                          },
                                          "update:sort-desc": function($event) {
                                            _vm.sortDesc = $event
                                          }
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "cell(note)",
                                              fn: function(data) {
                                                return [
                                                  _c(
                                                    "b-row",
                                                    [
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: { cols: "1" }
                                                        },
                                                        [
                                                          _c(
                                                            "svg",
                                                            {
                                                              attrs: {
                                                                width: "2",
                                                                height: "38",
                                                                viewBox:
                                                                  "0 0 2 38",
                                                                fill: "none",
                                                                xmlns:
                                                                  "http://www.w3.org/2000/svg"
                                                              }
                                                            },
                                                            [
                                                              _c("rect", {
                                                                attrs: {
                                                                  width: "2",
                                                                  height: "38",
                                                                  fill:
                                                                    "#00C49A"
                                                                }
                                                              })
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        {
                                                          attrs: { cols: "10" }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "ml-0",
                                                              staticStyle: {
                                                                "font-family":
                                                                  "Mulish",
                                                                "font-style":
                                                                  "normal",
                                                                "font-weight":
                                                                  "normal",
                                                                "font-size":
                                                                  "12px",
                                                                "line-height":
                                                                  "15px",
                                                                color:
                                                                  "rgba(23, 24, 34, 0.6)"
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                " Added on " +
                                                                  _vm._s(
                                                                    data.item
                                                                      .date
                                                                  ) +
                                                                  " "
                                                              ),
                                                              _c("br"),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticStyle: {
                                                                    "font-family":
                                                                      "Mulish",
                                                                    "font-style":
                                                                      "normal",
                                                                    "font-weight":
                                                                      "normal",
                                                                    "font-size":
                                                                      "14px",
                                                                    "line-height":
                                                                      "18px",
                                                                    color:
                                                                      "#171822",
                                                                    "word-break":
                                                                      "break-all"
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        data
                                                                          .item
                                                                          .note
                                                                      ) +
                                                                      " "
                                                                  )
                                                                ]
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      ),
                                                      _c(
                                                        "b-col",
                                                        {
                                                          staticClass:
                                                            "ml-auto",
                                                          attrs: { cols: "1" }
                                                        },
                                                        [
                                                          _c(
                                                            "b-button",
                                                            {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "b-tooltip",
                                                                  rawName:
                                                                    "v-b-tooltip.hover.left",
                                                                  value:
                                                                    "Delete",
                                                                  expression:
                                                                    "'Delete'",
                                                                  modifiers: {
                                                                    hover: true,
                                                                    left: true
                                                                  }
                                                                }
                                                              ],
                                                              staticClass:
                                                                "btn-icon bg-white mr-0",
                                                              attrs: {
                                                                size: "23",
                                                                variant:
                                                                  "outline-white"
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  return _vm.deleteNote(
                                                                    data.item.id
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "feather-icon",
                                                                {
                                                                  attrs: {
                                                                    icon:
                                                                      "TrashIcon",
                                                                    size: "18",
                                                                    variant:
                                                                      "outline-danger"
                                                                  }
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ]
                                              }
                                            }
                                          ],
                                          null,
                                          false,
                                          1075703903
                                        )
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _c(
                            "div",
                            {
                              staticClass:
                                "d-flex flex-column align-items-center justify-content-center h-10"
                            },
                            [
                              _c("div", { staticClass: "empty-state py-1" }, [
                                _c("img", {
                                  staticClass: "img-fluid",
                                  attrs: {
                                    src: require("@/assets/images/pages/doc-icon.png"),
                                    alt: "",
                                    width: "100"
                                  }
                                }),
                                _c("p", { staticClass: "txt-grey mt-1" }, [
                                  _vm._v("No Notes")
                                ])
                              ])
                            ]
                          )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("AddNoteSidebar", {
        ref: "AddNoteSidebar",
        attrs: { "is-add-note-sidebar-active": _vm.isAddNoteSidebarActive },
        on: {
          toggleSidebar: function($event) {
            return _vm.toggleSidebar()
          },
          dataRefresh: function($event) {
            return _vm.dataRefresh()
          }
        }
      }),
      _c("EditProfileSidebar", {
        ref: "AddLocationSideBar",
        attrs: {
          "is-edit-profile-sidebar-active": _vm.isEditProfileSidebarActive,
          User:
            _vm.user.job_roles || _vm.user.job_roles[0]
              ? _vm.user.job_roles[0]
              : ""
        },
        on: {
          close: _vm.toggleSideBars,
          "update:isEditProfileSidebarActive": function($event) {
            _vm.isEditProfileSidebarActive = $event
          },
          "update:is-edit-profile-sidebar-active": function($event) {
            _vm.isEditProfileSidebarActive = $event
          },
          toggleSideBars: function($event) {
            return _vm.toggleSideBars()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }