<template>
  <div>
    <b-modal
      id="modal-center"
      v-model="showFileUploadModal"
      centered
      hide-footer
      no-close-on-backdrop
      size="lg"
      title="Upload Certificate"
      @change="goBack"
    >
      <b-overlay :show="modalLoading" rounded="sm">
        <b-row class="mx-1">
          <b-col class="mt-2" cols="12">
            <div class="m-auto" style="width: 100px; height: 100px">
              <svg
                id="Layer_1"
                style="enable-background: new 0 0 512 512"
                version="1.1"
                viewBox="0 0 512 512"
                x="0px"
                xml:space="preserve"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                y="0px"
              >
                <rect height="512" style="fill: #32bea6" width="512" y="0" />
                <g>
                  <polygon
                    points="182.76,229.728 232.704,178.808 232.704,301.688 279.296,301.688 279.296,178.808
		329.24,229.728 362.488,197.288 256.008,88.72 149.512,197.288 	"
                    style="fill: #ffffff"
                  />
                  <polygon
                    points="395.528,421.864 116.472,421.864 116.472,301.088 132.472,301.088 132.472,405.864
		379.528,405.864 379.528,301.088 395.528,301.088 	"
                    style="fill: #ffffff"
                  />
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </div>
          </b-col>
          <b-col class="mt-3" cols="12">
            <b-overlay :show="buttonLoading" rounded="sm">
              <b-form-file
                ref="image_ref"
                v-model="uploadedFile"
                drop-placeholder="Drop file here..."
                placeholder="Choose a file or drop it here..."
              />
            </b-overlay>
          </b-col>
          <b-col class="mt-5" cols="12">
            <div class="float-right">
              <b-button
                :disabled="isActive"
                class="mr-2"
                variant="primary"
                @click="uploadFile(uploadType)"
              >
                Upload
              </b-button>

              <b-button variant="warning" @click="goBack">Cancel</b-button>
              <!--            <b-button @click="downloadFile()">Download</b-button>-->
              <!--              <b-button @click="getList()">List</b-button>-->
            </div>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BLink,
  BRow,
  BFormCheckbox,
  BCardBody,
  BCardHeader,
  BImg,
  BMedia,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BModal,
  BFormFile,
  BOverlay,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import staffBankAPI from "@/apis/modules/staffBank";
import { baseApiUrl, apiUrl } from "@/constants/config";
import fileUploader from "@/views/my-documents/fileUploader";
import profile from "@/apis/modules/profile";
import staffBank from "@/apis/modules/staffBank";
import MomentMixin from "@/mixins/MomentMixin";
import ErrorMessages from "@/mixins/ErrorMessages";
import SuccessMessage from "@/mixins/SuccessMessage";
import axios from "axios";
import Ripple from "vue-ripple-directive";
import Filter from "@/mixins/FilterMixin";
import settings from "@/apis/modules/settings";

export default {
  name: "index",
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
    BCardBody,
    BCardHeader,
    BFormCheckbox,
    BImg,
    BMedia,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    vSelect,
    BModal,
    BFormFile,
    BOverlay,

    VBTooltip,
  },
  mixins: [MomentMixin, Filter],
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      fileTypeOption: [],
      selected_file_type_id: "",
      isActive: true,
      buttonLoading: false,
      tableLoading: false,
      noDataTable: "",
      uploadedFile: null,
      showFileUploadModal: false,
      modalLoading: false,
      currentPage: 1,
      filters: {},
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      items: [],
      certificate: [],
      uploadType: "new_upload",
    };
  },

  watch: {
    uploadedFile() {
      this.isActive = false;
    },
  },

  methods: {
    open() {
      this.showFileUploadModal = true;
    },
    replaceDocument(item) {
      this.certificate = item;
      this.uploadType = "update";
      this.showFileUploadModal = true;
    },
    async getFileType() {
      try {
        this.tableLoading = true;

        const Response = await settings.getFileType();
        this.fileTypeOption = Response.data.data.map((x) => ({
          id: x.id,
          name: x.name,
        }));
      } catch (error) {
        this.convertAndNotifyError(error);
        this.tableLoading = false;
      }
    },
    async downloadFile(item) {
      try {
        this.tableLoading = true;
        // const response =

        const response = await staffBank.downloadImage(item.id);
        axios
          .get(
            `${apiUrl}/salaried-staff/files/${
              item.id
            }/download?auth_practice_id=${sessionStorage.getItem(
              "practice_id"
            )}`,
            {
              // headers{
              // }
              responseType: "arraybuffer",
              withCredentials: true,
            }
          )
          .then((response) => {
            let blob = new Blob([response.data], { type: item.mime_type }),
              // url = window.URL.createObjectURL(blob)
              // let blob = new Blob([response.data], { type: 'application/pdf' })
              link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = item.name;
            link.click();
            // setTimeout(() => window.URL.revokeObjectURL(link), 100)

            // window.open(url)

            // Mostly the same, I was just experimenting with different approaches, tried link.click, iframe and other solutions
            // let link = document.createElement('a')
            // link.href = window.URL.createObjectURL(blob)
            // link.download = item.name
            // link.click()
          });
        //
        // console.log(response)
        // let blob = new Blob([response.data], { type: 'application/pdf' })
        // let link = document.createElement('a')
        // link.href = window.URL.createObjectURL(blob)
        // link.download = item.name
        // link.click()
        // setTimeout(() => window.URL.revokeObjectURL(link), 100)

        // var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        // var fileLink = document.createElement('a');
        //
        // fileLink.href = fileURL;
        // // fileLink.href = `http://https://hyre.locumboxapp.com/api/mp/salaried-staff/files/${item.id}/download?auth_practice_id=${sessionStorage.getItem('practice_id')}`;
        // fileLink.target ='blank'
        // fileLink.setAttribute('download', response.headers.content_type);
        // document.body.appendChild(fileLink);
        //
        // fileLink.click();
        //
        //

        // var a = document.createElement("a");
        // a.href = `http://https://hyre.locumboxapp.com/api/mp/salaried-staff/files/${id}/download?auth_practice_id=${sessionStorage.getItem('practice_id')}`;
        // a.target = 'blank'
        // a.setAttribute("download", 'test');
        // a.click();
        this.showSuccessMessage("File Downloaded Successfully");
        this.$refs.table.refresh();
      } catch (error) {
        this.convertAndNotifyError(error);
        this.tableLoading = false;
      }
    },
    async deleteFile(id) {
      try {
        this.tableLoading = true;
        this.$swal({
          title: "Are you sure you want to delete this document?",
          text: "",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, delete",
          cancelButtonText: "No, go back",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            await staffBank.deleteToFile(id);
            this.$refs.table.refresh();
            this.showSuccessMessage("Your document has been deleted");
          } else if (result.dismiss === "cancel") {
            this.formLoading = false;
            this.showSuccessMessage("Cancelled");
            // this.$swal({
            //   title: 'Cancelled',
            //   text: ' ',

            //   icon: 'error',
            //   customClass: {
            //     confirmButton: 'btn btn-success'
            //   }
            // })
          }
        });

        this.tableLoading = false;
      } catch (error) {
        this.convertAndNotifyError(error);
        this.tableLoading = false;
      }
    },

    async uploadFile(type) {
      try {
        if (type === "update") {
          await staffBankAPI.deleteCertificate(this.certificate.id);
        }
        this.buttonLoading = true;
        fileUploader
          .store(this.uploadedFile, {
            progress: (progress) => {
              this.uploadProgress = Math.round(progress * 100);
            },
            visibility: "public-read",
            // baseURL: 'http://hyre-be-v2.test/api/mp',
            baseURL: `${baseApiUrl}`,

            params: {
              auth_practice_id: `${sessionStorage.getItem("practice_id")}`,
            },
          })
          .then(async (response) => {
            const key = response.key;
            await this.setImageKeyToApi(key);
            // await this.resetData()
            // this.isActive = true
          });
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },

    async setImageKeyToApi(key) {
      try {
        let respond = await staffBank.getCertificate({
          key: key,
          name: this.uploadedFile.name,
        });
        this.certificate = respond.data.data;
        this.showSuccessMessage("Document Uploaded Successfully");
        this.buttonLoading = false;
        this.goBack();
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },

    openFileUploadModel() {
      try {
        this.showFileUploadModal = true;
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },

    goBack() {
      this.showFileUploadModal = false;
      this.$emit("getDocument", this.certificate);
    },
  },
  mounted() {
    this.getFileType();
  },
};
</script>

<style scoped></style>

<styl lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</styl>
