var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            id: "modal-center",
            centered: "",
            "hide-footer": "",
            "no-close-on-backdrop": "",
            size: "lg",
            title: "Upload Job Specification"
          },
          on: {
            change: function($event) {
              _vm.showFileUploadModal = false
            }
          },
          model: {
            value: _vm.showFileUploadModal,
            callback: function($$v) {
              _vm.showFileUploadModal = $$v
            },
            expression: "showFileUploadModal"
          }
        },
        [
          _c(
            "b-overlay",
            { attrs: { show: _vm.modalLoading, rounded: "sm" } },
            [
              _c(
                "b-row",
                { staticClass: "mx-1" },
                [
                  _c("b-col", { staticClass: "mt-2", attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "m-auto",
                        staticStyle: { width: "100px", height: "100px" }
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticStyle: {
                              "enable-background": "new 0 0 512 512"
                            },
                            attrs: {
                              id: "Layer_1",
                              version: "1.1",
                              viewBox: "0 0 512 512",
                              x: "0px",
                              "xml:space": "preserve",
                              xmlns: "http://www.w3.org/2000/svg",
                              "xmlns:xlink": "http://www.w3.org/1999/xlink",
                              y: "0px"
                            }
                          },
                          [
                            _c("rect", {
                              staticStyle: { fill: "#32bea6" },
                              attrs: { height: "512", width: "512", y: "0" }
                            }),
                            _c("g", [
                              _c("polygon", {
                                staticStyle: { fill: "#ffffff" },
                                attrs: {
                                  points:
                                    "182.76,229.728 232.704,178.808 232.704,301.688 279.296,301.688 279.296,178.808\n\t\t329.24,229.728 362.488,197.288 256.008,88.72 149.512,197.288 \t"
                                }
                              }),
                              _c("polygon", {
                                staticStyle: { fill: "#ffffff" },
                                attrs: {
                                  points:
                                    "395.528,421.864 116.472,421.864 116.472,301.088 132.472,301.088 132.472,405.864\n\t\t379.528,405.864 379.528,301.088 395.528,301.088 \t"
                                }
                              })
                            ]),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g"),
                            _c("g")
                          ]
                        )
                      ]
                    )
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "mt-3", attrs: { cols: "12" } },
                    [
                      _c(
                        "b-overlay",
                        { attrs: { show: _vm.buttonLoading, rounded: "sm" } },
                        [
                          _c("b-form-file", {
                            ref: "image_ref",
                            attrs: {
                              "drop-placeholder": "Drop file here...",
                              placeholder: "Choose a file or drop it here..."
                            },
                            model: {
                              value: _vm.uploadedFile,
                              callback: function($$v) {
                                _vm.uploadedFile = $$v
                              },
                              expression: "uploadedFile"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c("b-col", { staticClass: "mt-5", attrs: { cols: "12" } }, [
                    _c(
                      "div",
                      { staticClass: "float-right" },
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              disabled: _vm.isActive,
                              variant: "primary"
                            },
                            on: {
                              click: function($event) {
                                return _vm.uploadFile(_vm.uploadType)
                              }
                            }
                          },
                          [_vm._v(" Upload ")]
                        ),
                        _c(
                          "b-button",
                          {
                            attrs: { variant: "warning" },
                            on: {
                              click: function($event) {
                                _vm.showFileUploadModal = false
                              }
                            }
                          },
                          [_vm._v("Cancel")]
                        )
                      ],
                      1
                    )
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }