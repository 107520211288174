var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-card",
        { attrs: { "no-body": "" } },
        [
          _c("b-card-header", { staticClass: "border-bottom pb-1" }, [
            _c(
              "div",
              { staticClass: "d-flex flex-row" },
              [
                _c(
                  "b-avatar",
                  { attrs: { rounded: "", variant: "light-primary" } },
                  [
                    _c("feather-icon", {
                      staticClass: "text-dark font-weight-bolder",
                      attrs: { icon: "KeyIcon", size: "18" }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    staticClass: "ml-1",
                    staticStyle: { "padding-top": "8px" }
                  },
                  [
                    _c("h4", { staticClass: " font-weight-700" }, [
                      _vm._v(" Reset Password ")
                    ])
                  ]
                )
              ],
              1
            )
          ]),
          _c(
            "b-card-body",
            [
              _c(
                "b-row",
                { staticClass: "mx-1" },
                [
                  _c(
                    "b-col",
                    { staticClass: "mt-2", attrs: { cols: "12", md: "12" } },
                    [
                      _c("b-form-group", [
                        _vm._v(
                          " Your new password must be different from previously used "
                        ),
                        _c("br"),
                        _vm._v(" passwords. ")
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "validation-observer",
                        { ref: "resetPasswordForm" },
                        [
                          _c(
                            "b-form",
                            {
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                }
                              }
                            },
                            [
                              _c(
                                "b-form-group",
                                {},
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "basic-password1" } },
                                    [_vm._v("Current Password")]
                                  ),
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Old-Password",
                                      rules: "required"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "b-input-group",
                                              {
                                                staticClass: "input-group-merge"
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "basic-password1",
                                                    type:
                                                      _vm.password1FieldType,
                                                    placeholder:
                                                      "Enter Current Password"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form.old_password,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "old_password",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.old_password"
                                                  }
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  { attrs: { "is-text": "" } },
                                                  [
                                                    _c("feather-icon", {
                                                      staticClass:
                                                        "cursor-pointer",
                                                      attrs: {
                                                        icon:
                                                          _vm.password1ToggleIcon
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.togglePassword1Visibility
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "basic-password2" } },
                                    [_vm._v("New Password")]
                                  ),
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Password",
                                      rules: "required"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "b-input-group",
                                              {
                                                staticClass: "input-group-merge"
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "basic-password2",
                                                    type:
                                                      _vm.password2FieldType,
                                                    placeholder:
                                                      "Enter New Password"
                                                  },
                                                  model: {
                                                    value: _vm.form.password,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "password",
                                                        $$v
                                                      )
                                                    },
                                                    expression: "form.password"
                                                  }
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  { attrs: { "is-text": "" } },
                                                  [
                                                    _c("feather-icon", {
                                                      staticClass:
                                                        "cursor-pointer",
                                                      attrs: {
                                                        icon:
                                                          _vm.password2ToggleIcon
                                                      },
                                                      on: {
                                                        click:
                                                          _vm.togglePassword2Visibility
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-form-group",
                                [
                                  _c(
                                    "label",
                                    { attrs: { for: "basic-password3" } },
                                    [_vm._v("Confirm Password")]
                                  ),
                                  _c("validation-provider", {
                                    attrs: {
                                      name: "Confirm-Password",
                                      rules: "required"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "default",
                                        fn: function(ref) {
                                          var errors = ref.errors
                                          return [
                                            _c(
                                              "b-input-group",
                                              {
                                                staticClass: "input-group-merge"
                                              },
                                              [
                                                _c("b-form-input", {
                                                  attrs: {
                                                    id: "basic-password3",
                                                    type:
                                                      _vm.password3FieldType,
                                                    placeholder:
                                                      "Enter New Password"
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.form
                                                        .password_confirmation,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.form,
                                                        "password_confirmation",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "form.password_confirmation\n"
                                                  }
                                                }),
                                                _c(
                                                  "b-input-group-append",
                                                  { attrs: { "is-text": "" } },
                                                  [
                                                    _c("feather-icon", {
                                                      staticClass:
                                                        "cursor-pointer",
                                                      attrs: {
                                                        icon:
                                                          _vm.password3ToggleIcon
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {}
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "small",
                                              { staticClass: "text-danger" },
                                              [_vm._v(_vm._s(errors[0]))]
                                            )
                                          ]
                                        }
                                      }
                                    ])
                                  })
                                ],
                                1
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(255, 255, 255, 0.15)",
                                      expression: "'rgba(255, 255, 255, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  staticClass: "mr-1",
                                  attrs: { type: "submit", variant: "primary" },
                                  on: { click: function($event) {} }
                                },
                                [_vm._v(" Save ")]
                              ),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "ripple",
                                      rawName: "v-ripple.400",
                                      value: "rgba(186, 191, 199, 0.15)",
                                      expression: "'rgba(186, 191, 199, 0.15)'",
                                      modifiers: { "400": true }
                                    }
                                  ],
                                  attrs: {
                                    type: "reset",
                                    variant: "outline-secondary"
                                  }
                                },
                                [
                                  _c(
                                    "span",
                                    { staticClass: "text-secondary" },
                                    [_vm._v(" Reset ")]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }