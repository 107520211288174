<template>
   <div>
     <b-card>
       <b-row class="my-n2  ml-0 mr-0">
         <!-- Left side Icons -->

         <b-col cols="10" class="pb-2 pt-2">
           <div class="row">
             <!-- 01 Allocated leave days -->

             <div class="d-flex align-items-center pr-bx mt-n1">
               <b-avatar variant="light-primary" rounded>
                 <feather-icon style="color:#4361EE" icon="CalendarIcon" size="18" />
               </b-avatar>

               <div class="ml-1">
                 <b-row v-if="editLeaveDaysShow">
                   <b-col>
                     <h4 class="mb-0 font-weight-bolder ml-n0" style="margin-top: 10px; width:100px"  v-if="trackLeaveOnDays">
                   {{ (allocatedLeaveDays).toFixed(1) }} days
                 </h4>
                 <h4 class="mb-0 font-weight-bolder ml-n0" style="margin-top: 10px;width:100px"  v-else>
                   {{
                     parseFloat(allocatedLeaveHours).toFixed(1)
                   }} hrs
                 </h4>
                 <div> <small class="ml-0">Allocated leave</small></div>
                   </b-col>
                 
                   <b-col class="">
                     <b-button
                         style="margin-top: 3px"
                         size="20"
                         variant="outline-white"
                         class="btn-icon bg-white text-primary"
                         @click="editLeaveDays()"
                     >
                       <feather-icon icon="EditIcon" size="15" />
                     </b-button>
                   </b-col>
                 </b-row>
                 <b-row v-else>
                   <b-col sm="10" class="mb-0 mr-n4">
                     <b-input-group class="input-group-merge">
                       <b-form-input
                           v-model="editNoOfLeaveDays"
                           size="sm"
                           placeholder=""
                           type="number"

                       />
                       <b-input-group-append is-text>
                         <feather-icon
                             style="cursor: pointer;"
                             class="text-primary"
                             @click="editLeaveDays()"
                             icon="CheckIcon"
                             size="15"
                         />
                       </b-input-group-append>
                     </b-input-group>
                     <div> <small class="ml-0">Allocated leave</small></div>
                   </b-col>
                 </b-row>
               </div>
             </div>

             <!-- 02 Used -->

             <div class="d-flex align-items-center pr-bx">
               <div class="ml-1">
                 <h4 class="mb-0 font-weight-bolder" v-if="trackLeaveOnDays">
                   {{ parseFloat(usedLeaveDays).toFixed(1) }} days
                 </h4>
                 <h4 class="mb-0 font-weight-bolder" v-else>
                   {{
                    usedLeaveHours >= 0 ?parseFloat(usedLeaveHours).toFixed(1): '0.0'
                   }} hrs
                 </h4>
                 <small>Used</small>
               </div>
             </div>

             <!-- 03 Remaining -->

             <div class="d-flex align-items-center pr-bx">
               <div class="ml-1">
                 <ul class="list-inline mb-0">
                   <li class="list-inline-item">
                     <h4 class="mb-0 font-weight-bolder" v-if="trackLeaveOnDays">
                       {{ parseFloat(allocatedLeaveDays - usedLeaveDays).toFixed(1) }}
                       days
                     </h4>
                     <h4 class="mb-0 font-weight-bolder" v-else>
                       {{
                         parseFloat(remainingLeaveHours).toFixed(1)
                       }} hrs
                     </h4>
                   </li>
                 </ul>
                 <small>Remaining</small>
               </div>
             </div>

             <!-- 04 Leave requests -->

             <div class="d-flex align-items-center ml-1">
               <b-avatar variant="light-warning" rounded>
                 <feather-icon icon="LoaderIcon" size="18" />
               </b-avatar>

               <div class="ml-1">
                 <h4 class="mb-0 font-weight-bolder">
                   {{ leaveRequestCount }}
                 </h4>
                 <small>Leave requests</small>
               </div>
             </div>
           </div>
         </b-col>

         <!-- Right side Downloading button -->

         <b-col cols="2" class="pt-0 pb-2 pt-xl-2 pb-xl-0 ml-n1 ">
           <b-row>
             <div
                 class="d-flex justify-content-end  mt-2 mt-md-0 pl-0 pl-md-0 ml-auto"
             >
               <b-button
                   class="float-right"
                   size="md"
                   variant="primary"
                   @click="toggleAddLeaveSideBar()"
               >+ Add Leave
               </b-button>
             </div>
           </b-row>
         </b-col>
       </b-row>
     </b-card>


     <b-card no-body>
       <b-card-body class=" pl-1 ">
         <b-row>
           <b-col>
             <div class="ml-1" style="padding-top:8px">
               <h4 class=" font-weight-700 mt-1">
                 Leave Requests
                 <span v-if="totalLeaveRequests>0" class="translate-middle badge rounded-circle bg-danger"
                       style="font-size: 0.8em;">{{totalLeaveRequests}}
            </span>
                 <span v-else>({{totalLeaveRequests}})</span>
               </h4>

             </div>
           </b-col>
           <b-col cols="12">
             <b-overlay
                 :show="tableLoading"
                 rounded="sm"
             >
               <b-table
                   ref="table_leave_requests"
                   :current-page="currentPage"
                   :per-page="pagination.perPage"
                   :fields="fields"
                   :items="leaveRequests"
                   :sort-by.sync="sortBy"
                   :sort-desc.sync="sortDesc"
                   :sort-direction="sortDirection"
                   hover
                   class="mobile_table_css"
                   responsive
               >
                 <template #cell(date)="data">
                   <div style="width: 150px">
                     <b-row class="center text-primary">
                       <p class="ml-1 font-weight-bolder pt-1 text-dark" >{{data.item.date}}</p>
                     </b-row>
                   </div>
                 </template>
                 <template #cell(time)="data">
                   <div style="width: 80px">
                     <b-row class="center text-primary">
                       <p class="ml-1 font-weight-bolder pt-1 text-dark" >{{data.item.time}}</p>
                     </b-row>
                   </div>
                 </template>
                 <template #cell(duration)="data">
                   <div v-if="data.item.is_day">
                     <span v-if="data.item.duration > 1">{{data.item.duration}} days</span>
                     <span v-else >{{data.item.duration}} day</span>
                   </div>
                   <div v-else>
                     <span v-if="data.item.duration > 1" >{{data.item.duration}} hours</span>
                     <span v-else >{{data.item.duration}} hour</span>

                   </div>


                 </template>
                 <template #cell(action)="data">
                   <div style="width: 180px">
                     <b-button
                         size="23"
                         variant="outline-white"
                         class="btn-icon shadow-sm bg-white text-primary mr-1 "
                         v-b-tooltip.hover.top="'Edit'"
                         @click="toggleSideBars(data.item)"
                     >
                       <feather-icon icon="EditIcon" size="18" />
                     </b-button>

                     <b-button
                         size="23"
                         variant="outline-white"
                         class="btn-icon shadow-sm bg-white text-primary mr-1"
                         v-b-tooltip.hover.top="'Accept'"
                         @click="approveLeave(data.item.id)"
                     >
                       <feather-icon icon="CheckSquareIcon" size="18" />
                     </b-button>

                     <b-button
                         size="23"
                         variant="outline-white"
                         class="btn-icon shadow-sm bg-white text-danger"
                         v-b-tooltip.hover.top="'Reject'"
                         @click="rejectLeave(data.item.id)"
                     >
                       <feather-icon icon="XSquareIcon" size="18" variant="outline-danger"/>
                     </b-button>
                   </div>
                 </template>

               </b-table>

             </b-overlay>
           </b-col>
           <b-col class="d-flex align-items-center justify-content-center justify-content-sm-start" cols="12" sm="6">
             <span class="text-muted" v-if="pagination1.totalRows !==0">Showing {{pagination1.from}} to {{pagination1.to}} of {{pagination1.totalRows}} entries</span>
             <!--              <span class="text-muted">Showing 2 out of 2 items</span>-->
           </b-col>
           <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end" >

             <b-pagination
                 v-model="currentPage"
                 :per-page="pagination1.perPage"
                 :total-rows="pagination1.totalRows"
                 class="mb-0 mt-1 mt-sm-0 "
                 first-number
                 last-number
                 next-class="next-item"
                 prev-class="prev-item">

               <template #prev-text>
                 <feather-icon icon="ChevronLeftIcon" size="18" />
               </template>

               <template #next-text>
                 <feather-icon icon="ChevronRightIcon" size="18" />
               </template>

             </b-pagination>

           </b-col>
         </b-row>
       </b-card-body>
     </b-card>





     <b-card class="mb-0" no-body>
       <div>
         <b-row >
           <h4 class="ml-3 mb-1 mt-1 font-weight-700 ">
             Scheduled Leaves
<!--             <span class="translate-middle badge rounded-circle bg-danger"-->
<!--                   style="font-size: 0.8em;">{{pagination2.totalRows}}-->
<!--            </span>-->

             <span>({{pagination2.totalRows}})</span>
           </h4>

         </b-row>

       </div>
       <div>
         <b-overlay
             :show="tableLoading"
             rounded="sm"
         >
           <b-row>
             <b-col class="ml-2 mr-2" >
               <b-table
                   ref="table_sheduled_leaves"
                   :current-page="currentPage1"
                   :per-page="pagination.perPage"
                   :fields="fields"
                   :items="scheduledLeaves"
                   :sort-by.sync="sortBy"
                   :sort-desc.sync="sortDesc"
                   :sort-direction="sortDirection"
                   hover
                   class="mobile_table_css"
                   responsive
               >
                 <template #cell(date)="data">
                   <div style="width: 150px">
                     <b-row class="center text-primary">
                       <p class="ml-1 font-weight-bolder pt-1 text-dark" >{{data.item.date}}</p>
                     </b-row>
                   </div>
                 </template>
                 <template #cell(time)="data">
                   <div style="width: 80px">
                     <b-row class="center text-primary">
                       <p class="ml-1 font-weight-bolder pt-1 text-dark" >{{data.item.time}}</p>
                     </b-row>
                   </div>
                 </template>
                 <template #cell(action)="data">
                   <div style="width: 200px">
                     <b-button
                         size="23"
                         variant="outline-white"
                         class="btn-icon shadow-sm bg-white text-primary mr-1"
                         v-b-tooltip.hover.top="'Edit'"
                         @click="toggleSideBars(data.item)"
                     >
                       <feather-icon icon="EditIcon" size="18" />
                     </b-button>

                     <b-button
                         size="23"
                         variant="outline-white"
                         class="btn-icon shadow-sm bg-white text-danger"
                         v-b-tooltip.hover.top="'Cancel'"
                         @click="cancelLeave(data.item.id)"
                     >
                       Cancel
                     </b-button>

                   </div>

                 </template>

               </b-table>
             </b-col>
           </b-row>
         </b-overlay>

         <!--                pagination-->
         <div class="mx-1 mb-2">
           <b-row>
             <b-col cols="12" v-if="noDataTable === 0" class="text-center">
               <span >No data for preview</span>
             </b-col>
             <b-col
                 class="d-flex align-items-center justify-content-center justify-content-sm-start"
                 cols="12"
                 sm="6"
             >
               <span class="text-muted" v-if="pagination2.totalRows !==0">Showing {{pagination2.from}} to {{pagination2.to}} of {{pagination2.totalRows}} entries</span>
               <!-- <span class="text-muted" v-if="pagination.totalRows !==0">Showing 1 to 4 of 4 entries</span> -->
             </b-col>
             <!-- Pagination -->
             <b-col
                 class="d-flex align-items-center justify-content-center justify-content-sm-end"
                 cols="12"
                 sm="6"
             >

               <b-pagination
                   v-model="currentPage1"
                   :per-page="pagination2.perPage"
                   :total-rows="pagination2.totalRows"
                   class="mb-0 mt-1 mt-sm-0 "
                   first-number
                   last-number
                   next-class="next-item"
                   prev-class="prev-item"
               >
                 <template #prev-text>
                   <feather-icon
                       icon="ChevronLeftIcon"
                       size="18"
                   />
                 </template>
                 <template #next-text>
                   <feather-icon
                       icon="ChevronRightIcon"
                       size="18"
                   />
                 </template>
               </b-pagination>

             </b-col>

           </b-row>
         </div>
       </div>
     </b-card>
     <b-card class="mb-2 mt-2" no-body>
       <div>
         <b-row >
           <h4 class="ml-3 mb-1 mt-1  font-weight-700 mt-1">
             Bank Holidays

             <span>({{pagination4.totalRows}})</span>
           </h4>

         </b-row>

       </div>
       <div>
         <b-overlay
             :show="tableLoading"
             rounded="sm"
         >
           <b-row>
             <b-col class="ml-2 mr-2" >
               <b-table
                   ref="table_bank_leaves"
                   :current-page="currentPage2"
                   :per-page="pagination4.perPage"
                   :fields="fields4"
                   :items="bankHolidayLeaves"
                   :sort-by.sync="sortBy"
                   :sort-desc.sync="sortDesc"
                   :sort-direction="sortDirection"
                   hover
                   class="mobile_table_css"
                   responsive
               >
                 <template #cell(date)="data">
                   <div style="width: 150px">
                     <b-row class="center text-primary">
                       <p class="ml-1 font-weight-bolder pt-1 text-dark" >{{data.item.date}}</p>
                     </b-row>
                   </div>
                 </template>
                 <template #cell(time)="data">
                   <div style="width: 80px">
                     <b-row class="center text-primary">
                       <p class="ml-1 font-weight-bolder pt-1 text-dark" >{{data.item.time}}</p>
                     </b-row>
                   </div>
                 </template>
                 <template #cell(action)="data">
                   <div style="width: 100px">
                     <b-button
                         size="23"
                         variant="outline-white"
                         class="btn-icon shadow-sm bg-white text-danger"
                         v-b-tooltip.hover.top="'Cancel'"
                         @click="cancelLeave(data.item.id)"
                     >
                       Cancel
                     </b-button>

                   </div>

                 </template>

               </b-table>
             </b-col>
           </b-row>
         </b-overlay>

         <!--                pagination-->
         <div class="mx-1 mb-2">
           <b-row>
             <b-col cols="12" v-if="noDataTable === 0" class="text-center">
               <span >No data for preview</span>
             </b-col>
             <b-col
                 class="d-flex align-items-center justify-content-center justify-content-sm-start"
                 cols="12"
                 sm="6"
             >
               <span class="text-muted" v-if="pagination4.totalRows !==0">Showing {{pagination4.from}} to {{pagination4.to}} of {{pagination4.totalRows}} entries</span>
               <!-- <span class="text-muted" v-if="pagination.totalRows !==0">Showing 1 to 4 of 4 entries</span> -->
             </b-col>
             <!-- Pagination -->
             <b-col
                 class="d-flex align-items-center justify-content-center justify-content-sm-end"
                 cols="12"
                 sm="6"
             >

               <b-pagination
                   v-model="currentPage2"
                   :per-page="pagination4.perPage"
                   :total-rows="pagination4.totalRows"
                   class="mb-0 mt-1 mt-sm-0 "
                   first-number
                   last-number
                   next-class="next-item"
                   prev-class="prev-item"
               >
                 <template #prev-text>
                   <feather-icon
                       icon="ChevronLeftIcon"
                       size="18"
                   />
                 </template>
                 <template #next-text>
                   <feather-icon
                       icon="ChevronRightIcon"
                       size="18"
                   />
                 </template>
               </b-pagination>

             </b-col>

           </b-row>
         </div>
       </div>
     </b-card>

     <b-card class="mb-2 mt-2" no-body>
       <div>
         <b-row >
           <h4 class="ml-3 mt-2 mb-1  font-weight-700">
             Past Leaves
<!--             <span class="translate-middle badge rounded-circle bg-danger"-->
<!--                   style="font-size: 0.8em;">{{pagination.totalRows}}-->
<!--            </span>-->
             <span>({{pagination.totalRows}})</span>
           </h4>
         </b-row>

       </div>
       <div>
         <b-overlay
             :show="tableLoading"
             rounded="sm"
         >
           <b-row>
             <b-col class="ml-2 mr-2" >
               <b-table
                   ref="table"
                   :current-page="currentPage3"
                   :per-page="pagination.perPage"
                   :fields="fields1"
                   :items="getPastLeaves"
                   :sort-by.sync="sortBy"
                   :sort-desc.sync="sortDesc"
                   :sort-direction="sortDirection"
                   class="position-relative mobile_table_css" empty-text=""
                   hover
                   primary-key="id"
                   responsive
                   show-empty
               >
                 <template #cell(date)="data">
                   <div style="width: 150px">
                     <b-row class="center text-primary">
                       <p class="ml-1 font-weight-bolder pt-1 text-dark" >{{data.item.date}}</p>
                     </b-row>
                   </div>
                 </template>
                 <template #cell(time)="data">
                   <div style="width: 80px">
                     <b-row class="center text-primary">
                       <p class="ml-1 font-weight-bolder pt-1 text-dark" >{{data.item.time}}</p>
                     </b-row>
                   </div>
                 </template>

                 <template #cell(status)="data">
                   <b-row v-if="data.item.status == 'Requested'">
                     <b-col class="">
                       <b-badge variant="light-warning" >
                         Pending review
                       </b-badge>
                     </b-col>
                   </b-row>
                   <b-row  v-else-if="data.item.status == 'Approved'">
                     <b-col class="">
                       <b-badge variant="light-success" >
                         {{ data.item.status }}
                       </b-badge>
                     </b-col>
                   </b-row>
                   <b-row  v-else-if="data.item.status == 'Cancelled'">
                     <b-col class="">
                       <b-badge variant="light-secondary" >
                         {{ data.item.status }}
                       </b-badge>
                     </b-col>
                   </b-row>
                   <b-row v-else>
                     <b-col class="">
                       <b-badge variant="light-danger" >
                         {{ data.item.status }}
                       </b-badge>
                     </b-col>
                   </b-row>
                 </template>

               </b-table>
             </b-col>
           </b-row>
         </b-overlay>

         <!--                pagination-->
         <div class="mx-1 mb-2">
           <b-row>
             <b-col cols="12" v-if="noDataTable === 0" class="text-center">
               <span >No data for preview</span>
             </b-col>
             <b-col
                 class="d-flex align-items-center justify-content-center justify-content-sm-start"
                 cols="12"
                 sm="6"
             >
               <span class="text-muted" v-if="pagination.totalRows !==0">Showing {{pagination.from}} to {{pagination.to}} of {{pagination.totalRows}} entries</span>
               <!-- <span class="text-muted" v-if="pagination.totalRows !==0">Showing 1 to 5 of 5 entries</span> -->
             </b-col>
             <!-- Pagination -->
             <b-col
                 class="d-flex align-items-center justify-content-center justify-content-sm-end"
                 cols="12"
                 sm="6"
             >

               <b-pagination
                   v-model="currentPage3"
                   :per-page="pagination.perPage"
                   :total-rows="pagination.totalRows"
                   class="mb-0 mt-1 mt-sm-0 "
                   first-number
                   last-number
                   next-class="next-item"
                   prev-class="prev-item"
               >
                 <template #prev-text>
                   <feather-icon
                       icon="ChevronLeftIcon"
                       size="18"
                   />
                 </template>
                 <template #next-text>
                   <feather-icon
                       icon="ChevronRightIcon"
                       size="18"
                   />
                 </template>
               </b-pagination>

             </b-col>

           </b-row>
         </div>
       </div>
     </b-card>

     <EditLeaveSidebar
         ref="EditLeaveSidebar"
         :is-edit-leave-sidebar-active="isEditLeaveSidebarActive"
         :staff-details = "staffMember"
         @toggleSideBars="toggle()"
     />

     <AddLeaveSidebar
         ref="AddLeaveSidebar"
         :is-add-leave-sidebar-active="isAddLeaveSidebarActive"
         @toggleSideBars="toggleAddLeaveSideBar()"
     />
   </div>
</template>

<script>
import SalariedStaffLeaveAPI from '@/apis/modules/leave_managment_salaried_staff'
import MomentMixin from '@/mixins/MomentMixin'
import ErrorMessages from '@/mixins/ErrorMessages'
import RequestLeave from '@/views/leave-management/sidebars/requestLeave'
import EditLeaveSidebar from '@/views/admin-leave/sidebars/editLeaveSidebar'
import AddLeaveSidebar from '@/views/admin-leave/sidebars/userProfileAddLeaveSidebar.vue'

import {

  BCard,
  BAlert,
  BAvatar,
  BBadge, BButton, BCardBody,
  BCol, BDropdown, BDropdownItem, BForm, BFormCheckbox, BFormDatepicker,
  BFormGroup,
  BFormInput, BFormInvalidFeedback,
  BFormSelect, BFormTextarea,
  BInputGroup, BInputGroupAppend, BInputGroupPrepend, BOverlay,
  BPagination,
  BRow, BSidebar, BTab,
  BTable, BTabs, VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import ProfileAPI from '@/apis/modules/profile'
import DateDiff from 'date-diff'
import leaveManagementAdmin from '@/apis/modules/leave_managment_admin'
import AdminLeaveAPI from '@/apis/modules/leave_managment_admin'
import PracticeAPI from "@/apis/modules/practice";
import Filter from '@/mixins/FilterMixin'

export default {
  name: 'leave',
  mixins: [MomentMixin, ErrorMessages],
  components: {
    AddLeaveSidebar,
    EditLeaveSidebar,
    RequestLeave,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    vSelect,
    BInputGroupPrepend,
    BCard,
    BCardBody,
    BTabs,
    BTab,
    ValidationProvider,
    ValidationObserver,
    BAlert,
    BForm,
    BFormCheckbox,
    BFormDatepicker,
    BFormInvalidFeedback,
    BSidebar,
    BFormTextarea,
    VBTooltip,
    BOverlay,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  data() {
    return {
      totalLeaveRequests:'',
      isAddLeaveSidebarActive:false,
      editLeaveDaysShow: true,
      editNoOfLeaveDays:'',
      selectedLeave: {},
      isRequestLeaveSidebarActive: false,
      allocatedLeaveDays: 0,
      usedLeaveDays: 0,
      leaveRequestCount: 0,
      allocatedLeaveHours: 0,
      usedLeaveHours: 0,
      remainingLeaveHours: 0,
      sicknessLeaveHours: 0,
      workingHoursPerDay: 0,
      loading: false,
      userDetails: {},
      jobRoleName: '',
      currentPracticeId: '',
      trackLeaveOnDays:'',
      isEditLeaveSidebarActive: false,
      currentPage: 1,
      currentPage1: 1,
      currentPage2: 1,
      currentPage3: 1,
      staffMember:{},
      tableLoading: false,
      noDataTable:'',
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      pagination: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      pagination1: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      pagination2: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      pagination4: {
        perPage: '',
        pageOptions: '',
        totalRows:'' ,
        from:'',
        to:''
      },
      currentUserId:'',
      fields: [
        {
          key: 'date',
          label: 'DATE',
          sortable: true
        },
        {
          key: 'time',
          label: 'TIME'
        },
        {
          key: 'duration',
          label: 'DURATION'
        },
        {
          key: 'reason',
          label: 'REASON'
        },
        // {
        //   key: 'approved_by',
        //   label: 'APPROVED BY'
        // },
        // {
        //   key: 'status',
        //   label: 'STATUS',
        //   sortable: false
        // },
        {
          key: 'action',
          label: '',
          sortable: false
        }
      ],

      fields2: [
        {
          key: 'date',
          label: 'DATE',
          sortable: true
        },
        {
          key: 'time',
          label: 'TIME'
        },
        {
          key: 'duration',
          label: 'DURATION'
        },
        {
          key: 'reason',
          label: 'REASON'
        },
        {
          key: 'approved_by',
          label: 'APPROVED BY'
        },
        // {
        //   key: 'status',
        //   label: 'STATUS',
        //   sortable: false
        // },
        {
          key: 'action',
          label: '',
          sortable: false
        }
      ],
      fields4: [
        {
          key: 'date',
          label: 'DATE',
          sortable: true
        },
        {
          key: 'time',
          label: 'TIME',
        },
        {
          key: 'duration',
          label: 'DURATION',
        },
        {
          key: 'name',
          label: 'NAME',
        },
        {
          key: 'action',
          label: '',
        },
      ],

      fields1: [
        {
          key: 'date',
          label: 'DATE',
          sortable: true
        },
        {
          key: 'time',
          label: 'TIME'
        },
        {
          key: 'duration',
          label: 'DURATION'
        },
        {
          key: 'reason',
          label: 'REASON'
        },
        {
          key: 'approved_by',
          label: 'APPROVED BY'
        },
        // {
        //   key: 'status',
        //   label: 'STATUS',
        //   sortable: false
        // }
      ],
    };
  },

  methods: {
    toggleAddLeaveSideBar(){
      this.isAddLeaveSidebarActive = !this.isAddLeaveSidebarActive;
      this.$refs.table_bank_leaves.refresh();
      this.$refs.table_sheduled_leaves.refresh();
      this.getUserSummaryDetails();
    },
    async getUserSummaryDetails() {
      try {
        this.loading = true;
        const Response = await SalariedStaffLeaveAPI.userSummary(this.$route.params.id);
        let leave_takable_count = Response.data.data.users[0].leave_takable_count;
        let working_hours_per_day = Response.data.data.working_hours_per_day;
        let user_total_leave_per_year = Response.data.data.user_total_leave_per_year;
        let user_total_sickness_hour = Response.data.data.total_sickness_hours_taken;
        let total_work_hours = user_total_leave_per_year * working_hours_per_day;
        let remaing = total_work_hours - leave_takable_count;
        let used_sick_days = parseInt(user_total_sickness_hour / working_hours_per_day);
        let used_days = remaing / working_hours_per_day;

        this.usedLeaveDays = used_days;
        this.allocatedLeaveDays = Response.data.data.users[0].number_of_leaves_per_year;
        let number_of_leaves_per_year = Response.data.data.users[0].number_of_leaves_per_year;
        this.workingHoursPerDay = working_hours_per_day;
        this.allocatedLeaveHours = user_total_leave_per_year * working_hours_per_day;
        this.usedLeaveHours = used_days * working_hours_per_day;
        this.remainingLeaveHours = (number_of_leaves_per_year - used_days) * working_hours_per_day;
        this.sicknessLeaveHours = user_total_sickness_hour;

        this.userDetails = Response.data.data.users[0].user;
        this.currentPracticeId = await Response.data.data.users[0].practice_id;
        this.jobRoleName = Response.data.data.users[0].job_role.name;
        this.leaveRequestCount = Response.data.data.pending_request_count;
        this.sicknessLeaveDays = used_sick_days;
        this.trackLeaveOnDays = !!Response.data.data.practice.track_leave_on_days;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        this.convertAndNotifyError(error);
      }
    },

    editLeave(data) {
      this.staffMember = data
      this.isEditLeaveSidebarActive = !this.isEditLeaveSidebarActive
    },
    async getCurrentUser(){
      const user =  (await ProfileAPI.getCurrentUser()).data.data
      this.currentUserId = user.id
    },

    async scheduledLeaves() {
      try{
        this.tableLoading = true
        const response = await leaveManagementAdmin.ScheduledLeaves(this.$route.params.id, 5 , this.currentPage1)
        let scheduledLeavesArray = response.data.data.leaves.data.map((x) => ({
          id: x.id,
          user_id: x.user.id,
          date: x.is_day ? `${this.momentFormat(x.start, 'DD-MMM-YY')}` + ` - ` + `${this.momentFormat(x.end, 'DD-MMM-YY')}` : this.momentFormat(x.start, 'DD-MMM-YY'),
          time: x.is_day ? 'Full day' : `${this.momentFormat(x.start, 'HH:mm')}` + ' - ' + `${this.momentFormat(x.end, 'HH:mm')}`,
          duration: x.is_day ? `${new DateDiff(new Date(x.end), new Date(x.start)).days()} days` : `${new DateDiff(new Date(x.end), new Date(x.start)).hours()} hours`,
          reason: x.sickness_type_id && x.leave_reason_id == 1 ? x.sickness_type.name : x.leave_reason_id ? x.leave_reason.name : 'N/A',
          reason_id: x.leave_reason_id === null ? 'N/A' : x.leave_reason.id,
          sickness_id: x.sickness_type_id === null ? 'N/A' : x.sickness_type.id,
          approved_by: x.administrator ? x.administrator.first_name + ' ' + x.administrator.last_name : 'not approved',
          staff: x.user.first_name + ' ' + x.user.last_name,
          is_day: x.is_day,
          start: x.start,
          end: x.end,
          start_time: `${this.momentFormat(x.start, 'HH:mm')}`,
          end_time: `${this.momentFormat(x.end, 'HH:mm')}`
        }))
        this.total = response.data.data.count
        this.tableLoading = false
        const paginationResponse = response.data.data.leaves
        this.currentPage1 = paginationResponse.current_page
        this.pagination2.totalRows = paginationResponse.total
        this.pagination2.perPage = paginationResponse.per_page
        this.pagination2.from = paginationResponse.from
        this.pagination2.to = paginationResponse.to
        return scheduledLeavesArray
      }catch(error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }

    },


    async getPastLeaves(){
      try{
        this.tableLoading = true
        await this.getCurrentUser()
        const Response = await SalariedStaffLeaveAPI.usersPastLeaves( this.currentUserId ,5, this.currentPage3)
        let dataArray =  Response.data.data.map((x) => ({
          id: x.id,
          date: x.is_day?`${this.momentFormat(x.start,'MMM DD, YYYY')}`+` - ` +`${ this.momentFormat(x.end,'MMM DD, YYYY')}`:this.momentFormat(x.start,'MMM DD, YYYY'),
          start: x.start,
          is_day: x.is_day,
          end: x.end,
          duration: x.is_day?`${new DateDiff( new Date(x.end), new Date(x.start)).days()} days`: `${new DateDiff( new Date(x.end), new Date(x.start)).hours()} hours`,
          reason: x.sickness_type_id && x.leave_reason_id == 1 ? x.sickness_type.name : x.leave_reason_id ? x.leave_reason.name : 'N/A',
          sickness_id: x.sickness_type_id === null ? 'N/A' : x.sickness_type.id,
          user: x.user === null? 'N/A':x.user.first_name +' '+ x.user.last_name,
          user_id: x.user.id,
          approved_by: x.administrator === null ? 'N/A' : `${x.administrator.first_name}  ${x.administrator.last_name}`,
          status: x.state.charAt(0).toUpperCase() + x.state.substring(1),
          time:x.is_day?'Full day': `${this.momentFormat(x.start,'HH:mm')}` + ' - ' + `${this.momentFormat(x.end,'HH:mm')}`
        }))
        this.tableLoading = false
        const paginationResponse = Response.data.meta
        this.currentPage3 = paginationResponse.current_page
        this.pagination.totalRows = paginationResponse.total
        this.pagination.perPage= paginationResponse.per_page
        this.pagination.from = paginationResponse.from
        this.pagination.to = paginationResponse.to

        return dataArray
      }catch(error){
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }
    },

    // async cancelLeave(id) {
    //   try{
    //     this.tableLoading = true
    //     const response = await leaveManagementAdmin.cancelLeave(id)
    //     this.tableLoading = false
    //     if (response.data) {
    //       this.$refs.table.refresh()
    //       this.showSuccessMessage('Leave is cancelled successfully')
    //       this.$refs.table_bank_leaves.refresh();
    //       this.$refs.table_sheduled_leaves.refresh();
    //       await this.getUserSummaryDetails()
    //     }
    //   }catch(error) {
    //     this.convertAndNotifyError(error)
    //   }
    // },


    async cancelLeave(id) {
      try {
        this.tableLoading = true
        this.$swal({
          title: 'Are you sure to delete this Leave?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete',
          cancelButtonText: 'No, go back',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-primary ml-1'
          },
          buttonsStyling: false
        }).then(async result => {
        if (result.value) {
          const response = await leaveManagementAdmin.cancelLeave(id)
          this.tableLoading = false
          this.$refs.table.refresh()
          this.showSuccessMessage('Leave is cancelled successfully')
          this.$refs.table_bank_leaves.refresh();
          this.$refs.table_sheduled_leaves.refresh();
          await this.getUserSummaryDetails()

          } 
        })
      } catch (error) {
        this.convertAndNotifyError(error) 
      }
       this.tableLoading = false
    },

    refreshTable(){
      this.$refs.table.refresh()
    },
    async toggle() {
      this.isEditLeaveSidebarActive = !this.isEditLeaveSidebarActive
      this.$refs.table_leave_requests.refresh()
      this.$refs.table_bank_leaves.refresh()
      this.$refs.table_sheduled_leaves.refresh();
    },

    async leaveRequests() {
      try {
        this.tableLoading = true
        const Response = await leaveManagementAdmin.getLeaveRequests(this.$route.params.id,5 , this.currentPage);
        let leavesArray = Response.data.data.leaves.data.map((x) => ({
          id: x.id,
          user_id: x.user.id,
          date: x.is_day ? `${this.momentFormat(x.start, 'MMM DD, YYYY')}` + ` - ` + `${this.momentFormat(x.end, 'MMM DD, YYYY')}` : this.momentFormat(x.start, 'MMM DD, YYYY'),
          time: x.is_day ? 'Full day' : `${this.momentFormat(x.start, 'HH:mm')}` + ' - ' + `${this.momentFormat(x.end, 'HH:mm')}`,
          duration: x.is_day ? `${new DateDiff(new Date(x.end), new Date(x.start)).days()}` : `${new DateDiff(new Date(x.end), new Date(x.start)).hours()}`,
          reason: x.sickness_type_id && x.leave_reason_id == 1 ? x.sickness_type.name : x.leave_reason_id ? x.leave_reason.name : 'N/A',
          reason_id: x.leave_reason_id === null ? 'N/A' : x.leave_reason.id,
          sickness_id: x.sickness_type_id === null ? 'N/A' : x.sickness_type.id,
          staff: x.user.first_name + ' ' + x.user.last_name,
          is_day: x.is_day,
          start: x.start,
          end: x.end,
          start_time: `${this.momentFormat(x.start, 'HH:mm')}`,
          end_time: `${this.momentFormat(x.end, 'HH:mm')}`
        }))
        this.totalLeaveRequests = Response.data.data.count

        this.tableLoading = false
        const paginationResponse = Response.data.data.leaves
        this.currentPage = paginationResponse.current_page
        this.pagination1.totalRows = paginationResponse.total
        this.pagination1.perPage = paginationResponse.per_page
        this.pagination1.from = paginationResponse.from
        this.pagination1.to = paginationResponse.to
        return leavesArray
      }catch (error) {
        this.convertAndNotifyError(error)
        this.tableLoading = false
      }

    },

    async bankHolidayLeaves() {
     try{
       this.tableLoading = true
       const response = await leaveManagementAdmin.bankHolidayLeaves(this.$route.params.id, 5 , this.currentPage2)
     let scheduledLeavesArray = response.data.data.leaves.data.map((x) => ({
       id: x.id,
       user_id: x.user.id,
       date: x.is_day ? `${this.momentFormat(x.start, 'DD-MMM-YY')}` + ` - ` + `${this.momentFormat(x.end, 'DD-MMM-YY')}` : this.momentFormat(x.start, 'DD-MMM-YY'),
       time: x.is_day ? 'Full day' : `${this.momentFormat(x.start, 'HH:mm')}` + ' - ' + `${this.momentFormat(x.end, 'HH:mm')}`,
       duration: x.is_day ? `${new DateDiff(new Date(x.end), new Date(x.start)).days()} days` : `${new DateDiff(new Date(x.end), new Date(x.start)).hours()} hours`,
       reason: x.sickness_type_id && x.leave_reason_id == 1 ? x.sickness_type.name : x.leave_reason_id ? x.leave_reason.name : 'N/A',
       reason_id: x.leave_reason_id === null ? 'N/A' : x.leave_reason.id,
       name: x.bank_holiday_title  ? x.bank_holiday_title :'N/A',
       sickness_id: x.sickness_type_id === null ? 'N/A' : x.sickness_type.id,
       approved_by: x.administrator ? x.administrator.first_name + ' ' + x.administrator.last_name : 'not approved',
       staff: x.user.first_name + ' ' + x.user.last_name,
       is_day: x.is_day,
       start: x.start,
       end: x.end,
       start_time: `${this.momentFormat(x.start, 'HH:mm')}`,
       end_time: `${this.momentFormat(x.end, 'HH:mm')}`
     }))
     this.total = response.data.data.count

       this.tableLoading = false
       const paginationResponse = response.data.data.leaves
       this.currentPage2 = paginationResponse.current_page
       this.pagination4.totalRows = paginationResponse.total
       this.pagination4.perPage = paginationResponse.per_page
       this.pagination4.from = paginationResponse.from
       this.pagination4.to = paginationResponse.to
       return scheduledLeavesArray
   }catch(error) {
       this.convertAndNotifyError(error)
       this.tableLoading = false
     }

   },

    toggleSideBars(data) {
      this.staffMember = data
      this.isEditLeaveSidebarActive = !this.isEditLeaveSidebarActive
    },

   
      async approveLeave(id) {
      try {
        this.tableLoading = true
        this.$swal({
          title: 'Are you sure to approve this Leave?',
          imageUrl: require('@/assets/images/icons/publish.png'),
          showCancelButton: true,
          confirmButtonText: 'Yes, approve',
          cancelButtonText: 'No, go back',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-primary ml-1'
          },
          buttonsStyling: false
        }).then(async result => {
        if (result.value) {
          const response = await leaveManagementAdmin.approveLeave(id)
          this.tableLoading = false
          this.$refs.table.refresh()
          this.showSuccessMessage('Leave is approved successfully')
           this.$refs.table_leave_requests.refresh()
          } 
        })
      } catch (error) {
        this.convertAndNotifyError(error) 
      }
       this.tableLoading = false
    },


        async rejectLeave(id) {
      try {
        this.tableLoading = true
        this.$swal({
          title: 'Are you sure to reject this Leave?',
          imageUrl: require('@/assets/images/icons/publish.png'),
          showCancelButton: true,
          confirmButtonText: 'Yes, Reject',
          cancelButtonText: 'No, go back',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-primary ml-1'
          },
          buttonsStyling: false
        }).then(async result => {
        if (result.value) {
          const response = await leaveManagementAdmin.rejectLeave(id)
          this.tableLoading = false
          this.$refs.table.refresh()
          this.showSuccessMessage('Leave is rejected successfully')
           this.$refs.table_leave_requests.refresh()
          } 
        })
      } catch (error) {
        this.convertAndNotifyError(error) 
      }
       this.tableLoading = false
    },


    async editLeaveDays() {
      try {
        await this.getCurrentUser()
        if (!this.editLeaveDaysShow) {
          this.loading = true;
          let payload = {};
          payload.user_id = this.$route.params.id;
          payload.number_of_leaves_per_year = this.editNoOfLeaveDays;
          const Response = await AdminLeaveAPI.updateUsersNumberOfLeaves(
              payload
          );
          await this.getUserSummaryDetails(this.userDetails.id);
          this.editLeaveDaysShow = !this.editLeaveDaysShow;
          this.loading = false;
        }
        else {
          // this.trackLeaveOnDays?this.editNoOfLeaveDays = this.allocatedLeaveDays:this.editNoOfLeaveDays = this.allocatedLeaveDays
          this.editNoOfLeaveDays = this.allocatedLeaveDays
          this.editLeaveDaysShow = !this.editLeaveDaysShow;
        }
      } catch (error) {
        this.loading = false;

        this.editLeaveDaysShow = !this.editLeaveDaysShow;
        this.convertAndNotifyError(error);
      }
    },
    async getPracticeDetails() {
      try {
        const Response = await PracticeAPI.getPracticeDetails();
        this.$store.state.userWorkingHoursPerDay =
          Response.data.data.working_hours_per_day;
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },
  },
  async mounted() {
    await this.getUserSummaryDetails()
    await this.getCurrentUser()
    await this.getPracticeDetails()
  },
}
</script>

<style scoped>

</style>