<template>
  <div>
    <b-modal
      id="edit_mandatory_training_model"
      v-model="show"
      centered
      hide-footer
      no-close-on-backdrop
      size="lg"
      title="Edit Mandatory Training"
      @change="clearData"
    >
      <b-overlay :show="loadingModel" rounded="sm">
        <b-card-body>
          <validation-observer ref="trainingForm">
            <span
              >Choose the trainings that are applicable for the staff’s role.
            </span>
            <hr />

            <div class="mt-1  ml-n1 mr-n1">
              <b-row>
                <span
                  class="ml-2 mt-1 mb-1 ml-auto mr-auto"
                  style="
                    font-family: Mulish;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 14px;
                    line-height: 21px;
                    color: #171822;
                  "
                >
                  <b-form-group style="margin-top: 6px">
                    <validation-provider
                      #default="{ errors }"
                      name="Training"
                      rules="required"
                    >
                      <v-select
                        label="name"
                        v-model="selectedTraining"
                        :options="fileTypeOption"
                        :reduce="(fileTypeOption) => fileTypeOption.id"
                        style="width: 400px"
                        placeholder="Select training"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </span>
              </b-row>

              <b-row>
                <b-col cols="3">
                  <span class="" style="margin-left: 6px; font-size: 12px"
                    >Date of Training</span
                  >

                  <b-form-group
                    class=""
                    style="margin-left: 6px; margin-top: 6px"
                  >
                    <validation-provider
                      name="Start date"
                      rules="required"
                      #default="{ errors }"
                    >
                      <b-input-group class="input-group-merge">
                        <b-form-datepicker
                          id="start_date"
                          v-model="form.start_date"
                          :date-format-options="{
                            year: '2-digit',
                            month: '2-digit',
                            day: '2-digit',
                          }"
                          placeholder="Select"
                          start-weekday="1"
                          class="form-control"
                          locale="en-UK"
                          trim
                        />
                      </b-input-group>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="3">
                  <span class="" style="margin-left: 0px; font-size: 12px"
                    >Renewal frequency</span
                  >
                  <b-form-group style="margin-left: -5px; margin-right: -5px; margin-top: 6px">
                    <v-select
                      :disabled="renewalDesabled"
                      label="name"
                      v-model="seleceted_renewal"
                      placeholder="Select"
                      :options="renewal_options"
                      :reduce="(renewal_options) => renewal_options.key"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="3">
                  <b-form-checkbox
                    class=""
                    style="margin-top: 32px"
                    v-model="form.is_non_renewal"
                  >
                    <span class="todo-title">Non-renewalss</span>
                  </b-form-checkbox>
                </b-col>

                <b-col cols="3" style="margin-top: 30px">
                  <div
                    v-b-tooltip.hover.top="'Download certificate'"
                    class="document-name"
                    v-if="uploadedCertificate.name"
                    @click="downloadFile(uploadedCertificate)"
                  >
                    View certificate
                  </div>
                  <div
                    v-b-tooltip.hover.top="'Replace new certificate'"
                    class="document-name"
                    v-if="uploadedCertificate.name"
                    @click="
                      deleteCurrentAndUploadNewDocumentPopup(
                        uploadedCertificate
                      )
                    "
                  >
                    Edit certificate
                  </div>
                  <a v-else href="#" @click="openUploadDocumentPopup()"
                    >Upload document</a
                  >
                </b-col>
              </b-row>
            </div>
            <b-row>
              <span class="ml-2 mt-1 ml-auto">
                <div class="d-flex mt-1 ml-auto">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="primary"
                    @click="submitForm"
                  >
                    Save
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    class="text-primary ml-1 shadow"
                    type="button"
                    variant="outline-white"
                    @click="clearData"
                  >
                    Cancel
                  </b-button>
                </div>
              </span>
            </b-row>
          </validation-observer>
        </b-card-body>
      </b-overlay>
    </b-modal>
    <UploadCertificate ref="uploadCertificate" @getDocument="getDocument" />
  </div>
</template>

<script>
import {
  BAvatar,
  BBadge,
  BButton,
  BCard,
  BCol,
  BLink,
  BRow,
  BFormCheckbox,
  BCardBody,
  BCardHeader,
  BImg,
  BMedia,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BDropdown,
  BDropdownItem,
  BInputGroupPrepend,
  BListGroup,
  BListGroupItem,
  BModal,
  BFormFile,
  BFormDatepicker,
  BOverlay,
  VBTooltip,
} from "bootstrap-vue";

import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import MomentMixin from "@/mixins/MomentMixin";
import Ripple from "vue-ripple-directive";
import staffBankAPI from "@/apis/modules/staffBank";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import UploadCertificate from "@/views/staff-bank/staff-profile/includes/popup/UploadCertificate.vue";
import staffBank from "@/apis/modules/staffBank";
import axios from "axios";
import { apiUrl } from "@/constants/config";

export default {
  name: "documentsAndTraining",
  components: {
    flatPickr,
    BFormDatepicker,
    BCard,
    BRow,
    BCol,
    BButton,
    BAvatar,
    BLink,
    BBadge,
    BCardBody,
    BCardHeader,
    BFormCheckbox,
    BImg,
    BMedia,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BDropdown,
    BDropdownItem,
    BInputGroupPrepend,
    BListGroup,
    BListGroupItem,
    vSelect,
    BModal,
    BFormFile,
    BOverlay,
    ValidationObserver,
    ValidationProvider,
    VBTooltip,
    UploadCertificate,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  mixins: [MomentMixin],

  data() {
    return {
      show: false,
      selected_training: "",
      fileTypeOption: [],
      loadingModel: true,
      renewalDesabled: false,
      toastMessage: "",
      form: {
        id: "",
        user_id: "",
        training_id: "",
        start_date: "",
        expire_date: "",
        training_certificate_id: "",
        is_non_renewal: false,
      },
      renewal_options: [
        {
          key: "12",
          value: "12",
          name: "12 Months",
        },
        {
          key: "24",
          value: "24",
          name: "24 Months",
        },
        {
          key: "36",
          value: "36",
          name: "36 Months",
        },
      ],
      seleceted_renewal: "",
      uploadedCertificate: [],
      selectedTraining: "",
    };
  },

  watch: {
    "form.is_non_renewal"() {
      if (this.form.is_non_renewal === 1 || this.form.is_non_renewal === true) {
        this.renewalDesabled = true;
        this.seleceted_renewal = 0;
      } else {
        this.renewalDesabled = false;
      }
    },
  },

  methods: {
    async getTrainings() {
      try {
        let respond = (await staffBankAPI.getUserUnAssignTrainings(this.$route.params.id)).data;
        this.fileTypeOption = respond.data.map((x) => ({
          id: x.id,
          name: x.name,
        }));
      } catch (e) {}
      this.loadingModel = false;
    },
    async getDocument(respond) {
      if (respond) {
        this.uploadedCertificate = respond;
        // this.uploadedCertificate = (await staffBankAPI.getOneCertificate(respond)).data.data[0]
        
        this.form.training_certificate_id = this.uploadedCertificate.id;
      }
    },
    async open(item) {
      await this.getTrainings();
      if (item) {
        this.selectedTraining = {
          id : item.training_id,
          name:item.training_name
        }
        this.form.id = item.id;
        this.form.start_date = item.start_date;
        this.form.expire_date = item.expire_date;
        this.form.is_non_renewal = item.is_non_renewal;
        this.renewalDesabled = item.is_non_renewal;
        this.form.training_certificate_id = item.training_certificate
          ? item.training_certificate.id
          : null;
        this.uploadedCertificate = item.training_certificate
          ? item.training_certificate
          : [];
        let year =
          new Date(item.expire_date).getFullYear() -
          new Date(item.start_date).getFullYear();
        this.seleceted_renewal = this.renewal_options.find(
          (e) => e.key * 1 === year * 12
        );
      }
      this.show = true;
      this.loadingModel = false;
    },

    openUploadDocumentPopup() {
      this.$refs.uploadCertificate.open();
    },
    async deleteCurrentAndUploadNewDocumentPopup(item) {
      this.$refs.uploadCertificate.replaceDocument(item);
    },
    async submitForm() {
      try {
        if (await this.$refs.trainingForm.validate()) {
         
          this.form.user_id = this.$route.params.id;
          if (typeof this.selectedTraining === "object") {
            this.form.training_id = this.selectedTraining.id;
          } else {
            this.form.training_id = this.selectedTraining;
          }
          if (this.renewalDesabled === true) {
            this.form.expire_date = "";
          } else {
            let year = new Date(this.form.start_date).getFullYear();
            let month = new Date(this.form.start_date).getMonth();
            let day = new Date(this.form.start_date).getDate();
            if (typeof this.seleceted_renewal === "object") {
              year = year + this.seleceted_renewal.key / 12;
            } else {
              year = year + this.seleceted_renewal / 12;
            }
            this.form.expire_date = this.momentFormat(
              new Date(year, month, day),
              "YYYY-MM-DD"
            );
          }

          await staffBankAPI.updateTraining(this.form.id, this.form);
          this.showSuccessMessage("Training update successfully");
          this.clearForm();
        }
      } catch (error) {
        this.convertAndNotifyError(error);
      }
    },
    async downloadFile(item) {
      try {
        this.loadingModel = true;

        const response = await staffBank.downloadCertificate(item.id);
        axios
          .get(
            `${apiUrl}/settings/training-certificates/${
              item.id
            }/download?auth_practice_id=${sessionStorage.getItem(
              "practice_id"
            )}`,
            {
              // headers{
              // }
              responseType: "arraybuffer",
              withCredentials: true,
            }
          )
          .then((response) => {
            let blob = new Blob([response.data], { type: item.mime_type }),
              link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = item.name;
            link.click();
          });
        this.showSuccessMessage("File Downloaded Successfully");
      } catch (error) {
        this.convertAndNotifyError(error);
        this.loadingModel = false;
      }
      this.loadingModel = false;
    },
    clearForm() {
      this.clearData();
      this.$emit("getAllData");
    },
    clearData() {
      this.form = {};
      this.seleceted_renewal = 0;
      this.show = false;
      this.uploadedCertificate = [];
    },
  },

  mounted() {},
};
</script>

<style scoped>
.document-name {
  cursor: pointer;
}
</style>
