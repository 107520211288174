var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-sidebar", {
    attrs: {
      id: "edit-madatory-sidebar",
      visible: _vm.isEditMandatorySidebarActive,
      backdrop: "",
      "bg-variant": "white",
      "no-header": "",
      right: "",
      shadow: "",
      "sidebar-class": "sidebar-lg"
    },
    on: {
      change: function(val) {
        return _vm.$emit("update:is-edit-mandatory-sidebar-active", val)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hide = ref.hide
          return [
            _c(
              "div",
              {
                staticClass:
                  "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
              },
              [
                _c("h4", { staticClass: "mb-0" }, [
                  _vm._v(" Edit Mandatory Training ")
                ]),
                _c("feather-icon", {
                  staticClass: "ml-1 cursor-pointer",
                  attrs: { icon: "XIcon", size: "16" },
                  on: {
                    click: function($event) {
                      _vm.clearForm()
                      hide()
                    }
                  }
                })
              ],
              1
            ),
            _c(
              "validation-observer",
              { ref: "refFormObserver" },
              [
                _c(
                  "b-overlay",
                  { attrs: { rounded: "sm" } },
                  [
                    _c(
                      "b-card-body",
                      [
                        _c(
                          "validation-observer",
                          { ref: "trainingForm" },
                          [
                            _c("span", [
                              _c("div", { staticClass: "ml-2 mt-2" }, [
                                _vm._v(
                                  "Choose the trainings that are applicable for the staff’s role."
                                )
                              ])
                            ]),
                            _c("hr"),
                            _c(
                              "div",
                              { staticClass: "ml-2" },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticClass: "mb-n1",
                                        attrs: { cols: "11" }
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "6px",
                                              "font-size": "12px"
                                            }
                                          },
                                          [_vm._v("Select Training")]
                                        )
                                      ]
                                    ),
                                    _c("b-col", { attrs: { cols: "11" } }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "ml-2",
                                          staticStyle: {
                                            "font-family": "Mulish",
                                            "font-style": "normal",
                                            "font-weight": "bold",
                                            "font-size": "14px",
                                            "line-height": "21px",
                                            color: "#171822"
                                          }
                                        },
                                        [
                                          _c(
                                            "b-form-group",
                                            {
                                              staticStyle: {
                                                "margin-left": "6px"
                                              }
                                            },
                                            [
                                              _c("validation-provider", {
                                                attrs: {
                                                  name: "Training",
                                                  rules: "required"
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function(ref) {
                                                        var errors = ref.errors
                                                        return [
                                                          _c("v-select", {
                                                            attrs: {
                                                              label: "name",
                                                              options:
                                                                _vm.fileTypeOption,
                                                              reduce: function(
                                                                fileTypeOption
                                                              ) {
                                                                return fileTypeOption.id
                                                              },
                                                              placeholder:
                                                                "Select training"
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.selectedTraining,
                                                              callback: function(
                                                                $$v
                                                              ) {
                                                                _vm.selectedTraining = $$v
                                                              },
                                                              expression:
                                                                "selectedTraining"
                                                            }
                                                          }),
                                                          _c(
                                                            "small",
                                                            {
                                                              staticClass:
                                                                "text-danger"
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  errors[0]
                                                                )
                                                              )
                                                            ]
                                                          )
                                                        ]
                                                      }
                                                    }
                                                  ],
                                                  null,
                                                  true
                                                )
                                              })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "11" } },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "6px",
                                              "font-size": "12px"
                                            }
                                          },
                                          [_vm._v("Date of Training")]
                                        ),
                                        _c(
                                          "b-form-group",
                                          {
                                            staticStyle: {
                                              "margin-left": "6px",
                                              "margin-top": "6px"
                                            }
                                          },
                                          [
                                            _c("validation-provider", {
                                              attrs: {
                                                name: "Start date",
                                                rules: "required"
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "default",
                                                    fn: function(ref) {
                                                      var errors = ref.errors
                                                      return [
                                                        _c(
                                                          "b-input-group",
                                                          {
                                                            staticClass:
                                                              "input-group-merge"
                                                          },
                                                          [
                                                            _c(
                                                              "b-form-datepicker",
                                                              {
                                                                staticClass:
                                                                  "form-control",
                                                                attrs: {
                                                                  id:
                                                                    "start_date",
                                                                  "date-format-options": {
                                                                    year:
                                                                      "numeric",
                                                                    month:
                                                                      "2-digit",
                                                                    day:
                                                                      "2-digit"
                                                                  },
                                                                  placeholder:
                                                                    "Select",
                                                                  "start-weekday":
                                                                    "1",
                                                                  locale:
                                                                    "en-UK",
                                                                  trim: ""
                                                                },
                                                                model: {
                                                                  value:
                                                                    _vm.form
                                                                      .start_date,
                                                                  callback: function(
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.form,
                                                                      "start_date",
                                                                      $$v
                                                                    )
                                                                  },
                                                                  expression:
                                                                    "form.start_date"
                                                                }
                                                              }
                                                            )
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "small",
                                                          {
                                                            staticClass:
                                                              "text-danger"
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(errors[0])
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    }
                                                  }
                                                ],
                                                null,
                                                true
                                              )
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "8" } },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-left": "6px",
                                              "font-size": "12px"
                                            }
                                          },
                                          [_vm._v("Renewal frequency")]
                                        ),
                                        _c(
                                          "b-form-group",
                                          {
                                            staticStyle: {
                                              "margin-left": "6px",
                                              "margin-top": "6px"
                                            }
                                          },
                                          [
                                            _c("v-select", {
                                              attrs: {
                                                disabled: _vm.renewalDesabled,
                                                label: "name",
                                                placeholder: "Select",
                                                options: _vm.renewal_options,
                                                reduce: function(
                                                  renewal_options
                                                ) {
                                                  return renewal_options.key
                                                }
                                              },
                                              model: {
                                                value: _vm.seleceted_renewal,
                                                callback: function($$v) {
                                                  _vm.seleceted_renewal = $$v
                                                },
                                                expression: "seleceted_renewal"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-col",
                                      { attrs: { cols: "4" } },
                                      [
                                        _vm.form.is_non_renewal === 1
                                          ? _c(
                                              "b-form-checkbox",
                                              {
                                                staticStyle: {
                                                  "margin-top": "32px"
                                                },
                                                attrs: {
                                                  value:
                                                    _vm.form.is_non_renewal,
                                                  id: _vm.form.id
                                                },
                                                on: { change: _vm.changeState },
                                                model: {
                                                  value:
                                                    _vm.form.is_non_renewal,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "is_non_renewal",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.is_non_renewal"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "todo-title" },
                                                  [_vm._v("Non-renewal")]
                                                )
                                              ]
                                            )
                                          : _c(
                                              "b-form-checkbox",
                                              {
                                                staticStyle: {
                                                  "margin-top": "32px"
                                                },
                                                on: { change: _vm.changeState },
                                                model: {
                                                  value:
                                                    _vm.form.is_non_renewal,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "is_non_renewal",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "form.is_non_renewal"
                                                }
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  { staticClass: "todo-title" },
                                                  [_vm._v("Non-renewal")]
                                                )
                                              ]
                                            )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c("b-col", { attrs: { cols: "11" } }, [
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "margin-left": "6px",
                                            "font-size": "12px"
                                          }
                                        },
                                        [_vm._v("Select Document")]
                                      )
                                    ])
                                  ],
                                  1
                                ),
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      {
                                        staticStyle: {
                                          "margin-top": "10px",
                                          "margin-left": "6px"
                                        },
                                        attrs: { cols: "11" }
                                      },
                                      [
                                        _vm.uploadedCertificate.name
                                          ? _c(
                                              "b-row",
                                              [
                                                _c(
                                                  "b-col",
                                                  { attrs: { cols: "6" } },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "ripple",
                                                            rawName:
                                                              "v-ripple.400",
                                                            value:
                                                              "rgba(186, 191, 199, 0.15)",
                                                            expression:
                                                              "'rgba(186, 191, 199, 0.15)'",
                                                            modifiers: {
                                                              "400": true
                                                            }
                                                          }
                                                        ],
                                                        staticClass:
                                                          "text-primary ml-auto mr-2 mt-1",
                                                        attrs: {
                                                          type: "reset"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.downloadFile(
                                                              _vm.uploadedCertificate
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " View certificate "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                ),
                                                _c(
                                                  "b-col",
                                                  { attrs: { cols: "6" } },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "ripple",
                                                            rawName:
                                                              "v-ripple.400",
                                                            value:
                                                              "rgba(186, 191, 199, 0.15)",
                                                            expression:
                                                              "'rgba(186, 191, 199, 0.15)'",
                                                            modifiers: {
                                                              "400": true
                                                            }
                                                          }
                                                        ],
                                                        staticClass:
                                                          "text-primary ml-auto mr-2 mt-1",
                                                        attrs: {
                                                          type: "reset"
                                                        },
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            return _vm.deleteCurrentAndUploadNewDocumentPopup(
                                                              _vm.uploadedCertificate
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " Edit certificate "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              ],
                                              1
                                            )
                                          : _c(
                                              "a",
                                              {
                                                attrs: { href: "#" },
                                                on: {
                                                  click: function($event) {
                                                    return _vm.openUploadDocumentPopup()
                                                  }
                                                }
                                              },
                                              [_vm._v("Upload document")]
                                            )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c("b-row", [
                              _c("span", { staticClass: "mr-4 mt-2 ml-3" }, [
                                _c(
                                  "div",
                                  { staticClass: "d-flex mt-1" },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple.400",
                                            value: "rgba(255, 255, 255, 0.15)",
                                            expression:
                                              "'rgba(255, 255, 255, 0.15)'",
                                            modifiers: { "400": true }
                                          }
                                        ],
                                        attrs: {
                                          type: "submit",
                                          variant: "primary"
                                        },
                                        on: { click: _vm.submitForm }
                                      },
                                      [_vm._v(" Save ")]
                                    ),
                                    _c(
                                      "b-button",
                                      {
                                        directives: [
                                          {
                                            name: "ripple",
                                            rawName: "v-ripple.400",
                                            value: "rgba(186, 191, 199, 0.15)",
                                            expression:
                                              "'rgba(186, 191, 199, 0.15)'",
                                            modifiers: { "400": true }
                                          }
                                        ],
                                        staticClass: "text-primary ml-1 shadow",
                                        attrs: {
                                          type: "button",
                                          variant: "outline-white"
                                        },
                                        on: {
                                          click: function($event) {
                                            _vm.clearForm()
                                            hide()
                                          }
                                        }
                                      },
                                      [_vm._v(" Cancel ")]
                                    )
                                  ],
                                  1
                                )
                              ])
                            ])
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _c("UploadCertificate", {
              ref: "uploadCertificate",
              on: { getDocument: _vm.getDocument }
            })
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }