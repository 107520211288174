var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "match-height" },
        [
          _c(
            "b-col",
            { attrs: { lg: "5", md: "6" } },
            [
              _c(
                "b-card",
                { attrs: { "no-body": "" } },
                [
                  _c("b-card-header", { staticClass: "border-bottom pb-1" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex flex-row" },
                      [
                        _c(
                          "b-avatar",
                          { attrs: { rounded: "", variant: "light-primary" } },
                          [
                            _c("feather-icon", {
                              staticClass: "text-dark font-weight-700",
                              attrs: { icon: "MapPinIcon", size: "18" }
                            })
                          ],
                          1
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "ml-1",
                            staticStyle: { "padding-top": "8px" }
                          },
                          [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-family": "Mulish",
                                  "font-style": "normal",
                                  "font-weight": "bold",
                                  "font-size": "20px",
                                  "line-height": "25px",
                                  color: "#171822"
                                }
                              },
                              [_vm._v(" Locations ")]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c("div", { staticClass: "ml-auto" })
                  ]),
                  _c(
                    "b-card-body",
                    { staticClass: " pl-1 px-0 mx-0" },
                    [
                      _c(
                        "vue-perfect-scrollbar",
                        {
                          staticClass:
                            "todo-task-list-wrapper list-group scroll-area min-vh-50",
                          staticStyle: { "max-height": "400px" }
                        },
                        [
                          _c(
                            "ul",
                            { staticClass: "list-group list-group-flush" },
                            _vm._l(_vm.connectedLocations, function(location) {
                              return _c(
                                "li",
                                { staticClass: "list-group-item font-black" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "normal",
                                        "font-size": "14px",
                                        "line-height": "21px",
                                        color: "#171822"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(location.practice.name) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("br"),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "normal",
                                        "font-size": "12px",
                                        "line-height": "15px",
                                        color: "rgba(23, 24, 34, 0.6)"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(location.practice.address) +
                                          ", " +
                                          _vm._s(location.practice.city) +
                                          ", " +
                                          _vm._s(
                                            location.practice.postal_code
                                          ) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          this.openEditEmployInfo === false
            ? _c(
                "b-col",
                { attrs: { lg: "7", md: "6" } },
                [
                  _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        { staticClass: "border-bottom pb-1" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row" },
                            [
                              _c(
                                "b-avatar",
                                {
                                  attrs: {
                                    rounded: "",
                                    variant: "light-primary"
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "text-dark font-weight-700",
                                    attrs: { icon: "FileTextIcon", size: "18" }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: { "padding-top": "8px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "bold",
                                        "font-size": "20px",
                                        "line-height": "25px",
                                        color: "#171822"
                                      }
                                    },
                                    [_vm._v(" Employment Info ")]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "ml-auto" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass:
                                    "btn-icon bg-white shadow-sm font-weight-bold",
                                  attrs: { variant: "white" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openEditEmploymentInfoForm(
                                        _vm.isActiveEditEmployInfo
                                      )
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        value: "Edit",
                                        expression: "'Edit'",
                                        modifiers: { hover: true, top: true }
                                      }
                                    ],
                                    staticClass: "text-primary",
                                    attrs: { icon: "EditIcon", size: "20" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "b-card-body",
                        [
                          _c(
                            "b-row",
                            { staticClass: "match-height mt-2" },
                            [
                              _c(
                                "b-col",
                                { attrs: { lg: "6", md: "6" } },
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { lg: "6", md: "6" } },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-family": "Mulish",
                                                "font-style": "normal",
                                                "font-weight": "normal",
                                                "font-size": "14px",
                                                "line-height": "18px",
                                                color: "rgba(23, 24, 34, 0.6)"
                                              }
                                            },
                                            [_vm._v(" Date Hired ")]
                                          ),
                                          _c(
                                            "h5",
                                            {
                                              staticClass: "mb-0",
                                              staticStyle: {
                                                "margin-top": "10px",
                                                "font-family": "Mulish",
                                                "font-style": "normal",
                                                "font-weight": "normal",
                                                "font-size": "14px",
                                                "line-height": "18px",
                                                color: "#171822"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.user
                                                      .practice_user_employment_information
                                                      ? _vm.ChangeFormateDate(
                                                          _vm.user
                                                            .practice_user_employment_information
                                                            .date_hired
                                                        )
                                                      : "-"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { lg: "6", md: "6" } },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-family": "Mulish",
                                                "font-style": "normal",
                                                "font-weight": "normal",
                                                "font-size": "14px",
                                                "line-height": "18px",
                                                color: "rgba(23, 24, 34, 0.6)"
                                              }
                                            },
                                            [_vm._v(" Employment Type ")]
                                          ),
                                          _c(
                                            "h5",
                                            {
                                              staticClass: "mb-0",
                                              staticStyle: {
                                                "margin-top": "10px",
                                                "font-family": "Mulish",
                                                "font-style": "normal",
                                                "font-weight": "normal",
                                                "font-size": "14px",
                                                "line-height": "18px",
                                                color: "#171822"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.user
                                                      .practice_user_employment_information
                                                      ? _vm.user
                                                          .practice_user_employment_information
                                                          .employment_type
                                                      : "-"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { lg: "6", md: "6" } },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-family": "Mulish",
                                                "font-style": "normal",
                                                "font-weight": "normal",
                                                "font-size": "14px",
                                                "line-height": "18px",
                                                color: "rgba(23, 24, 34, 0.6)"
                                              }
                                            },
                                            [_vm._v(" Salary ")]
                                          ),
                                          _c(
                                            "h5",
                                            {
                                              staticClass: "mb-0",
                                              staticStyle: {
                                                "margin-top": "10px",
                                                "font-family": "Mulish",
                                                "font-style": "normal",
                                                "font-weight": "normal",
                                                "font-size": "14px",
                                                "line-height": "18px",
                                                color: "#171822"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.user
                                                      .practice_user_employment_information
                                                      ? "£ " +
                                                          new Intl.NumberFormat().format(
                                                            _vm.user
                                                              .practice_user_employment_information
                                                              .salary
                                                          )
                                                      : "-"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-col",
                                        { attrs: { lg: "6", md: "6" } },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-family": "Mulish",
                                                "font-style": "normal",
                                                "font-weight": "normal",
                                                "font-size": "14px",
                                                "line-height": "18px",
                                                color: "rgba(23, 24, 34, 0.6)"
                                              }
                                            },
                                            [_vm._v(" DBS Status ")]
                                          ),
                                          _c(
                                            "h5",
                                            {
                                              staticClass: "mb-0",
                                              staticStyle: {
                                                "margin-top": "10px",
                                                "font-family": "Mulish",
                                                "font-style": "normal",
                                                "font-weight": "normal",
                                                "font-size": "14px",
                                                "line-height": "18px",
                                                color: "#171822"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.user
                                                      .practice_user_employment_information
                                                      ? _vm.user
                                                          .practice_user_employment_information
                                                          .dbs_status
                                                      : "-"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    { staticClass: "mt-2" },
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { lg: "12", md: "6" } },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-family": "Mulish",
                                                "font-style": "normal",
                                                "font-weight": "normal",
                                                "font-size": "14px",
                                                "line-height": "18px",
                                                color: "rgba(23, 24, 34, 0.6)"
                                              }
                                            },
                                            [_vm._v(" DBS Renewal Date ")]
                                          ),
                                          _c(
                                            "h5",
                                            {
                                              staticStyle: {
                                                "margin-top": "10px",
                                                "font-family": "Mulish",
                                                "font-style": "normal",
                                                "font-weight": "normal",
                                                "font-size": "14px",
                                                "line-height": "18px",
                                                color: "#171822"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.user
                                                      .practice_user_employment_information
                                                      ? _vm.ChangeFormateDate(
                                                          _vm.user
                                                            .practice_user_employment_information
                                                            .dbs_renewal_date
                                                        )
                                                      : "-"
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("b-col", { attrs: { lg: "6", md: "6" } }, [
                                _c("div", [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "normal",
                                        "font-size": "14px",
                                        "line-height": "18px",
                                        color: "rgba(23, 24, 34, 0.6)"
                                      }
                                    },
                                    [_vm._v(" Employment Contract ")]
                                  )
                                ]),
                                _vm.user.practice_user_employment_information &&
                                _vm.user.practice_user_employment_information
                                  .practiceusercontactdocument
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "ml-0 mb-2",
                                        staticStyle: { "margin-top": "5px" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.user
                                                .practice_user_employment_information
                                                .practiceusercontactdocument
                                                .name
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _c(
                                      "h5",
                                      {
                                        staticClass: "mb-0",
                                        staticStyle: {
                                          "margin-top": "20px",
                                          "font-family": "Mulish",
                                          "font-style": "normal",
                                          "font-weight": "normal",
                                          "font-size": "14px",
                                          "line-height": "18px"
                                        }
                                      },
                                      [
                                        _c("feather-icon", {
                                          staticStyle: {
                                            color: "#1B9AAA",
                                            width: "20px",
                                            height: "20px"
                                          },
                                          attrs: { icon: "FilePlusIcon" }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "ml-1",
                                            staticStyle: {
                                              "font-family": "Mulish",
                                              "font-style": "normal",
                                              "font-weight": "normal",
                                              "font-size": "14px",
                                              color: "#1B9AAA"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " Upload Employment Contract "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    ),
                                _c("div", { staticClass: "mt-3" }, [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "normal",
                                        "font-size": "14px",
                                        "line-height": "18px",
                                        color: "rgba(23, 24, 34, 0.6)"
                                      }
                                    },
                                    [_vm._v(" Job Specification ")]
                                  )
                                ]),
                                _vm.user.practice_user_employment_information &&
                                _vm.user.practice_user_employment_information
                                  .practiceuserjob
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "ml-0",
                                        staticStyle: { "margin-top": "5px" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.user
                                                .practice_user_employment_information
                                                .practiceuserjob.name
                                            ) +
                                            " "
                                        )
                                      ]
                                    )
                                  : _c(
                                      "h5",
                                      {
                                        staticClass: "mb-0",
                                        staticStyle: {
                                          "margin-top": "20px",
                                          "font-family": "Mulish",
                                          "font-style": "normal",
                                          "font-weight": "normal",
                                          "font-size": "14px",
                                          "line-height": "18px"
                                        }
                                      },
                                      [
                                        _c("feather-icon", {
                                          staticStyle: {
                                            color: "#1B9AAA",
                                            width: "20px",
                                            height: "20px"
                                          },
                                          attrs: { icon: "FilePlusIcon" }
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "ml-1",
                                            staticStyle: {
                                              "font-family": "Mulish",
                                              "font-style": "normal",
                                              "font-weight": "normal",
                                              "font-size": "14px",
                                              color: "#1B9AAA"
                                            }
                                          },
                                          [_vm._v(" Upload Job Specification ")]
                                        )
                                      ],
                                      1
                                    )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          this.openEditEmployInfo === true
            ? _c(
                "b-col",
                { attrs: { lg: "7", md: "6" } },
                [
                  _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        { staticClass: "border-bottom pb-1" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row" },
                            [
                              _c(
                                "b-avatar",
                                {
                                  attrs: {
                                    rounded: "",
                                    variant: "light-primary"
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "text-dark font-weight-700",
                                    attrs: { icon: "FileTextIcon", size: "18" }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: { "padding-top": "8px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "bold",
                                        "font-size": "20px",
                                        "line-height": "25px",
                                        color: "#171822"
                                      }
                                    },
                                    [_vm._v(" Employment Info ")]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c("div", { staticClass: "ml-auto" }, [
                            _c("span", { staticStyle: { color: "#EFA12C" } }, [
                              _vm._v("•")
                            ]),
                            _c(
                              "span",
                              { staticStyle: { "margin-left": "5px" } },
                              [_vm._v("Editing")]
                            )
                          ])
                        ]
                      ),
                      _c(
                        "b-card-body",
                        [
                          _c(
                            "b-row",
                            { staticClass: "match-height mt-2" },
                            [
                              _c(
                                "b-col",
                                { attrs: { lg: "6", md: "6" } },
                                [
                                  _c(
                                    "validation-observer",
                                    { ref: "EditPersonalInfoForm" },
                                    [
                                      _c(
                                        "b-form",
                                        {
                                          on: {
                                            submit: function($event) {
                                              $event.preventDefault()
                                            }
                                          }
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "mr-2 ml-2" },
                                            [
                                              _c(
                                                "b-row",
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "12"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-group",
                                                        {
                                                          attrs: {
                                                            label: "Date hired",
                                                            "label-for":
                                                              "Date hired"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "validation-provider",
                                                            {
                                                              attrs: {
                                                                name:
                                                                  "Date hired",
                                                                rules: ""
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var errors =
                                                                        ref.errors
                                                                      return [
                                                                        _c(
                                                                          "b-input-group",
                                                                          {
                                                                            staticClass:
                                                                              "input-group-merge"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b-input-group-prepend",
                                                                              {
                                                                                attrs: {
                                                                                  "is-text":
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "feather-icon",
                                                                                  {
                                                                                    attrs: {
                                                                                      icon:
                                                                                        "CalendarIcon"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "flat-pickr",
                                                                              {
                                                                                staticClass:
                                                                                  "form-control",
                                                                                attrs: {
                                                                                  config: {
                                                                                    enableTime: false,
                                                                                    noCalendar: false,
                                                                                    dateFormat:
                                                                                      "d-m-Y"
                                                                                  },
                                                                                  placeholder:
                                                                                    "Select hired date"
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    _vm
                                                                                      .form
                                                                                      .date_hired,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.form,
                                                                                      "date_hired",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "form.date_hired"
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "small",
                                                                          {
                                                                            staticClass:
                                                                              "text-danger"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                errors[0]
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                false,
                                                                2030605794
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "12"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-group",
                                                        {
                                                          attrs: {
                                                            label:
                                                              "Employment type",
                                                            "label-for":
                                                              "h-Preferred_pronouns"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "validation-provider",
                                                            {
                                                              attrs: {
                                                                name:
                                                                  "Employment type",
                                                                rules:
                                                                  "required"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var errors =
                                                                        ref.errors
                                                                      return [
                                                                        _c(
                                                                          "b-input-group",
                                                                          {
                                                                            staticClass:
                                                                              "input-group-merge"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-select",
                                                                              {
                                                                                staticClass:
                                                                                  "w-100",
                                                                                attrs: {
                                                                                  reduce: function(
                                                                                    title
                                                                                  ) {
                                                                                    return title.value
                                                                                  },
                                                                                  options: [
                                                                                    {
                                                                                      title:
                                                                                        "Full time",
                                                                                      value:
                                                                                        "Full time"
                                                                                    },
                                                                                    {
                                                                                      title:
                                                                                        "Part time",
                                                                                      value:
                                                                                        "Part time"
                                                                                    },
                                                                                    {
                                                                                      title:
                                                                                        "Casual",
                                                                                      value:
                                                                                        "Casual"
                                                                                    },
                                                                                    {
                                                                                      title:
                                                                                        "AARS",
                                                                                      value:
                                                                                        "AARS"
                                                                                    }
                                                                                  ],
                                                                                  label:
                                                                                    "title",
                                                                                  placeholder:
                                                                                    "Employment type"
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    _vm
                                                                                      .form
                                                                                      .employment_type,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.form,
                                                                                      "employment_type",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "form.employment_type"
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "small",
                                                                          {
                                                                            staticClass:
                                                                              "text-danger"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                errors[0]
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                false,
                                                                3362508993
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "12"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {},
                                                        [
                                                          _c(
                                                            "b-form-group",
                                                            {
                                                              staticClass:
                                                                "input-group-merge",
                                                              attrs: {
                                                                label: "Salary",
                                                                "label-for":
                                                                  "home-Salary"
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "validation-provider",
                                                                {
                                                                  attrs: {
                                                                    name:
                                                                      "Salary",
                                                                    rules: ""
                                                                  },
                                                                  scopedSlots: _vm._u(
                                                                    [
                                                                      {
                                                                        key:
                                                                          "default",
                                                                        fn: function(
                                                                          ref
                                                                        ) {
                                                                          var errors =
                                                                            ref.errors
                                                                          return [
                                                                            _c(
                                                                              "b-input-group",
                                                                              {
                                                                                staticClass:
                                                                                  "input-group-merge"
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "b-input-group-prepend",
                                                                                  {
                                                                                    attrs: {
                                                                                      "is-text":
                                                                                        ""
                                                                                    }
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      " £ "
                                                                                    )
                                                                                  ]
                                                                                ),
                                                                                _c(
                                                                                  "b-form-input",
                                                                                  {
                                                                                    attrs: {
                                                                                      id:
                                                                                        "home-address",
                                                                                      placeholder:
                                                                                        "Enter Salary",
                                                                                      type:
                                                                                        "number"
                                                                                    },
                                                                                    model: {
                                                                                      value:
                                                                                        _vm
                                                                                          .form
                                                                                          .salary,
                                                                                      callback: function(
                                                                                        $$v
                                                                                      ) {
                                                                                        _vm.$set(
                                                                                          _vm.form,
                                                                                          "salary",
                                                                                          $$v
                                                                                        )
                                                                                      },
                                                                                      expression:
                                                                                        "form.salary"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "small",
                                                                              {
                                                                                staticClass:
                                                                                  "text-danger"
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  _vm._s(
                                                                                    errors[0]
                                                                                  )
                                                                                )
                                                                              ]
                                                                            )
                                                                          ]
                                                                        }
                                                                      }
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    3174038506
                                                                  )
                                                                }
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "12"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-group",
                                                        {
                                                          attrs: {
                                                            label: "DBS status",
                                                            "label-for":
                                                              "h-DBS status"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "validation-provider",
                                                            {
                                                              attrs: {
                                                                name:
                                                                  "DBS status",
                                                                rules:
                                                                  "required"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var errors =
                                                                        ref.errors
                                                                      return [
                                                                        _c(
                                                                          "b-input-group",
                                                                          {
                                                                            staticClass:
                                                                              "input-group-merge"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "v-select",
                                                                              {
                                                                                staticClass:
                                                                                  "w-100",
                                                                                attrs: {
                                                                                  reduce: function(
                                                                                    title
                                                                                  ) {
                                                                                    return title.value
                                                                                  },
                                                                                  options: [
                                                                                    {
                                                                                      title:
                                                                                        "Not started",
                                                                                      value:
                                                                                        "Not started"
                                                                                    },
                                                                                    {
                                                                                      title:
                                                                                        "In progress",
                                                                                      value:
                                                                                        "In progress"
                                                                                    },
                                                                                    {
                                                                                      title:
                                                                                        "Completed",
                                                                                      value:
                                                                                        "Completed"
                                                                                    }
                                                                                  ],
                                                                                  label:
                                                                                    "title",
                                                                                  placeholder:
                                                                                    "Select DBS status"
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    _vm
                                                                                      .form
                                                                                      .dbs_status,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.form,
                                                                                      "dbs_status",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "form.dbs_status"
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "small",
                                                                          {
                                                                            staticClass:
                                                                              "text-danger"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                errors[0]
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                false,
                                                                2679604162
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "12"
                                                      }
                                                    },
                                                    [
                                                      _c(
                                                        "b-form-group",
                                                        {
                                                          attrs: {
                                                            label:
                                                              "DBS renewal Date ",
                                                            "label-for":
                                                              "h-DBS renewal Date"
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "validation-provider",
                                                            {
                                                              attrs: {
                                                                name:
                                                                  "DBS renewal Date",
                                                                rules:
                                                                  "required"
                                                              },
                                                              scopedSlots: _vm._u(
                                                                [
                                                                  {
                                                                    key:
                                                                      "default",
                                                                    fn: function(
                                                                      ref
                                                                    ) {
                                                                      var errors =
                                                                        ref.errors
                                                                      return [
                                                                        _c(
                                                                          "b-input-group",
                                                                          {
                                                                            staticClass:
                                                                              "input-group-merge"
                                                                          },
                                                                          [
                                                                            _c(
                                                                              "b-input-group-prepend",
                                                                              {
                                                                                attrs: {
                                                                                  "is-text":
                                                                                    ""
                                                                                }
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "feather-icon",
                                                                                  {
                                                                                    attrs: {
                                                                                      icon:
                                                                                        "CalendarIcon"
                                                                                    }
                                                                                  }
                                                                                )
                                                                              ],
                                                                              1
                                                                            ),
                                                                            _c(
                                                                              "flat-pickr",
                                                                              {
                                                                                staticClass:
                                                                                  "form-control",
                                                                                attrs: {
                                                                                  config: {
                                                                                    enableTime: false,
                                                                                    noCalendar: false,
                                                                                    dateFormat:
                                                                                      "d-m-Y"
                                                                                  },
                                                                                  placeholder:
                                                                                    "Select DBS renewal Date "
                                                                                },
                                                                                model: {
                                                                                  value:
                                                                                    _vm
                                                                                      .form
                                                                                      .dbs_renewal_date,
                                                                                  callback: function(
                                                                                    $$v
                                                                                  ) {
                                                                                    _vm.$set(
                                                                                      _vm.form,
                                                                                      "dbs_renewal_date",
                                                                                      $$v
                                                                                    )
                                                                                  },
                                                                                  expression:
                                                                                    "form.dbs_renewal_date"
                                                                                }
                                                                              }
                                                                            )
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _c(
                                                                          "small",
                                                                          {
                                                                            staticClass:
                                                                              "text-danger"
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                errors[0]
                                                                              )
                                                                            )
                                                                          ]
                                                                        )
                                                                      ]
                                                                    }
                                                                  }
                                                                ],
                                                                null,
                                                                false,
                                                                2732081085
                                                              )
                                                            }
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      staticClass: "mt-2",
                                                      attrs: { md: "12" }
                                                    },
                                                    [
                                                      _c(
                                                        "b-button",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "ripple",
                                                              rawName:
                                                                "v-ripple.400",
                                                              value:
                                                                "rgba(255, 255, 255, 0.15)",
                                                              expression:
                                                                "'rgba(255, 255, 255, 0.15)'",
                                                              modifiers: {
                                                                "400": true
                                                              }
                                                            }
                                                          ],
                                                          staticClass: "mr-1",
                                                          attrs: {
                                                            type: "submit",
                                                            variant: "primary"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.updateEmploymentInfo()
                                                            }
                                                          }
                                                        },
                                                        [_vm._v(" Save ")]
                                                      ),
                                                      _c(
                                                        "b-button",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "ripple",
                                                              rawName:
                                                                "v-ripple.400",
                                                              value:
                                                                "rgba(186, 191, 199, 0.15)",
                                                              expression:
                                                                "'rgba(186, 191, 199, 0.15)'",
                                                              modifiers: {
                                                                "400": true
                                                              }
                                                            }
                                                          ],
                                                          staticClass:
                                                            "text-primary ml-1 shadow",
                                                          attrs: {
                                                            type: "button",
                                                            variant:
                                                              "outline-white"
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              _vm.openEditEmployInfo = false
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text-primary"
                                                            },
                                                            [_vm._v(" Cancel ")]
                                                          )
                                                        ]
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c("b-col", { attrs: { lg: "6", md: "6" } }, [
                                _c(
                                  "span",
                                  {
                                    staticClass: "mb-1",
                                    staticStyle: {
                                      "font-family": "Mulish",
                                      "font-style": "normal",
                                      "font-weight": "normal",
                                      "font-size": "14px",
                                      "line-height": "18px",
                                      color: "rgba(23, 24, 34, 0.6)"
                                    }
                                  },
                                  [_vm._v(" Employment Contract ")]
                                ),
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        return _vm.openUploadEmploymentContactPopup()
                                      }
                                    }
                                  },
                                  [
                                    _c("feather-icon", {
                                      staticStyle: {
                                        color: "#1B9AAA",
                                        width: "20px",
                                        height: "20px"
                                      },
                                      attrs: { icon: "FilePlusIcon" }
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "ml-1",
                                        staticStyle: {
                                          "font-family": "Mulish",
                                          "font-style": "normal",
                                          "font-weight": "normal",
                                          "font-size": "14px",
                                          color: "#1B9AAA"
                                        }
                                      },
                                      [_vm._v(" Upload Employment Contract ")]
                                    )
                                  ],
                                  1
                                ),
                                _vm.uploadedContactDocument.name
                                  ? _c("div", { staticClass: "ml-3" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.uploadedContactDocument.name
                                          ) +
                                          " "
                                      )
                                    ])
                                  : _vm._e(),
                                _c(
                                  "span",
                                  {
                                    staticClass: "mt-3 mb-1",
                                    staticStyle: {
                                      "font-family": "Mulish",
                                      "font-style": "normal",
                                      "font-weight": "normal",
                                      "font-size": "14px",
                                      "line-height": "18px",
                                      color: "rgba(23, 24, 34, 0.6)"
                                    }
                                  },
                                  [_vm._v(" Job Specification ")]
                                ),
                                _c(
                                  "a",
                                  {
                                    attrs: { href: "#" },
                                    on: {
                                      click: function($event) {
                                        return _vm.openUploadJobPopup()
                                      }
                                    }
                                  },
                                  [
                                    _c("feather-icon", {
                                      staticStyle: {
                                        color: "#1B9AAA",
                                        width: "20px",
                                        height: "20px"
                                      },
                                      attrs: { icon: "FilePlusIcon" }
                                    }),
                                    _c(
                                      "span",
                                      {
                                        staticClass: "ml-1",
                                        staticStyle: {
                                          "font-family": "Mulish",
                                          "font-style": "normal",
                                          "font-weight": "normal",
                                          "font-size": "14px",
                                          color: "#1B9AAA"
                                        }
                                      },
                                      [_vm._v(" Upload Job Specification ")]
                                    )
                                  ],
                                  1
                                ),
                                _vm.uploadedJobSpecificationDocument.name
                                  ? _c("div", { staticClass: "ml-3" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.uploadedJobSpecificationDocument
                                              .name
                                          ) +
                                          " "
                                      )
                                    ])
                                  : _vm._e()
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c("UploadCertificate", {
        ref: "uploadEmploymentContact",
        on: { getContactDocument: _vm.getContactDocument }
      }),
      _c("UploadJobSpecification", {
        ref: "uploadJobSpecification",
        on: { getJobSpecificationDocument: _vm.getJobSpecificationDocument }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }