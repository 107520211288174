var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "match-height" },
        [
          _c(
            "b-col",
            { attrs: { lg: "6", md: "6" } },
            [
              _c(
                "b-overlay",
                { attrs: { show: _vm.loadDocument, rounded: "sm" } },
                [
                  _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        { staticClass: "border-bottom pb-1" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row" },
                            [
                              _c(
                                "b-avatar",
                                {
                                  attrs: {
                                    rounded: "",
                                    variant: "light-primary"
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "text-dark font-weight-700",
                                    attrs: { icon: "FileIcon", size: "18" }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: { "padding-top": "8px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "bold",
                                        "font-size": "20px",
                                        "line-height": "25px",
                                        color: "#171822"
                                      }
                                    },
                                    [_vm._v(" Documents ")]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "ml-auto" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass:
                                    "btn-icon bg-white shadow-sm font-weight-bold",
                                  attrs: { variant: "white" },
                                  on: {
                                    click: function($event) {
                                      return _vm.openDocumentSideBar()
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        value: "Add Documents",
                                        expression: "'Add Documents'",
                                        modifiers: { hover: true, top: true }
                                      }
                                    ],
                                    staticClass: "text-primary",
                                    attrs: { icon: "PlusIcon", size: "20" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c("b-card-body", [
                        _vm.getDocuments.length > 0
                          ? _c(
                              "div",
                              _vm._l(_vm.getDocuments, function(item, index) {
                                return _c("div", { key: index }, [
                                  _c(
                                    "div",
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            height: "40px",
                                            "background-color": "#ebf5fb",
                                            "margin-top": "20px"
                                          }
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "font-family": "Mulish",
                                                "font-style": "normal",
                                                "font-weight": "bold",
                                                "font-size": "12px",
                                                "line-height": "15px",
                                                "text-transform": "uppercase",
                                                color: "#171822"
                                              }
                                            },
                                            [
                                              _c("b-row", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "ml-2 mt-1",
                                                    staticStyle: {
                                                      "padding-bottom": "5px",
                                                      "font-family": "Mulish",
                                                      "font-style": "normal",
                                                      "font-weight": "bold",
                                                      "font-size": "12px",
                                                      "line-height": "15px",
                                                      "text-transform":
                                                        "uppercase",
                                                      color: "#171822"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " + _vm._s(item.type)
                                                    )
                                                  ]
                                                )
                                              ])
                                            ],
                                            1
                                          )
                                        ]
                                      ),
                                      _c(
                                        "b-row",
                                        [
                                          _c(
                                            "b-media",
                                            { attrs: { "no-body": "" } },
                                            [
                                              _c(
                                                "span",
                                                { staticClass: "ml-2 mt-2" },
                                                [
                                                  item.file_type ===
                                                  "application/pdf"
                                                    ? _c("b-img", {
                                                        staticClass: "mr-1",
                                                        attrs: {
                                                          src: require("@/assets/images/icons/file-icons/pdf.png"),
                                                          alt: "browser img",
                                                          height: "30"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  item.file_type ===
                                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                    ? _c("b-img", {
                                                        staticClass: "mr-1",
                                                        attrs: {
                                                          src: require("@/assets/images/icons/file-icons/docx.png"),
                                                          alt: "browser img",
                                                          height: "30"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  item.file_type ===
                                                  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                                    ? _c("b-img", {
                                                        staticClass: "mr-1",
                                                        attrs: {
                                                          src: require("@/assets/images/icons/file-icons/excel.png"),
                                                          alt: "browser img",
                                                          height: "30"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  item.file_type ===
                                                  "application/msword"
                                                    ? _c("b-img", {
                                                        staticClass: "mr-1",
                                                        attrs: {
                                                          src: require("@/assets/images/icons/file-icons/excel.png"),
                                                          alt: "browser img",
                                                          height: "30"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  item.file_type ===
                                                  "application/vnd.ms-excel"
                                                    ? _c("b-img", {
                                                        staticClass: "mr-1",
                                                        attrs: {
                                                          src: require("@/assets/images/icons/file-icons/excel.png"),
                                                          alt: "browser img",
                                                          height: "30"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  item.file_type === "image/png"
                                                    ? _c("b-img", {
                                                        staticClass: "mr-1",
                                                        attrs: {
                                                          src: require("@/assets/images/icons/file-icons/png.png"),
                                                          alt: "browser img",
                                                          height: "30"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  item.file_type ===
                                                  "image/jpeg"
                                                    ? _c("b-img", {
                                                        staticClass: "mr-1",
                                                        attrs: {
                                                          src: require("@/assets/images/icons/file-icons/jpg.png"),
                                                          alt: "browser img",
                                                          height: "30"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  item.file_type ===
                                                  "application/xls"
                                                    ? _c("b-img", {
                                                        staticClass: "mr-1",
                                                        attrs: {
                                                          src: require("@/assets/images/icons/file-icons/xls.png"),
                                                          alt: "browser img",
                                                          height: "30"
                                                        }
                                                      })
                                                    : _vm._e()
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              staticClass: "mt-1",
                                              staticStyle: {
                                                "font-family": "Mulish",
                                                "font-style": "normal",
                                                "font-weight": "normal",
                                                "font-size": "14px",
                                                "line-height": "21px",
                                                color: "#171822"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(item.name) + " "
                                              ),
                                              _c("br"),
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-family": "Mulish",
                                                    "font-style": "normal",
                                                    "font-weight": "normal",
                                                    "font-size": "12px",
                                                    "line-height": "15px",
                                                    color: "#949494"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " Uploaded by " +
                                                      _vm._s(item.updated_by) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            ]
                                          ),
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "ripple",
                                                  rawName: "v-ripple.400",
                                                  value:
                                                    "rgba(186, 191, 199, 0.15)",
                                                  expression:
                                                    "'rgba(186, 191, 199, 0.15)'",
                                                  modifiers: { "400": true }
                                                }
                                              ],
                                              staticClass:
                                                "text-primary ml-auto mt-1 mr-1",
                                              attrs: { type: "reset" }
                                            },
                                            [
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "btn-icon font-weight-bold",
                                                  attrs: { variant: "white" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.downloadDocumentFile(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("feather-icon", {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover.top",
                                                        value: "Download",
                                                        expression:
                                                          "'Download'",
                                                        modifiers: {
                                                          hover: true,
                                                          top: true
                                                        }
                                                      }
                                                    ],
                                                    staticClass:
                                                      "text-secondary",
                                                    attrs: {
                                                      icon: "DownloadIcon",
                                                      size: "20"
                                                    }
                                                  })
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-button",
                                                {
                                                  staticClass:
                                                    "btn-icon font-weight-bold",
                                                  attrs: { variant: "white" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.deleteFile(
                                                        item
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _c("feather-icon", {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover.top",
                                                        value: "Delete",
                                                        expression: "'Delete'",
                                                        modifiers: {
                                                          hover: true,
                                                          top: true
                                                        }
                                                      }
                                                    ],
                                                    staticClass:
                                                      "text-secondary",
                                                    attrs: {
                                                      icon: "TrashIcon",
                                                      size: "20"
                                                    }
                                                  })
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ])
                              }),
                              0
                            )
                          : _c("div", { staticClass: "text-center mt-4" }, [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "100",
                                    height: "100",
                                    viewBox: "0 0 50 50",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d:
                                        "M25 0C38.8072 0 50 11.1928 50 25C50 38.8072 38.8072 50 25 50C11.1928 50 0 38.8072 0 25C0 11.1928 11.1928 0 25 0Z",
                                      fill: "#CCE6F4"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d:
                                        "M34.8938 12.5996C35.6281 12.5996 36.2235 13.1951 36.2235 13.9294C36.2235 14.6637 35.6281 15.2592 34.8938 15.2592C34.1592 15.2592 33.564 14.6637 33.564 13.9294C33.564 13.1951 34.1592 12.5996 34.8938 12.5996Z",
                                      fill: "white"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d:
                                        "M38.6171 8.34375C39.6453 8.34375 40.4788 9.17726 40.4788 10.2055C40.4788 11.2336 39.6453 12.0672 38.6171 12.0672C37.5889 12.0672 36.7554 11.2336 36.7554 10.2055C36.7554 9.17726 37.5889 8.34375 38.6171 8.34375Z",
                                      fill: "white"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d:
                                        "M33.0319 7.8125C33.3258 7.8125 33.5638 8.05053 33.5638 8.34441C33.5638 8.6383 33.3258 8.87633 33.0319 8.87633C32.738 8.87633 32.5 8.6383 32.5 8.34441C32.5 8.05053 32.738 7.8125 33.0319 7.8125Z",
                                      fill: "white"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M34.701 20.1504C32.7913 20.1504 31.2363 21.7053 31.2363 23.615C31.2363 25.5247 32.7913 27.0797 34.701 27.0797C36.6107 27.0797 38.1656 25.5247 38.1656 23.615C38.1656 21.7053 36.6107 20.1504 34.701 20.1504ZM34.701 25.6938C33.5548 25.6938 32.6222 24.7612 32.6222 23.615C32.6222 22.4689 33.5548 21.5362 34.701 21.5362C35.8471 21.5362 36.7798 22.4689 36.7798 23.615C36.7798 24.7612 35.847 25.6938 34.701 25.6938Z",
                                      fill: "black"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M15.3975 20.1504C13.4864 20.1504 11.9329 21.7053 11.9329 23.615C11.9329 25.5247 13.4878 27.0797 15.3975 27.0797C17.3072 27.0797 18.8622 25.5247 18.8622 23.615C18.8622 21.7053 17.3086 20.1504 15.3975 20.1504ZM15.3975 25.6938C14.2514 25.6938 13.3187 24.7612 13.3187 23.615C13.3187 22.4689 14.2514 21.5362 15.3975 21.5362C16.5436 21.5362 17.4763 22.4689 17.4763 23.615C17.4763 24.7612 16.5436 25.6938 15.3975 25.6938Z",
                                      fill: "black"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M34.7007 28.4648C33.5601 28.4648 32.4431 28.7767 31.4702 29.3657C31.1445 29.5652 31.0392 29.9907 31.2374 30.3178C31.4383 30.6449 31.8624 30.7488 32.1895 30.5506C32.9447 30.0918 33.8123 29.8507 34.7007 29.8507C37.3754 29.8507 39.5512 32.0265 39.5512 34.7013C39.5512 35.0838 39.8617 35.3942 40.2442 35.3942C40.6267 35.3942 40.9372 35.0838 40.9372 34.7013C40.9371 31.2629 38.139 28.4648 34.7007 28.4648Z",
                                      fill: "black"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M18.528 29.3643C17.5565 28.7767 16.4395 28.4648 15.2989 28.4648C11.8606 28.4648 9.0625 31.2629 9.0625 34.7013C9.0625 35.0838 9.37296 35.3942 9.75546 35.3942C10.138 35.3942 10.4484 35.0838 10.4484 34.7013C10.4483 32.0265 12.6242 29.8507 15.2989 29.8507C16.1873 29.8507 17.0548 30.0918 17.8087 30.5492C18.1344 30.7474 18.5613 30.6434 18.7608 30.3163C18.9604 29.9893 18.855 29.5638 18.528 29.3643Z",
                                      fill: "black"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M24.9999 25.6934C20.0329 25.6934 15.9917 29.7345 15.9917 34.7015C15.9917 35.084 16.3022 35.3945 16.6847 35.3945C17.0672 35.3945 17.3776 35.084 17.3776 34.7015C17.3776 30.4982 20.7965 27.0793 24.9999 27.0793C29.2032 27.0793 32.6221 30.4982 32.6221 34.7015C32.6221 35.084 32.9326 35.3945 33.3151 35.3945C33.6976 35.3945 34.008 35.084 34.008 34.7015C34.008 29.7345 29.9668 25.6934 24.9999 25.6934Z",
                                      fill: "#1B9AAA"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M25 14.6055C22.3253 14.6055 20.1494 16.7813 20.1494 19.456C20.1494 22.1308 22.3252 24.3066 25 24.3066C27.6747 24.3066 29.8505 22.1307 29.8505 19.456C29.8505 16.7813 27.6747 14.6055 25 14.6055ZM25 22.9207C23.0903 22.9207 21.5353 21.3657 21.5353 19.456C21.5353 17.5463 23.0903 15.9914 25 15.9914C26.9097 15.9914 28.4646 17.5463 28.4646 19.456C28.4646 21.3657 26.9097 22.9207 25 22.9207Z",
                                      fill: "#1B9AAA"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z",
                                      fill: "#1B9AAA",
                                      stroke: "#1B9AAA",
                                      "stroke-width": "0.5"
                                    }
                                  }),
                                  _c(
                                    "mask",
                                    {
                                      staticStyle: { "mask-type": "alpha" },
                                      attrs: {
                                        id: "mask0_1872_13634",
                                        maskUnits: "userSpaceOnUse",
                                        x: "14",
                                        y: "11",
                                        width: "6",
                                        height: "5"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z",
                                          fill: "white"
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "g",
                                    {
                                      attrs: { mask: "url(#mask0_1872_13634)" }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z",
                                          fill: "#1B9AAA"
                                        }
                                      }),
                                      _c(
                                        "mask",
                                        {
                                          staticStyle: { "mask-type": "alpha" },
                                          attrs: {
                                            id: "mask1_1872_13634",
                                            maskUnits: "userSpaceOnUse",
                                            x: "14",
                                            y: "11",
                                            width: "6",
                                            height: "5"
                                          }
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d:
                                                "M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z",
                                              fill: "white"
                                            }
                                          })
                                        ]
                                      ),
                                      _c("g", {
                                        attrs: {
                                          mask: "url(#mask1_1872_13634)"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _c("h5", { staticClass: "mt-2" }, [
                                _vm._v("No Documents")
                              ]),
                              _c("div", { staticStyle: { height: "100px" } })
                            ])
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-modal",
                    {
                      attrs: {
                        id: "modal-center",
                        centered: "",
                        "hide-footer": "",
                        "no-close-on-backdrop": "",
                        size: "lg",
                        title: "Upload Document"
                      },
                      model: {
                        value: _vm.showFileUploadModal,
                        callback: function($$v) {
                          _vm.showFileUploadModal = $$v
                        },
                        expression: "showFileUploadModal"
                      }
                    },
                    [
                      _c(
                        "b-overlay",
                        { attrs: { show: _vm.modalLoading, rounded: "sm" } },
                        [
                          _c(
                            "b-row",
                            { staticClass: "mx-1" },
                            [
                              _c(
                                "b-col",
                                { staticClass: "mt-2", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "m-auto",
                                      staticStyle: {
                                        width: "100px",
                                        height: "100px"
                                      }
                                    },
                                    [
                                      _c(
                                        "svg",
                                        {
                                          staticStyle: {
                                            "enable-background":
                                              "new 0 0 512 512"
                                          },
                                          attrs: {
                                            id: "Layer_1",
                                            version: "1.1",
                                            viewBox: "0 0 512 512",
                                            x: "0px",
                                            "xml:space": "preserve",
                                            xmlns: "http://www.w3.org/2000/svg",
                                            "xmlns:xlink":
                                              "http://www.w3.org/1999/xlink",
                                            y: "0px"
                                          }
                                        },
                                        [
                                          _c("rect", {
                                            staticStyle: { fill: "#32bea6" },
                                            attrs: {
                                              height: "512",
                                              width: "512",
                                              y: "0"
                                            }
                                          }),
                                          _c("g", [
                                            _c("polygon", {
                                              staticStyle: { fill: "#ffffff" },
                                              attrs: {
                                                points:
                                                  "182.76,229.728 232.704,178.808 232.704,301.688 279.296,301.688 279.296,178.808\n\t\t329.24,229.728 362.488,197.288 256.008,88.72 149.512,197.288 \t"
                                              }
                                            }),
                                            _c("polygon", {
                                              staticStyle: { fill: "#ffffff" },
                                              attrs: {
                                                points:
                                                  "395.528,421.864 116.472,421.864 116.472,301.088 132.472,301.088 132.472,405.864\n\t\t379.528,405.864 379.528,301.088 395.528,301.088 \t"
                                              }
                                            })
                                          ]),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g"),
                                          _c("g")
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mt-3", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-overlay",
                                    {
                                      attrs: {
                                        show: _vm.buttonLoading,
                                        rounded: "sm"
                                      }
                                    },
                                    [
                                      _c("v-select", {
                                        staticClass: "mb-2",
                                        attrs: {
                                          options: _vm.fileTypeOption,
                                          reduce: function(fileTypeOption) {
                                            return fileTypeOption.id
                                          },
                                          label: "name",
                                          placeholder: "Select Document Type",
                                          value: "id"
                                        },
                                        model: {
                                          value: _vm.selected_file_type_id,
                                          callback: function($$v) {
                                            _vm.selected_file_type_id = $$v
                                          },
                                          expression: "selected_file_type_id"
                                        }
                                      }),
                                      _c("b-form-file", {
                                        ref: "image_ref",
                                        attrs: {
                                          "drop-placeholder":
                                            "Drop file here...",
                                          placeholder:
                                            "Choose a file or drop it here..."
                                        },
                                        model: {
                                          value: _vm.uploadedFile,
                                          callback: function($$v) {
                                            _vm.uploadedFile = $$v
                                          },
                                          expression: "uploadedFile"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { staticClass: "mt-5", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "float-right" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          staticClass: "mr-2",
                                          attrs: {
                                            disabled: _vm.isActive,
                                            variant: "primary"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.uploadFile()
                                            }
                                          }
                                        },
                                        [_vm._v(" Upload ")]
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "warning" },
                                          on: {
                                            click: function($event) {
                                              ;(_vm.isActive = true),
                                                (_vm.showFileUploadModal = false)
                                            }
                                          }
                                        },
                                        [_vm._v("Cancel ")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { lg: "6", md: "6" } },
            [
              _c(
                "b-overlay",
                { attrs: { show: _vm.loadingModel, rounded: "sm" } },
                [
                  _c(
                    "b-card",
                    { attrs: { "no-body": "" } },
                    [
                      _c(
                        "b-card-header",
                        { staticClass: "border-bottom pb-1" },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex flex-row" },
                            [
                              _c(
                                "b-avatar",
                                {
                                  attrs: {
                                    rounded: "",
                                    variant: "light-primary"
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    staticClass: "text-dark font-weight-700",
                                    attrs: { icon: "SlashIcon", size: "18" }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "ml-1",
                                  staticStyle: { "padding-top": "8px" }
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        "font-family": "Mulish",
                                        "font-style": "normal",
                                        "font-weight": "bold",
                                        "font-size": "20px",
                                        "line-height": "25px",
                                        color: "#171822"
                                      }
                                    },
                                    [_vm._v(" Mandatory Training ")]
                                  )
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "ml-auto" },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass:
                                    "btn-icon bg-white shadow-sm font-weight-bold",
                                  attrs: { variant: "white" },
                                  on: {
                                    click: function($event) {
                                      return _vm.toggleAddMandatorySideBar()
                                    }
                                  }
                                },
                                [
                                  _c("feather-icon", {
                                    directives: [
                                      {
                                        name: "b-tooltip",
                                        rawName: "v-b-tooltip.hover.top",
                                        value: "Add",
                                        expression: "'Add'",
                                        modifiers: { hover: true, top: true }
                                      }
                                    ],
                                    staticClass: "text-primary",
                                    attrs: { icon: "PlusIcon", size: "20" }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ]
                      ),
                      _c("b-card-body", [
                        _vm.userTrainingLists.length > 0
                          ? _c(
                              "div",
                              _vm._l(_vm.userTrainingLists, function(
                                item,
                                index
                              ) {
                                return _c("div", { key: index }, [
                                  !item.is_expired && !item.is_non_renewal
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                height: "40px",
                                                "background-color": "#ebf5fb",
                                                "margin-top": "20px"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-family": "Mulish",
                                                    "font-style": "normal",
                                                    "font-weight": "bold",
                                                    "font-size": "12px",
                                                    "line-height": "15px",
                                                    "text-transform":
                                                      "uppercase",
                                                    color: "#171822"
                                                  }
                                                },
                                                [
                                                  _c("b-row", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "ml-2 mt-1",
                                                        staticStyle: {
                                                          "padding-bottom":
                                                            "5px",
                                                          "font-family":
                                                            "Mulish",
                                                          "font-style":
                                                            "normal",
                                                          "font-weight": "bold",
                                                          "font-size": "12px",
                                                          "line-height": "15px",
                                                          "text-transform":
                                                            "uppercase",
                                                          color: "#171822"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.training_name
                                                            )
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "ripple",
                                                            rawName:
                                                              "v-ripple.400",
                                                            value:
                                                              "rgba(186, 191, 199, 0.15)",
                                                            expression:
                                                              "'rgba(186, 191, 199, 0.15)'",
                                                            modifiers: {
                                                              "400": true
                                                            }
                                                          }
                                                        ],
                                                        staticClass:
                                                          "text-primary ml-auto mr-2",
                                                        attrs: { type: "reset" }
                                                      },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "btn-icon font-weight-bold",
                                                            attrs: {
                                                              variant: "white"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openEditeForm(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("feather-icon", {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "b-tooltip",
                                                                  rawName:
                                                                    "v-b-tooltip.hover.top",
                                                                  value: "Edit",
                                                                  expression:
                                                                    "'Edit'",
                                                                  modifiers: {
                                                                    hover: true,
                                                                    top: true
                                                                  }
                                                                }
                                                              ],
                                                              staticClass:
                                                                "text-primary",
                                                              attrs: {
                                                                icon:
                                                                  "EditIcon",
                                                                size: "20"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c("feather-icon", {
                                                staticClass: "text-primary m-1",
                                                attrs: {
                                                  icon: "CheckCircleIcon",
                                                  size: "20"
                                                }
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "mt-1",
                                                  staticStyle: {
                                                    "font-family": "Mulish",
                                                    "font-style": "normal",
                                                    "font-weight": "normal",
                                                    "font-size": "14px",
                                                    "line-height": "21px",
                                                    color: "#171822"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " Completed on " +
                                                      _vm._s(
                                                        _vm.momentFormat(
                                                          item.start_date,
                                                          "MMM DD, YYYY"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("br"),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticStyle: {
                                                        "font-family": "Mulish",
                                                        "font-style": "normal",
                                                        "font-weight": "normal",
                                                        "font-size": "12px",
                                                        "line-height": "15px",
                                                        color: "#949494"
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " Expires on " +
                                                          _vm._s(
                                                            _vm.momentFormat(
                                                              item.expire_date,
                                                              "MMM DD, YYYY"
                                                            )
                                                          ) +
                                                          " "
                                                      )
                                                    ]
                                                  )
                                                ]
                                              ),
                                              item.training_certificate
                                                ? _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "ripple",
                                                          rawName:
                                                            "v-ripple.400",
                                                          value:
                                                            "rgba(186, 191, 199, 0.15)",
                                                          expression:
                                                            "'rgba(186, 191, 199, 0.15)'",
                                                          modifiers: {
                                                            "400": true
                                                          }
                                                        }
                                                      ],
                                                      staticClass:
                                                        "text-primary ml-auto mr-2 mt-1",
                                                      attrs: { type: "reset" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.downloadFile(
                                                            item.training_certificate
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " View Credential "
                                                      )
                                                    ]
                                                  )
                                                : _c("span", {
                                                    directives: [
                                                      {
                                                        name: "ripple",
                                                        rawName: "v-ripple.400",
                                                        value:
                                                          "rgba(186, 191, 199, 0.15)",
                                                        expression:
                                                          "'rgba(186, 191, 199, 0.15)'",
                                                        modifiers: {
                                                          "400": true
                                                        }
                                                      }
                                                    ],
                                                    staticClass:
                                                      "text-primary ml-auto mr-2 mt-1",
                                                    attrs: { type: "reset" },
                                                    on: {
                                                      click: _vm.noSuchDocument
                                                    }
                                                  })
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  item.is_expired && !item.is_non_renewal
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                height: "40px",
                                                "background-color": "#ebf5fb",
                                                "margin-top": "20px"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-family": "Mulish",
                                                    "font-style": "normal",
                                                    "font-weight": "bold",
                                                    "font-size": "12px",
                                                    "line-height": "15px",
                                                    "text-transform":
                                                      "uppercase",
                                                    color: "#171822"
                                                  }
                                                },
                                                [
                                                  _c("b-row", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "ml-2 mt-1",
                                                        staticStyle: {
                                                          "padding-bottom":
                                                            "5px",
                                                          "font-family":
                                                            "Mulish",
                                                          "font-style":
                                                            "normal",
                                                          "font-weight": "bold",
                                                          "font-size": "12px",
                                                          "line-height": "15px",
                                                          "text-transform":
                                                            "uppercase",
                                                          color: "#171822"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.training_name
                                                            )
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "ripple",
                                                            rawName:
                                                              "v-ripple.400",
                                                            value:
                                                              "rgba(186, 191, 199, 0.15)",
                                                            expression:
                                                              "'rgba(186, 191, 199, 0.15)'",
                                                            modifiers: {
                                                              "400": true
                                                            }
                                                          }
                                                        ],
                                                        staticClass:
                                                          "text-primary ml-auto mr-2",
                                                        attrs: { type: "reset" }
                                                      },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "btn-icon font-weight-bold",
                                                            attrs: {
                                                              variant: "white"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openEditeForm(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("feather-icon", {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "b-tooltip",
                                                                  rawName:
                                                                    "v-b-tooltip.hover.top",
                                                                  value: "Edit",
                                                                  expression:
                                                                    "'Edit'",
                                                                  modifiers: {
                                                                    hover: true,
                                                                    top: true
                                                                  }
                                                                }
                                                              ],
                                                              staticClass:
                                                                "text-primary",
                                                              attrs: {
                                                                icon:
                                                                  "EditIcon",
                                                                size: "20"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c("feather-icon", {
                                                staticClass: "text-danger m-1",
                                                attrs: {
                                                  icon: "AlertOctagonIcon",
                                                  size: "20"
                                                }
                                              }),
                                              _c(
                                                "span",
                                                {
                                                  staticClass: "mt-1",
                                                  staticStyle: {
                                                    "font-family": "Mulish",
                                                    "font-style": "normal",
                                                    "font-weight": "normal",
                                                    "font-size": "14px",
                                                    "line-height": "21px",
                                                    color: "#171822"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " Completed on " +
                                                      _vm._s(
                                                        _vm.momentFormat(
                                                          item.start_date,
                                                          "MMM DD, YYYY"
                                                        )
                                                      ) +
                                                      " "
                                                  ),
                                                  _c("br"),
                                                  _vm.momentFormat(
                                                    item.expire_date
                                                  ) >=
                                                  _vm.momentFormat(_vm.today)
                                                    ? _c("div", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "font-family":
                                                                "Mulish",
                                                              "font-style":
                                                                "normal",
                                                              "font-weight":
                                                                "normal",
                                                              "font-size":
                                                                "12px",
                                                              "line-height":
                                                                "15px",
                                                              color: "#949494"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Expired on " +
                                                                _vm._s(
                                                                  _vm.momentFormat(
                                                                    item.expire_date,
                                                                    "MMM DD, YYYY"
                                                                  )
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                    : _c("div", [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "font-family":
                                                                "Mulish",
                                                              "font-style":
                                                                "normal",
                                                              "font-weight":
                                                                "normal",
                                                              "font-size":
                                                                "12px",
                                                              "line-height":
                                                                "15px",
                                                              color: "#ff0000"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " Expired on " +
                                                                _vm._s(
                                                                  _vm.momentFormat(
                                                                    item.expire_date,
                                                                    "MMM DD, YYYY"
                                                                  )
                                                                )
                                                            )
                                                          ]
                                                        )
                                                      ])
                                                ]
                                              ),
                                              item.training_certificate
                                                ? _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "ripple",
                                                          rawName:
                                                            "v-ripple.400",
                                                          value:
                                                            "rgba(186, 191, 199, 0.15)",
                                                          expression:
                                                            "'rgba(186, 191, 199, 0.15)'",
                                                          modifiers: {
                                                            "400": true
                                                          }
                                                        }
                                                      ],
                                                      staticClass:
                                                        "text-primary ml-auto mr-2 mt-1",
                                                      attrs: { type: "reset" },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return _vm.downloadFile(
                                                            item.training_certificate
                                                          )
                                                        }
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " View Credential "
                                                      )
                                                    ]
                                                  )
                                                : _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "ripple",
                                                          rawName:
                                                            "v-ripple.400",
                                                          value:
                                                            "rgba(186, 191, 199, 0.15)",
                                                          expression:
                                                            "'rgba(186, 191, 199, 0.15)'",
                                                          modifiers: {
                                                            "400": true
                                                          }
                                                        }
                                                      ],
                                                      staticClass:
                                                        "text-primary ml-auto mr-2 mt-1",
                                                      attrs: { type: "reset" },
                                                      on: {
                                                        click:
                                                          _vm.noSuchDocument
                                                      }
                                                    },
                                                    [
                                                      _vm._v(
                                                        " View Credential "
                                                      )
                                                    ]
                                                  )
                                            ],
                                            1
                                          )
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  item.is_non_renewal
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                height: "40px",
                                                "background-color": "#ebf5fb",
                                                "margin-top": "20px"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-family": "Mulish",
                                                    "font-style": "normal",
                                                    "font-weight": "bold",
                                                    "font-size": "12px",
                                                    "line-height": "15px",
                                                    "text-transform":
                                                      "uppercase",
                                                    color: "#171822"
                                                  }
                                                },
                                                [
                                                  _c("b-row", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "ml-2 mt-1",
                                                        staticStyle: {
                                                          "padding-bottom":
                                                            "5px",
                                                          "font-family":
                                                            "Mulish",
                                                          "font-style":
                                                            "normal",
                                                          "font-weight": "bold",
                                                          "font-size": "12px",
                                                          "line-height": "15px",
                                                          "text-transform":
                                                            "uppercase",
                                                          color: "#171822"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              item.training_name
                                                            )
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        directives: [
                                                          {
                                                            name: "ripple",
                                                            rawName:
                                                              "v-ripple.400",
                                                            value:
                                                              "rgba(186, 191, 199, 0.15)",
                                                            expression:
                                                              "'rgba(186, 191, 199, 0.15)'",
                                                            modifiers: {
                                                              "400": true
                                                            }
                                                          }
                                                        ],
                                                        staticClass:
                                                          "text-primary ml-auto mr-2",
                                                        attrs: { type: "reset" }
                                                      },
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "btn-icon font-weight-bold",
                                                            attrs: {
                                                              variant: "white"
                                                            },
                                                            on: {
                                                              click: function(
                                                                $event
                                                              ) {
                                                                return _vm.openEditeForm(
                                                                  item
                                                                )
                                                              }
                                                            }
                                                          },
                                                          [
                                                            _c("feather-icon", {
                                                              directives: [
                                                                {
                                                                  name:
                                                                    "b-tooltip",
                                                                  rawName:
                                                                    "v-b-tooltip.hover.top",
                                                                  value: "Edit",
                                                                  expression:
                                                                    "'Edit'",
                                                                  modifiers: {
                                                                    hover: true,
                                                                    top: true
                                                                  }
                                                                }
                                                              ],
                                                              staticClass:
                                                                "text-primary",
                                                              attrs: {
                                                                icon:
                                                                  "EditIcon",
                                                                size: "20"
                                                              }
                                                            })
                                                          ],
                                                          1
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  ])
                                                ],
                                                1
                                              )
                                            ]
                                          ),
                                          _c("b-row", [
                                            _c(
                                              "svg",
                                              {
                                                staticClass: "mt-1 ml-1",
                                                attrs: {
                                                  width: "18",
                                                  height: "18",
                                                  viewBox: "0 0 18 18",
                                                  fill: "none",
                                                  xmlns:
                                                    "http://www.w3.org/2000/svg"
                                                }
                                              },
                                              [
                                                _c("path", {
                                                  attrs: {
                                                    d: "M9 1.5V4.5",
                                                    stroke: "#EFA12C",
                                                    "stroke-width": "2",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round"
                                                  }
                                                }),
                                                _c("path", {
                                                  attrs: {
                                                    d: "M9 13.5V16.5",
                                                    stroke: "#EFA12C",
                                                    "stroke-width": "2",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round"
                                                  }
                                                }),
                                                _c("path", {
                                                  attrs: {
                                                    d:
                                                      "M3.69727 3.69531L5.81977 5.81781",
                                                    stroke: "#EFA12C",
                                                    "stroke-width": "2",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round"
                                                  }
                                                }),
                                                _c("path", {
                                                  attrs: {
                                                    d:
                                                      "M12.1797 12.1797L14.3022 14.3022",
                                                    stroke: "#EFA12C",
                                                    "stroke-width": "2",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round"
                                                  }
                                                }),
                                                _c("path", {
                                                  attrs: {
                                                    d: "M1.5 9H4.5",
                                                    stroke: "#EFA12C",
                                                    "stroke-width": "2",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round"
                                                  }
                                                }),
                                                _c("path", {
                                                  attrs: {
                                                    d: "M13.5 9H16.5",
                                                    stroke: "#EFA12C",
                                                    "stroke-width": "2",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round"
                                                  }
                                                }),
                                                _c("path", {
                                                  attrs: {
                                                    d:
                                                      "M3.69727 14.3022L5.81977 12.1797",
                                                    stroke: "#EFA12C",
                                                    "stroke-width": "2",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round"
                                                  }
                                                }),
                                                _c("path", {
                                                  attrs: {
                                                    d:
                                                      "M12.1797 5.81781L14.3022 3.69531",
                                                    stroke: "#EFA12C",
                                                    "stroke-width": "2",
                                                    "stroke-linecap": "round",
                                                    "stroke-linejoin": "round"
                                                  }
                                                })
                                              ]
                                            ),
                                            _c(
                                              "span",
                                              {
                                                staticClass: "mt-1 ml-1",
                                                staticStyle: {
                                                  "font-family": "Mulish",
                                                  "font-style": "normal",
                                                  "font-weight": "normal",
                                                  "font-size": "14px",
                                                  "line-height": "21px",
                                                  color: "#171822"
                                                }
                                              },
                                              [
                                                _vm._v(
                                                  " Start on " +
                                                    _vm._s(
                                                      _vm.momentFormat(
                                                        item.start_date,
                                                        "MMM DD, YYYY"
                                                      )
                                                    )
                                                ),
                                                _c("br"),
                                                _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      "font-family": "Mulish",
                                                      "font-style": "normal",
                                                      "font-weight": "normal",
                                                      "font-size": "12px",
                                                      "line-height": "15px",
                                                      color: "#949494"
                                                    }
                                                  },
                                                  [_vm._v(" Non-renewal")]
                                                )
                                              ]
                                            ),
                                            item.training_certificate
                                              ? _c(
                                                  "span",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "b-tooltip",
                                                        rawName:
                                                          "v-b-tooltip.hover.top",
                                                        value:
                                                          "Download certificate",
                                                        expression:
                                                          "'Download certificate'",
                                                        modifiers: {
                                                          hover: true,
                                                          top: true
                                                        }
                                                      },
                                                      {
                                                        name: "ripple",
                                                        rawName: "v-ripple.400",
                                                        value:
                                                          "rgba(186, 191, 199, 0.15)",
                                                        expression:
                                                          "'rgba(186, 191, 199, 0.15)'",
                                                        modifiers: {
                                                          "400": true
                                                        }
                                                      }
                                                    ],
                                                    staticClass:
                                                      "text-primary ml-auto mr-2 mt-1",
                                                    attrs: { type: "reset" },
                                                    on: {
                                                      click: function($event) {
                                                        return _vm.downloadFile(
                                                          item.training_certificate
                                                        )
                                                      }
                                                    }
                                                  },
                                                  [_vm._v(" View Credential ")]
                                                )
                                              : _vm._e()
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ])
                              }),
                              0
                            )
                          : _c("div", { staticClass: "text-center mt-4" }, [
                              _c(
                                "svg",
                                {
                                  attrs: {
                                    width: "100",
                                    height: "100",
                                    viewBox: "0 0 50 50",
                                    fill: "none",
                                    xmlns: "http://www.w3.org/2000/svg"
                                  }
                                },
                                [
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d:
                                        "M25 0C38.8072 0 50 11.1928 50 25C50 38.8072 38.8072 50 25 50C11.1928 50 0 38.8072 0 25C0 11.1928 11.1928 0 25 0Z",
                                      fill: "#CCE6F4"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d:
                                        "M34.8938 12.5996C35.6281 12.5996 36.2235 13.1951 36.2235 13.9294C36.2235 14.6637 35.6281 15.2592 34.8938 15.2592C34.1592 15.2592 33.564 14.6637 33.564 13.9294C33.564 13.1951 34.1592 12.5996 34.8938 12.5996Z",
                                      fill: "white"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d:
                                        "M38.6171 8.34375C39.6453 8.34375 40.4788 9.17726 40.4788 10.2055C40.4788 11.2336 39.6453 12.0672 38.6171 12.0672C37.5889 12.0672 36.7554 11.2336 36.7554 10.2055C36.7554 9.17726 37.5889 8.34375 38.6171 8.34375Z",
                                      fill: "white"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      "fill-rule": "evenodd",
                                      "clip-rule": "evenodd",
                                      d:
                                        "M33.0319 7.8125C33.3258 7.8125 33.5638 8.05053 33.5638 8.34441C33.5638 8.6383 33.3258 8.87633 33.0319 8.87633C32.738 8.87633 32.5 8.6383 32.5 8.34441C32.5 8.05053 32.738 7.8125 33.0319 7.8125Z",
                                      fill: "white"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M34.701 20.1504C32.7913 20.1504 31.2363 21.7053 31.2363 23.615C31.2363 25.5247 32.7913 27.0797 34.701 27.0797C36.6107 27.0797 38.1656 25.5247 38.1656 23.615C38.1656 21.7053 36.6107 20.1504 34.701 20.1504ZM34.701 25.6938C33.5548 25.6938 32.6222 24.7612 32.6222 23.615C32.6222 22.4689 33.5548 21.5362 34.701 21.5362C35.8471 21.5362 36.7798 22.4689 36.7798 23.615C36.7798 24.7612 35.847 25.6938 34.701 25.6938Z",
                                      fill: "black"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M15.3975 20.1504C13.4864 20.1504 11.9329 21.7053 11.9329 23.615C11.9329 25.5247 13.4878 27.0797 15.3975 27.0797C17.3072 27.0797 18.8622 25.5247 18.8622 23.615C18.8622 21.7053 17.3086 20.1504 15.3975 20.1504ZM15.3975 25.6938C14.2514 25.6938 13.3187 24.7612 13.3187 23.615C13.3187 22.4689 14.2514 21.5362 15.3975 21.5362C16.5436 21.5362 17.4763 22.4689 17.4763 23.615C17.4763 24.7612 16.5436 25.6938 15.3975 25.6938Z",
                                      fill: "black"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M34.7007 28.4648C33.5601 28.4648 32.4431 28.7767 31.4702 29.3657C31.1445 29.5652 31.0392 29.9907 31.2374 30.3178C31.4383 30.6449 31.8624 30.7488 32.1895 30.5506C32.9447 30.0918 33.8123 29.8507 34.7007 29.8507C37.3754 29.8507 39.5512 32.0265 39.5512 34.7013C39.5512 35.0838 39.8617 35.3942 40.2442 35.3942C40.6267 35.3942 40.9372 35.0838 40.9372 34.7013C40.9371 31.2629 38.139 28.4648 34.7007 28.4648Z",
                                      fill: "black"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M18.528 29.3643C17.5565 28.7767 16.4395 28.4648 15.2989 28.4648C11.8606 28.4648 9.0625 31.2629 9.0625 34.7013C9.0625 35.0838 9.37296 35.3942 9.75546 35.3942C10.138 35.3942 10.4484 35.0838 10.4484 34.7013C10.4483 32.0265 12.6242 29.8507 15.2989 29.8507C16.1873 29.8507 17.0548 30.0918 17.8087 30.5492C18.1344 30.7474 18.5613 30.6434 18.7608 30.3163C18.9604 29.9893 18.855 29.5638 18.528 29.3643Z",
                                      fill: "black"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M24.9999 25.6934C20.0329 25.6934 15.9917 29.7345 15.9917 34.7015C15.9917 35.084 16.3022 35.3945 16.6847 35.3945C17.0672 35.3945 17.3776 35.084 17.3776 34.7015C17.3776 30.4982 20.7965 27.0793 24.9999 27.0793C29.2032 27.0793 32.6221 30.4982 32.6221 34.7015C32.6221 35.084 32.9326 35.3945 33.3151 35.3945C33.6976 35.3945 34.008 35.084 34.008 34.7015C34.008 29.7345 29.9668 25.6934 24.9999 25.6934Z",
                                      fill: "#1B9AAA"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M25 14.6055C22.3253 14.6055 20.1494 16.7813 20.1494 19.456C20.1494 22.1308 22.3252 24.3066 25 24.3066C27.6747 24.3066 29.8505 22.1307 29.8505 19.456C29.8505 16.7813 27.6747 14.6055 25 14.6055ZM25 22.9207C23.0903 22.9207 21.5353 21.3657 21.5353 19.456C21.5353 17.5463 23.0903 15.9914 25 15.9914C26.9097 15.9914 28.4646 17.5463 28.4646 19.456C28.4646 21.3657 26.9097 22.9207 25 22.9207Z",
                                      fill: "#1B9AAA"
                                    }
                                  }),
                                  _c("path", {
                                    attrs: {
                                      d:
                                        "M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z",
                                      fill: "#1B9AAA",
                                      stroke: "#1B9AAA",
                                      "stroke-width": "0.5"
                                    }
                                  }),
                                  _c(
                                    "mask",
                                    {
                                      staticStyle: { "mask-type": "alpha" },
                                      attrs: {
                                        id: "mask0_1872_13634",
                                        maskUnits: "userSpaceOnUse",
                                        x: "14",
                                        y: "11",
                                        width: "6",
                                        height: "5"
                                      }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z",
                                          fill: "white"
                                        }
                                      })
                                    ]
                                  ),
                                  _c(
                                    "g",
                                    {
                                      attrs: { mask: "url(#mask0_1872_13634)" }
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          d:
                                            "M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z",
                                          fill: "#1B9AAA"
                                        }
                                      }),
                                      _c(
                                        "mask",
                                        {
                                          staticStyle: { "mask-type": "alpha" },
                                          attrs: {
                                            id: "mask1_1872_13634",
                                            maskUnits: "userSpaceOnUse",
                                            x: "14",
                                            y: "11",
                                            width: "6",
                                            height: "5"
                                          }
                                        },
                                        [
                                          _c("path", {
                                            attrs: {
                                              d:
                                                "M19.0234 13.4453C19.0234 13.6246 18.9039 13.7441 18.7246 13.7441H16.9316V15.5371C16.9316 15.7164 16.8121 15.8359 16.6328 15.8359C16.4535 15.8359 16.334 15.7164 16.334 15.5371V13.7441H14.541C14.3617 13.7441 14.2422 13.6246 14.2422 13.4453C14.2422 13.266 14.3617 13.1465 14.541 13.1465H16.334V11.3535C16.334 11.1742 16.4535 11.0547 16.6328 11.0547C16.8121 11.0547 16.9316 11.1742 16.9316 11.3535V13.1465H18.7246C18.9039 13.1465 19.0234 13.266 19.0234 13.4453Z",
                                              fill: "white"
                                            }
                                          })
                                        ]
                                      ),
                                      _c("g", {
                                        attrs: {
                                          mask: "url(#mask1_1872_13634)"
                                        }
                                      })
                                    ]
                                  )
                                ]
                              ),
                              _c("h5", { staticClass: "mt-2" }, [
                                _vm._v("No Training Users")
                              ]),
                              _c("div", { staticStyle: { height: "100px" } })
                            ])
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("AddMandatoryTrainingPopup", {
        ref: "addMandatoryTrainingPopup",
        on: {
          getAllData: function($event) {
            return _vm.getTrainingCertificate()
          }
        }
      }),
      _c("EditMandatoryTrainingPopup", {
        ref: "editMandatoryTrainingPopup",
        on: {
          getAllData: function($event) {
            return _vm.getTrainingCertificate()
          }
        }
      }),
      _c("AddDocumentSidebar", {
        ref: "AddDocumentSidebar",
        attrs: {
          "is-add-document-sidebar-active": _vm.isAddDocumentSidebarActive
        },
        on: {
          getDocumentList: function($event) {
            return _vm.getDocumentList()
          }
        }
      }),
      _c("AddMandatorySidebar", {
        ref: "AddMandatorySidebar",
        attrs: {
          "is-add-mandatory-sidebar-active": _vm.isAddMandatorySidebarActive
        },
        on: {
          getAllData: function($event) {
            return _vm.toggleAddMandatorySideBar()
          }
        }
      }),
      _c("EditMandatorySidebar", {
        ref: "EditMandatorySidebar",
        attrs: {
          "is-edit-mandatory-sidebar-active": _vm.isEditMandatorySidebarActive
        },
        on: {
          getAllData: function($event) {
            return _vm.toggleEditMandatorySideBar()
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }