var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-sidebar", {
    attrs: {
      id: "add-note-sidebar",
      visible: _vm.isAddNoteSidebarActive,
      backdrop: "",
      "bg-variant": "white",
      "no-header": "",
      right: "",
      shadow: "",
      "sidebar-class": "sidebar-lg"
    },
    on: {
      change: function(val) {
        return _vm.$emit("update:is-add-note-sidebar-active", val)
      }
    },
    scopedSlots: _vm._u([
      {
        key: "default",
        fn: function(ref) {
          var hide = ref.hide
          return [
            _c(
              "b-overlay",
              { attrs: { show: _vm.tableLoading, rounded: "sm" } },
              [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
                  },
                  [
                    _c("h4", { staticClass: "mb-0" }, [_vm._v(" Add Note ")]),
                    _c("feather-icon", {
                      staticClass: "ml-1 cursor-pointer",
                      attrs: { icon: "XIcon", size: "16" },
                      on: { click: hide }
                    })
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "p-2" },
                  [
                    _c(
                      "validation-observer",
                      { ref: "requestLeaveForm" },
                      [
                        _c(
                          "b-form",
                          {
                            on: {
                              submit: function($event) {
                                $event.preventDefault()
                              }
                            }
                          },
                          [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { md: "12" } },
                                  [
                                    _c("h4", [_vm._v("Note")]),
                                    _c("validation-provider", {
                                      attrs: {
                                        name: "Note",
                                        rules: "required"
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "default",
                                            fn: function(ref) {
                                              var errors = ref.errors
                                              return [
                                                _c("b-form-textarea", {
                                                  attrs: {
                                                    id: "textarea-default",
                                                    placeholder: "Enter note",
                                                    rows: "3"
                                                  },
                                                  model: {
                                                    value: _vm.note,
                                                    callback: function($$v) {
                                                      _vm.note = $$v
                                                    },
                                                    expression: "note"
                                                  }
                                                }),
                                                _c(
                                                  "small",
                                                  {
                                                    staticClass: "text-danger"
                                                  },
                                                  [_vm._v(_vm._s(errors[0]))]
                                                )
                                              ]
                                            }
                                          }
                                        ],
                                        null,
                                        true
                                      )
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex mt-3" },
                              [
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple.400",
                                        value: "rgba(255, 255, 255, 0.15)",
                                        expression:
                                          "'rgba(255, 255, 255, 0.15)'",
                                        modifiers: { "400": true }
                                      }
                                    ],
                                    attrs: {
                                      type: "submit",
                                      variant: "primary"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.addNote()
                                      }
                                    }
                                  },
                                  [_vm._v(" Save ")]
                                ),
                                _c(
                                  "b-button",
                                  {
                                    directives: [
                                      {
                                        name: "ripple",
                                        rawName: "v-ripple.400",
                                        value: "rgba(255, 255, 255, 0.15)",
                                        expression:
                                          "'rgba(255, 255, 255, 0.15)'",
                                        modifiers: { "400": true }
                                      }
                                    ],
                                    staticClass: "text-primary ml-1 shadow",
                                    attrs: { type: "button", variant: "white" },
                                    on: { click: hide }
                                  },
                                  [_vm._v(" Cancel ")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            )
          ]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }